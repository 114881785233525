import { isUndefined } from 'lodash-es'
import useMap from './use-map'

export default function useCenterCoordinate() {
  const map = useMap()

  const [coordinate, setCoordinate] = useState()

  // on center change
  useEffect(() => {
    if (!map) return

    const mapView = map.getView()

    if (isUndefined(coordinate)) {
      setCoordinate(mapView.getCenter())
    }

    function onCenterChange(e) {
      setCoordinate(e.target.get(e.key))
    }

    mapView.on('change:center', onCenterChange)

    return () => {
      mapView.un('change:center', onCenterChange)
    }
  }, [map])

  return coordinate
}
