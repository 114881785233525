import { Form, TextField } from 'components/form'
import { inviteUser } from 'api/tenant'

export default function ManageUsers() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSubmit = useCallback((model) => {
    dispatch(inviteUser(model))
  }, [])

  return (
    <Form onSubmit={onSubmit} submitText={t('invite')}>
      <TextField id="userEmail" name="userEmail" label={t('user_email')} />
    </Form>
  )
}
