import BaseField from './base-field'

const TextField = React.forwardRef((props, ref) => {
  return (
    <BaseField
      {...props}
      type="text"
      ref={ref}
      defaultValue={props.defaultValue || ''}
    />
  )
})
TextField.displayName = 'TextField'

export default TextField
