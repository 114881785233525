import DoneIcon from '@mui/icons-material/Check'
import SyncIcon from '@mui/icons-material/Sync'
import { Box, CircularProgress } from '@mui/material'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import makeStyles from '@mui/styles/makeStyles'
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import * as c from 'common/c'
export type SyncStatusProps = {
  status: String
}

const useStyles = makeStyles({
  syncStatusIcons: {
    height: '30px',
    width: '30px',
  },
  syncComplete: {
    color: '#0f5132',
  },
  readyToSync: {
    color: '#70787D',
  },
  syncError: {
    color: '#F00',
  },
})

export default function SyncStatus(props: SyncStatusProps) {
  const { status } = props

  const classes = useStyles()
  switch (status) {
    case c.IN_PROGRESS:
      return (
        <Box px={2}>
          <CircularProgress size={30} />
        </Box>
      )
    case c.DONE:
      return (
        <Box px={2}>
          <PublishedWithChangesIcon className={`${classes.syncComplete} ${classes.syncStatusIcons}`} />
        </Box>
      )
      case c.ERROR:
      return (
        <Box px={2}>
          <SyncProblemIcon className={`${classes.syncError} ${classes.syncStatusIcons}`} />
        </Box>
      )
    default:
      return (
        <Box px={2}>
          <SyncIcon className={`${classes.readyToSync} ${classes.syncStatusIcons}`} />
        </Box>
      )
  }
}
