import { useAuth0 } from '@auth0/auth0-react'
import { Button, IconButton } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCallback, useEffect, useState } from 'react'

export function ImpersonateCheck(props) {
  const { t } = useTranslation()
  const [impersonate, setImpersonate] = useState()
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setImpersonate(!!localStorage.getItem('impersonate'))
  }, [])

  const leaveFarm = useCallback(() => {
    if (impersonate === true) {
      const data = JSON.parse(
        localStorage.getItem(
          `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        ),
      )
      const otok = localStorage.getItem('otok')
      const updateData = update(data, {
        body: { access_token: { $set: otok } },
      })
      localStorage.setItem(
        `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        JSON.stringify(updateData),
      )
      getAccessTokenSilently({ audience: 'https://api.farmtrx.com' })
        .then(() => {
          localStorage.removeItem('impersonate')
          localStorage.removeItem('visitingFarmName')
          //Remove the CFF localStorage items
          localStorage.removeItem('selectedClient')
          localStorage.removeItem('selectedFarms')
          //End
          localStorage.removeItem('visitingFarmName')
          window.location.href = '/admin'
        })
        .catch((err) => {
          console.log('ERR => ', err)
        })
    }
  }, [getAccessTokenSilently, impersonate])

  const altElement = props.showMessage ? (
    <StyledDiv>
      <span>{t('impersonating')}</span>
      <Button variant="contained" color="primary" onClick={leaveFarm}>
        {t('stop_impersonating')}
      </Button>
    </StyledDiv>
  ) : (
    <IconButton title={t('stop_impersonating')} onClick={leaveFarm}>
      <LogoutIcon />
    </IconButton>
  )

  return impersonate ? altElement : <>{props.children}</>
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`
