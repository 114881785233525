import * as c from 'common/c'

export const headersFetched = (data) => {
  return {
    type: c.HEADERS_FETCHED,
    payload: data,
  }
}

export const addHeader = (data) => {
  return {
    type: c.ADD_HEADER,
    payload: data,
  }
}

export const editHeaderDetails = (data) => {
  return {
    type: c.EDIT_HEADER,
    payload: data,
  }
}

export const removeHeader = (id) => {
  return {
    type: c.DELETE_HEADER,
    payload: id,
  }
}

export const headerTypesFetched = (data) => {
  return {
    type: c.HEADER_TYPES_FETCHED,
    payload: data,
  }
}
