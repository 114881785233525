import { Vector as VectorSource } from 'ol/source'
import MapSourceBoundaryContext from 'context/map-source-boundary-context'
import LazyRender from 'components/lazy-render'
import { isNil } from 'lodash-es'

export default function MapSourceBoundaryProvider(props) {
  const { features, children } = props

  const [source, setSource] = useState()

  useEffect(() => {
    setSource(new VectorSource({ useSpatialIndex: false }))
  }, [])

  useEffect(() => {
    if (!isNil(source)) {
      source.clear({ fast: true })
      source.addFeatures(features.getArray())
    }
  }, [source, features])

  const isDone = useMemo(() => {
    return !isNil(source)
  }, [source])

  return (
    <LazyRender
      done={isDone}
      render={() => {
        return (
          <MapSourceBoundaryContext.Provider value={source}>
            {children}
          </MapSourceBoundaryContext.Provider>
        )
      }}
    />
  )
}
