import { DragBox } from 'ol/interaction'
import { always } from 'ol/events/condition'
import useMap from 'hooks/use-map'
import { noop } from 'lodash-es'

export default function DrawBoxInteraction(props) {
  const { onDrawBox = noop } = props

  const map = useMap()

  const [interaction, setInteraction] = useState()

  useEffect(() => {
    const interaction = new DragBox({ condition: always })

    map.addInteraction(interaction)
    setInteraction(interaction)

    return () => {
      map.removeInteraction(interaction)
    }
  }, [map])

  useEffect(() => {
    if (!interaction) return

    function onBoxEnd(e) {
      onDrawBox(interaction.getGeometry().getExtent(), e.target.startPixel_)
    }

    interaction.on('boxend', onBoxEnd)

    return () => {
      interaction.un('boxend', onBoxEnd)
    }
  }, [interaction, onDrawBox])

  return null
}

DrawBoxInteraction.propTypes = { onDrawBox: PropTypes.func }
