import styled from 'styled-components'
import { Children, cloneElement } from 'react'
import { ExpandableToolbarButton } from './expandable-toolbar-button'
import { DoubleArrow as ExpandIcon } from '@mui/icons-material'
import { t } from 'i18next'
export function ExpandableToolbar(props) {
  const [expanded, setExpanded] = useState(undefined)

  return (
    <StyledToolbar>
      {Children.map(props.children, (child) =>
        cloneElement(child, { expanded }),
      )}

      <ExpandableToolbarButton
        icon={
          expanded ? (
            <ExpandIcon style={{ transform: 'rotate(180deg)' }} />
          ) : (
            <ExpandIcon />
          )
        }
        label={t('collapse')}
        onClick={() => {
          setExpanded(expanded ? undefined : 1)
        }}
        expanded={expanded}
      />
    </StyledToolbar>
  )
}

const StyledToolbar = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  & > div {
    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
`
