import styled from 'styled-components'
import { H } from 'components/layout'

import {
  ArrowForwardIosTwoTone as ArrowForwardIcon,
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import TextField from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'
import colors from 'common/colors'

const useStyles = makeStyles(() => ({
  flexOne: {
    overflow: 'hidden',
    flexGrow: 1,
    flexBasis: 0,
    flexShrink: 1,
    textOverflow: 'ellipsis',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'flex-end',
  },
  selected: {
    paddingTop: '8px',
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#9ec7d2',
  },
  default: {
    paddingTop: '4px',
    paddingBottom: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
}))
const StyledTextField = withStyles({
  root: {
    width: '15ch',
  },
})(TextField)

export default function Expandable(props) {
  const {
    title,
    onClick,
    open = false,
    children,
    onEditField,
    onDeleteField,
    edit = false,
    saveName,
    cancelEdit,
    fieldName,
    fieldId,
    farmId,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [newName, setNewName] = useState(fieldName)

  const enableButton = useCallback(() => {
    return !(newName != '' && newName != fieldName)
  }, [newName, fieldName])

  const handleInputChange = useCallback((e) => {
    const { value } = e.target
    setNewName(value)
  })

  const saveData = useCallback(() => {
    saveName(fieldId, newName, farmId)
  }, [newName])
  const handleCancel = useCallback((e) => {
    e.preventDefault()
    cancelEdit(e)
  })

  return (
    <>
      <StyledRoot
        className={open ? classes.selected : classes.default}
        onClick={onClick}
        id={props.id}
      >
        <StyledRoot className={classes.row}>
          <StyledItem className={classes.flexOne}>
            {edit ? (
              <StyledTextField
                value={newName}
                required
                size="small"
                name="name"
                onChange={(e) => handleInputChange(e)}
              />
            ) : (
              <StyledHeader open={open}>{title}</StyledHeader>
            )}
          </StyledItem>{' '}
          {open && edit ? (
            <StyledItem>
              <IconButton
                color="secondary"
                aria-label="save the item"
                className={classes.button}
                disabled={enableButton()}
                onClick={(e) => saveData(e)}
              >
                <SaveOutlinedIcon
                  fontSize="small"
                  style={{ color: colors.brandLight }}
                />
              </IconButton>
              <IconButton
                color="error"
                aria-label={t('cancel')}
                className={classes.button}
                onClick={(e) => handleCancel(e)}
              >
                <ClearIcon fontSize="small" style={{ color: '#B2023B' }} />
              </IconButton>
            </StyledItem>
          ) : (
            <StyledItem>
              <EditIcon
                size="small"
                style={{ height: '0.9em' }}
                onClick={onEditField}
                titleAccess={t('edit_field')}
              />
              <DeleteIcon
                size="small"
                style={{ height: '0.9em' }}
                titleAccess={t('delete_field')}
                onClick={onDeleteField}
              />
            </StyledItem>
          )}
          <ArrowForwardIcon style={{ cursor: 'pointer' }} />
        </StyledRoot>
      </StyledRoot>
      {open ? <StyledContainer>{children}</StyledContainer> : null}
    </>
  )
}

const StyledRoot = styled(H)`
  margin: 3px;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`

const StyledItem = styled(H.Item)``

const StyledHeader = styled.h1`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin: 0;
`

const StyledContainer = styled.div`
  padding: 0;
`
