import styled from 'styled-components'
import { switchToSplit } from 'actions/app'
import { SplitScreen } from '@troo/farmtrx-icons'
import {
  featuresFetchedA,
  featuresFetchedB,
  setLayersA,
  setLayersB,
  fieldsHarvestedAFetched,
  fieldsHarvestedBFetched,
} from 'actions/field'
import { harvestAFetched, harvestBFetched } from 'actions/harvest'
import { get } from 'lodash-es'
import { vectorLayers, rasterLayers } from 'common/layer-data'
import { setYearA, setYearB } from 'actions/field'

export default function SwitchSplit() {
  const harvest = useSelector((state) => state.harvest.current)
  const fieldFeatures = useSelector((state) => get(state, 'field.features'))
  const fieldsHarvested = useSelector((state) =>
    get(state, 'field.fieldsHarvested'),
  )

  const dispatch = useDispatch()
  const layersA = useSelector((state) => get(state, 'field.layers_a'))
  const layersB = useSelector((state) => get(state, 'field.layers_b'))
  const year = useSelector((state) => get(state, 'field.year'))
  const yearA = useSelector((state) => get(state, 'field.year_a'))
  const yearB = useSelector((state) => get(state, 'field.year_a'))

  const [showSplitScreenIcon, setShowSplitScreenIcon] = useState(false)

  /*If Harvest is not selected then set the first array from the harvests state object as the default selection in the split screen window.*/
  const harvests = useSelector((state) => state.harvest.collection)

  /*End*/

  /*When there are no harvests, hide split screen icon*/
  useEffect(() => {
    if (harvests.length) {
      setShowSplitScreenIcon(true)
    } else {
      setShowSplitScreenIcon(false)
    }
  }, [harvests])

  const baseVectorLayers = useMemo(() => {
    const _baseVectorLayers = {}
    for (let i = 0; i < vectorLayers.length; i++) {
      _baseVectorLayers[vectorLayers[i].name] = vectorLayers[i]
    }
    return _baseVectorLayers
  }, [vectorLayers])
  const baseRasterLayers = useMemo(() => {
    const _baseRasterLayers = {}
    for (let i = 0; i < rasterLayers.length; i++) {
      _baseRasterLayers[rasterLayers[i].name] = rasterLayers[i]
    }
    return _baseRasterLayers
  }, [rasterLayers])

  const onClick = useCallback(() => {
    dispatch(setYearA(year))
    dispatch(fieldsHarvestedAFetched(fieldsHarvested))
    dispatch(setYearB(year))
    dispatch(fieldsHarvestedBFetched(fieldsHarvested))

    let defaultHarvest = {}
    if (Object.keys(harvest).length) {
      defaultHarvest = Object.assign(defaultHarvest, harvest)
    } else {
      defaultHarvest = Object.assign(defaultHarvest, harvests[0])
    }
    dispatch(harvestAFetched(defaultHarvest))
    dispatch(harvestBFetched(defaultHarvest))

    dispatch(featuresFetchedA(fieldFeatures))
    dispatch(featuresFetchedB(fieldFeatures))
    if (defaultHarvest?.id) {
      const initialLayersA = {}
      const initialLayersB = {}
      initialLayersA[defaultHarvest.id] =
        layersA[defaultHarvest.id] != null
          ? layersA[defaultHarvest.id]
          : Object.assign({}, baseRasterLayers, baseVectorLayers)
      initialLayersB[defaultHarvest.id] =
        layersB[defaultHarvest.id] != null
          ? layersB[defaultHarvest.id]
          : Object.assign({}, baseRasterLayers, baseVectorLayers)
      dispatch(setLayersA(initialLayersA))
      dispatch(setLayersB(initialLayersB))
    }
    dispatch(switchToSplit())
  }, [year, yearA, yearB, harvests])

  return showSplitScreenIcon ? (
    <StyledLink href="#" onClick={onClick}>
      <SplitScreen />
    </StyledLink>
  ) : (
    <></>
  )
}

const StyledLink = styled.a`
  position: absolute;
  top: 10px;
  right: 20px;
  background: white;
  height: 27px;
  padding: 4px;
  border-radius: 4px;
`
