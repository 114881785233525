import * as c from 'common/c'

export const farmCreated = (data) => {
  return {
    type: c.FARM_CREATED,
    payload: data,
  }
}

export const farmsFetched = (data) => {
  return {
    type: c.FARMS_FETCHED,
    payload: data,
  }
}

export const addEmptyItem = (data) => {
  return {
    type: c.FARMSETTINGS_EMPTY_ITEM_ADDED,
    payload: data,
  }
}

export const farmPreferencesFetched = (data) => {
  return {
    type: c.FARM_PREFERENCES_FETCHED,
    payload: data,
  }
}

export const farmUpdated = (data) => {
  return {
    type: c.FARM_UPDATED,
    payload: data,
  }
}

export const farmsUpdated = (data) => {
  return {
    type: c.FARMS_UPDATED,
    payload: data,
  }
}

export const farmDeleted = (id) => {
  return {
    type: c.FARM_DELETED,
    payload: id,
  }
}

export const removeEmptyItem = (column) => {
  return {
    type: c.FARMSETTINGS_EMPTY_ITEM_DELETED,
    payload: column,
  }
}

export const brandAdded = (data) => {
  return {
    type: c.FARMSETTINGS_COMBINE_BRAND_ADDED,
    payload: data,
  }
}

export const brandUpdated = (data) => {
  return {
    type: c.FARMSETTINGS_COMBINE_BRAND_UPDATED,
    payload: data,
  }
}

export const brandDeleted = (data) => {
  return {
    type: c.FARMSETTINGS_COMBINE_BRAND_DELETED,
    payload: data,
  }
}

export const brandsFetched = (data) => {
  return {
    type: c.FARMSETTINGS_COMBINE_BRAND_FETCHED,
    payload: data,
  }
}

export const assignSelectedFarms = (farmIds) => {
  return {
    type: c.FARMS_SELECTED,
    payload: farmIds,
  }
}
