import * as c from 'common/c'

export const appReady = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.READY,
  }
}

export const shouldCreateAccount = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.ACCOUNT_CREATE,
  }
}

export const shouldcreateUserAccount = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.USER_CREATE,
  }
}

export const shouldCreateField = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.FIELD_CREATE,
  }
}

export const ajaxStart = () => {
  return { type: c.AJAX_START }
}

export const ajaxStop = () => {
  return { type: c.AJAX_STOP }
}

export const addEvent = (message, title) => {
  return {
    type: c.ADD_EVENT,
    payload: {
      title,
      message,
    },
  }
}

export const clearEvent = () => {
  return { type: c.CLEAR_EVENT }
}

export const showModal = (component, meta = {}) => {
  return {
    type: c.MODAL_SHOW,
    payload: component,
    meta,
  }
}

export const closeModal = () => {
  return { type: c.MODAL_CLOSE }
}

export const showPopup = (component, meta = {}) => {
  return {
    type: c.POPUP_SHOW,
    payload: component,
    meta,
  }
}

export const closePopup = () => {
  return { type: c.POPUP_CLOSE }
}

export const shouldShowFarmSetupWizard = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.FARM_SETUP_WIZARD,
  }
}

export const shouldShowDashboard = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.SHOW_DASHBOARD,
  }
}

export const switchToSplit = () => {
  return { type: c.SPLIT_VIEW }
}

export const switchToSingle = () => {
  return { type: c.SPLIT_SINGLE }
}

export const shouldEditUserAccount = (data) => {
  return {
    type: c.EDIT_USER_ACCOUNT,
    payload: data,
  }
}

export const accountDisabled = () => {
  return {
    type: c.STATE_SET,
    payload: c.APP_STATE.ACCOUNT_DISABLED,
  }
}
