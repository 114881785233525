import * as c from 'common/c'

const state = (
  state = {
    busy: false,
    state: c.APP_STATE.UNDEFINED,
    event: undefined,
    modal: undefined,
    popup: undefined,
    view: 'single',
  },
  action,
) => {
  switch (action.type) {
    // app state
    case c.AJAX_START:
      return update(state, { busy: { $set: true } })
    case c.AJAX_STOP:
      return update(state, { busy: { $set: false } })
    case c.STATE_SET:
      return update(state, { state: { $set: action.payload } })

    // events
    case c.ADD_EVENT:
      return update(state, { event: { $set: action.payload } })
    case c.CLEAR_EVENT:
      return update(state, { event: { $set: undefined } })

    // modal
    case c.MODAL_SHOW: {
      const modal = {
        component: action.payload,
        props: action.meta,
      }

      return update(state, { modal: { $set: modal } })
    }
    case c.MODAL_CLOSE:
      return update(state, { modal: { $set: undefined } })

    case c.POPUP_SHOW: {
      const popup = {
        component: action.payload,
        props: action.meta,
      }

      return update(state, { popup: { $set: popup } })
    }
    case c.POPUP_CLOSE:
      return update(state, { popup: { $set: undefined } })

    case c.SPLIT_VIEW:
      return update(state, { view: { $set: 'split' } })

    case c.SPLIT_SINGLE:
      return update(state, { view: { $set: 'single' } })

    default:
      return state
  }
}

export default state
