import styled from 'styled-components'
import { get, reduce, findIndex } from 'lodash-es'
import Expandable from './expandable'
import { hideLayerLegend } from 'actions/map'
import { useCallback, useEffect, useMemo } from 'react'
import { editFieldName, cancelEditFieldName } from 'actions/field'
import { startEditField } from 'actions/map'
import { showModal } from 'actions/app'
import { editField } from 'api/field'
import { ErrorOutlineOutlined as ErrorIcon } from '@mui/icons-material'
import DeleteField from 'components/delete-field'
import { useState } from 'react'
import { getHarvestStatusIcon } from 'common/c'
import HarvestPanel from './harvest-panel'
import { useSelector } from 'react-redux'

export default function FieldExplorer(props) {
  const permissions = useSelector((state) => state.account.permissions)
  const [showPermissions, setShowPermissions] = useState({})

  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  const harvestYears = useSelector((state) => state.harvest.years)
  const fieldEdit = useSelector((state) => get(state, 'map.editField', false))
  const selectedYear = useSelector((state) => state.field.year)
  const harvest = useSelector((state) => state.harvest.current)
  const editName = useSelector((state) =>
    get(state, 'field.editFieldName', false),
  )
  useEffect(() => {
    setShowPermissions({
      editCropCalibration: permissions.includes('harvest:admin'),
      editCropType: permissions.includes('farm:write'),
      editSensorCalibration: permissions.includes('harvest:admin'),
      editTimeDelay: permissions.includes('harvest:admin'),
      exportHarvest: permissions.includes('farm:read'),
      refreshHarvest: permissions.includes('harvest:admin'),
      setHeader: permissions.includes('harvest:write'),
      updateTare: permissions.includes('harvest:admin'),
      updateTestWeight: permissions.includes('harvest:write'),
      updateTotalYield: permissions.includes('harvest:write'),
    })
  }, [permissions])

  const {
    fields,
    harvestedFields,
    hideEmptyFields = false,
    selectedField,
    harvests = [],
    onFieldSelected,

    onSetHeader,
    onChangeTY,
    onChangeTestWeight,
    onEditSensorCal,
    onUpdateCropCal,
    onCropTypeOverride,
    onChangeTimeDelay,
    onRefreshHarvest,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const layers = useSelector((state) => get(state, 'field.layers'))
  const [isHarvestActionDrawerOpen, setIsHarvestActionDrawerOpen] =
    useState(false)
  const [harvestOptionsAnchor, setHarvestOptionsAnchor] = useState(null)

  const orderedFields = useMemo(() => {
    let selectedFarms = JSON.parse(localStorage.getItem('selectedFarms'))
    let selectedClient = JSON.parse(localStorage.getItem('selectedClient'))
    let result = []

    fields.map((field) => {
      //If CFF is enabled
      if (typeof cffEnabled === 'boolean' && cffEnabled && selectedClient) {
        if (!selectedFarms || !selectedFarms.length) {
          selectedFarms = selectedClient?.farms
        }
        const idx = findIndex(selectedFarms, (farm) => {
          return farm?.id === field.farmId
        })

        const i = findIndex(harvestedFields, (h) => {
          return h?.id === field.id
        })
        if (i && i === -1 && idx > -1) {
          result.push(field)
        }
      } else {
        const idx = findIndex(harvestedFields, (h) => {
          return h?.id === field.id
        })
        if (idx === -1) result.push(field)
      }
    })

    return hideEmptyFields ? harvestedFields : harvestedFields.concat(result)
  }, [harvestedFields, fields, hideEmptyFields, cffEnabled])

  const openArr = useMemo(() => {
    const _idxs = {}
    orderedFields.map((f, idx) => {
      const isSelected = selectedField && selectedField.id === f.id
      if (isSelected) {
        const element = document.getElementById('pageContent')
        if (element) {
          setIsHarvestActionDrawerOpen(true)
          setHarvestOptionsAnchor(element)
        }
      }
      _idxs[idx] = isSelected
    })

    return _idxs
  }, [orderedFields, selectedField, harvest])

  const shouldShowLegend = useMemo(
    () =>
      reduce(
        layers,
        (result, value, key) => {
          return result || (key === 'boundary' ? false : value)
        },
        false,
      ),
    [layers],
  )

  useEffect(() => {
    if (!shouldShowLegend) dispatch(hideLayerLegend())
  }, [shouldShowLegend])

  const editHarvest = useCallback((editType, h) => {
    switch (editType) {
      case 'edit_off_take':
        console.log('edit_off_take')
        break
      case 'set_header':
        onSetHeader()
        break
      case 'edit_crop_type':
        onCropTypeOverride()
        break
      case 'change_ty':
        onChangeTY()
        break
      case 'change_tw':
        onChangeTestWeight()
        break
      case 'edit_sensor_calibration':
        onEditSensorCal()
        break
      case 'edit_crop_calibration':
        onUpdateCropCal()
        break
      case 'edit_time_delay':
        onChangeTimeDelay()
        break
      case 'refresh':
        onRefreshHarvest(h)
        break
      default:
        return
    }
  })

  const handleFieldPress = useCallback((field, isOpen, event) => {
    const elementId = `${field.name}-${field.id}`
    let element = event.target
    if (event.target?.id !== elementId) {
      element = document.getElementById(elementId)
    }
    if (isOpen && fieldEdit) {
      dispatch(editFieldName())
    } else if (isOpen) {
      onFieldSelected(null)
      setIsHarvestActionDrawerOpen(false)
      setHarvestOptionsAnchor(null)
    } else {
      onFieldSelected(field.id)
      setIsHarvestActionDrawerOpen(true)
      setHarvestOptionsAnchor(element)
    }
  })

  const getFieldName = (field, open = false) => {
    return (
      <>
        <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
          {field.name}
        </div>
        {open && harvests.length ? (
          getHarvestStatus()
        ) : (
          <StyledHarvestCount>
            {' '}
            ({getHarvestCount(field.id)})
          </StyledHarvestCount>
        )}
      </>
    )
  }

  const getHarvestCount = (fieldId) => {
    let harvestCount = 0
    const harvestYr = harvestYears.find(
      (obj) => obj.harvestYr == selectedYear && obj.hasHarvest,
    )

    if (harvestYr) {
      harvestCount = harvestYr.fieldIds.filter(
        (item) => item === fieldId,
      ).length
    }
    return harvestCount
  }

  const getHarvestStatus = () => {
    const harvestStatuses = harvests.map((h) => h.status)
    const harvestErrorStatuses = [
      'DATA_RECEIVED_ERROR',
      'LAYER_PROCESSING_NO_LAYERS',
      'LAYER_PROCESSING_ERROR',
    ]
    const harvestProcessingStatuses = [
      'LAYER_PROCESSING_STARTED',
      'DATA_RECEIVED',
      'REFRESH_REQUESTED',
    ]

    let statusIcon = (
      <ErrorIcon
        size="small"
        style={{ color: '#B2023B', height: '0.7em' }}
        title={t('error')}
        titleAccess={t('error')}
      />
    )
    const errorStatusesFound = harvestStatuses.find((h) =>
      harvestErrorStatuses.find((e) => e == h),
    )
    if (errorStatusesFound) {
      return statusIcon
    } else if (harvestStatuses.every((s) => s === harvestStatuses[0])) {
      //Check if all the statuses are the same
      return getHarvestStatusIcon(harvestStatuses[0])
    } else {
      const processingStatusFound = harvestStatuses.find((h) =>
        harvestProcessingStatuses.find((e) => e == h),
      )
      if (processingStatusFound) {
        return getHarvestStatusIcon(processingStatusFound)
      }
    }
  }
  const onEditField = useCallback((field, isOpen, event) => {
    if (!isOpen) {
      dispatch(cancelEditFieldName())
      const elementId = `${field.name}-${field.id}`
      let element = event.target
      if (event.target?.id !== elementId) {
        element = document.getElementById(elementId)
      }
      setIsHarvestActionDrawerOpen(true)
      setHarvestOptionsAnchor(element)
      onFieldSelected(field.id)
    }
    dispatch(startEditField())
    event.stopPropagation()
  })

  const onDeleteField = useCallback((id, isOpen, name, event) => {
    if (id != undefined && name != undefined) {
      event.stopPropagation()
      dispatch(
        showModal(<DeleteField id={id} fieldName={name} />, {
          title: t('delete_field'),
        }),
      )
    }
  })
  const onCancelEdit = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(cancelEditFieldName())
  })

  const onSaveName = useCallback(
    (id, name, farmId) => {
      dispatch(editField(id, { name, farmId }))
    },
    [editName],
  )

  return (
    <StyledRoot
      style={{
        paddingTop: '18px',
        justifyContent: 'space-between',
        visibility: 'visible',
      }}
      className="is_visible"
    >
      <StyledList>
        {orderedFields && orderedFields.length ? (
          orderedFields.map((field, idx) => (
            <Expandable
              id={`${field.name}-${field.id}`}
              key={field.id}
              title={getFieldName(field, openArr[idx])}
              open={openArr[idx]}
              onEditField={(event) => {
                onEditField(field, openArr[idx], event)
              }}
              onDeleteField={(event) => {
                onDeleteField(field.id, openArr[idx], field.name, event)
              }}
              fieldName={field.name}
              onClick={(event) => {
                handleFieldPress(field, openArr[idx], event)
              }}
              saveName={onSaveName}
              farmId={field?.farmId}
              cancelEdit={onCancelEdit}
              edit={editName && openArr[idx]}
              fieldId={field.id}
              position={'fixed'}
            >
              <HarvestPanel
                open={isHarvestActionDrawerOpen && !fieldEdit}
                anchorEl={harvestOptionsAnchor}
                permissions={showPermissions}
                onClickHandler={editHarvest}
              />
            </Expandable>
          ))
        ) : (
          <div>
            <p>
              {t('no_harvests')} / {t('no')} {t('fields')}
            </p>
          </div>
        )}
      </StyledList>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    overflow-y: auto;
  }
`

const StyledHarvestCount = styled.span`
  opacity: 0.28;
  margin-left: 0.25em;
`

const StyledList = styled.ul`
  margin: 18px 0 0 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 1px 11px;
  top: 70px;
  bottom: 8px;
  list-style-type: none;
`
