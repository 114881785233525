import * as c from 'common/c'

export const integrationCreated = (data) => {
  return {
    type: c.TENANT_INTEGRATION_ADDED,
    payload: data,
  }
}

export const integrationUpdated = (data) => {
  return {
    type: c.TENANT_INTEGRATION_UPDATED,
    payload: data,
  }
}

export const tenantIntegrationsFetched = (data) => {
  return {
    type: c.TENANT_INTEGRATIONS_FETCHED,
    payload: data,
  }
}

export const integrationsFetched = (data) => {
  return {
    type: c.INTEGRATIONS_FETCHED,
    payload: data,
  }
}

export const setIntegrationUrls = (o) => {
  return {
    type: c.SET_INTEGRATION_URLS,
    payload: o,
  }
}

export const setClientSyncStatus = (o) => {
  return {
    type: c.SET_CLIENT_SYNC_STATE,
    payload: o,
  }
}

export const setFarmSyncStatus = (o) => {
  return {
    type: c.SET_FARM_SYNC_STATE,
    payload: o,
  }
}

export const setFieldSyncStatus = (o) => {
  return {
    type: c.SET_FIELD_SYNC_STATE,
    payload: o,
  }
}

export const setHarvestSyncStatus = (o) => {
  return {
    type: c.SET_HARVEST_SYNC_STATE,
    payload: o,
  }
}

export const setAllSyncStatus = (o) => {
  return {
    type: c.SET_ALL_SYNC_STATE,
    payload: o,
  }
}

export const setIntegrationSyncErrors = (data) => {
  return {
    type: c.SET_INTEGRATION_SYNC_ERRORS,
    payload: data,
  }
}
