import styled from 'styled-components'

export default function ModalActions(props) {
  return <StyledActions>{props.children}</StyledActions>
}

const StyledActions = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 1em;
  & > * {
    margin-left: 1em;
  }
`
