import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'

declare module 'state/equipment' {
  export type EquipmentModel = {
    createdAt?: string
    equipmentModelId: number | null
    equipmentType: string
    id: string
    makeBrandId: number
    nickname: string
    tenantId: string
    timeDelaySec: number
    updatedAt?: string
    year: number
  }
  export type equipment = {
    collection: EquipmentModel[]
  }
}

const initialState = { collection: [] }
//Ignore errors for update not being found, no idea where it actually comes from
//do not import update from babel core, that breaks everything.
export default (state = initialState, action) => {
  switch (action.type) {
    case c.EQUIPMENT_FETCHED: {
      //@ts-ignore
      return update(state, { collection: { $set: action.payload } })
    }

    case c.ADD_EQUIPMENT: {
      //@ts-ignore
      return update(state, { collection: { $unshift: [action.payload] } })
    }

    case c.DELETE_EQUIPMENT: {
      const idx = findIndex(
        get(state, 'collection', []),
        (equipment: EquipmentModel) => equipment.id === action.payload,
      )
      if (idx > -1) {
        //@ts-ignore
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    case c.EDIT_EQUIPMENT: {
      const index = findIndex(
        get(state, 'collection', []),
        (equipment: EquipmentModel) => equipment.id === action.payload.id,
      )
      if (index > -1) {
        //@ts-ignore
        return update(state, {
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    default:
      return state
  }
}
