import ModalContainer from '../../modal/modal-container'
import ModalActions from '../../modal/modal-actions'
import Button from '@mui/material/Button'
import { closeModal } from 'actions/app'
import { deleteField } from 'api/field'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export default function OCFFDeleteField(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { selectedField } = props
    const { id, name } = selectedField

    const closePopup = () => dispatch(closeModal())

    const deleteItem = (() => {
        dispatch(deleteField(id))
    })

    return (
        <ModalContainer>
            <div>{t('delete_item_warning', { name })}
            </div>
            <ModalActions>
                <Button variant="contained" color="inherit" onClick={closePopup}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={deleteItem}
                >
                    {t('delete')}
                </Button>
            </ModalActions>
        </ModalContainer>
    )
}