import { Button, Tooltip } from '@mui/material'
import styled from 'styled-components'

export function ExpandableToolbarButton(props) {
  return (
    <Tooltip title={props.label} placement="right">
      <StyledContainer expanded={props.expanded} active={props.active}>
        <StyledButton
          expanded={props.expanded}
          active={props.active}
          disabled={props.disabled}
          onClick={props.onClick}
          startIcon={props.icon}
        >
          <span className="label">{props.label}</span>
        </StyledButton>
      </StyledContainer>
    </Tooltip>
  )
}

const StyledContainer = styled.div`
  background-color: #f2f2f2;
  ${(props) => (props.expanded || props.active ? `width:100%;` : `width:32px;`)}
  ${(props) =>
    !props.expanded &&
    props.active &&
    `
  &&&{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
  }
  `}
`

const StyledButton = styled(Button)`
  min-width: 0;
  height: 32px;
  justify-content: start;
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;

  .MuiButton-startIcon {
    margin-left: -2px;
  }
  svg path {
    color: #4a4a4a;
  }
  span.label {
    display: ${(props) => (props.expanded || props.active ? 'inline' : 'none')};
    white-space: nowrap;
  }
  ${(props) =>
    props.active &&
    `
    position:relative;
    width:fit-content;
    background-color:#143157;
    color:#ffffff;

    &:hover{
      background-color:#004780;
      color:#ffffff;
    }
    svg path{
      stroke:#ffffff;
      fill:none;
    }
    `}

  ${(props) =>
    props.disabled &&
    `
    svg{
      opacity:0.26
    }
    `}

    ${(props) => (props.expanded ? `width:100%` : `width:fit-content`)}
`
