import { appReady } from 'actions/app'
import {
  addEmptyItem,
  brandAdded,
  brandDeleted,
  brandsFetched,
  brandUpdated,
  farmCreated,
  removeEmptyItem,
  farmUpdated,
  farmsFetched,
  farmDeleted,
} from 'actions/farm'
import http from 'common/http'
import { shouldShowFarmSetupWizard } from '../actions/app'
import { closeModal } from 'actions/app'
import {
  farmReferenceAdded,
  farmReferenceDeleted,
  farmReferenceUpdated,
} from 'actions/client'

export const createFarm = (data) => {
  return (dispatch) => {
    return http.post('/farm', data).then((res) => {
      dispatch(farmCreated(res.data))
      dispatch(closeModal())
      //For updating the client to show the updated farms
      dispatch(farmReferenceAdded(res.data))
      return {
        data: res.data,
        message: 'Farm created',
      }
    })
  }
}

export const getFarm = (data) => {
  return (dispatch) => {
    return http.get('/farm', data).then((res) => {
      if (Array.isArray(res.data) && res.data.length === 0) {
        dispatch(shouldShowFarmSetupWizard())
      } else {
        dispatch(farmsFetched(res.data))
      }
    })
  }
}

export const getFilteredFarms = (clientId) => {
  return (dispatch) => {
    const url = `/farm?clientId=${clientId}`
    return http.get(url).then((res) => {
      dispatch(farmsFetched(res.data))
    })
  }
}

export const editFarm = (data) => {
  const { name, clientId } = data
  return (dispatch) => {
    return http.put(`/farm/${data['id']}`, { name, clientId }).then((res) => {
      dispatch(closeModal())
      dispatch(farmUpdated(res.data))
      //For updating the client to show the updated farms
      dispatch(farmReferenceUpdated(data))
    })
  }
}
export const deleteFarm = (id, clientId) => {
  return (dispatch) => {
    return http.delete(`/farm/${id}`).then(() => {
      dispatch(closeModal())
      dispatch(farmDeleted(id))
      //For updating the client object to show the number of farms he has
      dispatch(farmReferenceDeleted(id, clientId))
    })
  }
}

export const deleteEmptyObj = (column) => {
  return (dispatch) => {
    dispatch(removeEmptyItem(column))
  }
}

export const addEmptyObj = (id, data, column) => {
  return (dispatch) => {
    dispatch(addEmptyItem(id, data, column))
  }
}

export const farmSetUpComplete = () => {
  return (dispatch) => {
    dispatch(appReady())
  }
}

export function createBrand(data) {
  return (dispatch) => {
    return http
      .post('/make-brand', data)
      .then((res) => {
        dispatch(brandAdded(res.data))
        return res.data
      })
      .catch(() => {})
  }
}

export const updateBrand = (data) => {
  return (dispatch) => {
    return http
      .put('/make-brand', data)
      .then(() => {
        dispatch(brandUpdated(data))
      })
      .catch(() => {})
  }
}

export const deleteBrand = (id) => {
  return (dispatch) => {
    return http
      .delete('/make-brand')
      .then(() => {
        dispatch(brandDeleted(id))
      })
      .catch(() => {})
  }
}

export const fetchBrands = () => {
  return (dispatch) => {
    return http
      .get('/make-brand')
      .then((res) => {
        dispatch(brandsFetched(res.data))
      })
      .catch(() => {})
  }
}
