export type DefaultLayerFormats = {
  label: string
  name: string
  value: string
  isPremium: boolean
}
export type LayerType = {
  name: string
  translation: string
  selected: boolean
  zindex: number
  formats: string[]
  base_layer?: string
  exportName: { [key: string]: string }
}

export const vectorLayers: LayerType[] = [
  {
    name: 'boundary',
    translation: 'boundary',
    selected: true,
    zindex: 10,
    formats: ['google-earth', 'esri'],
    exportName: {
      'google-earth': 'boundary_kml',
      esri: 'boundary_shp',
    },
  },
  {
    name: 'raw_points',
    translation: 'raw_yield_points',
    selected: false,
    zindex: 100,
    formats: ['esri', 'agleader-basic', 'raw-points-isoxml'],
    exportName: {
      esri: 'raw_points_shp',
      'agleader-basic': 'raw_points_agl',
      'raw-points-isoxml': 'raw_points_isoxml',
    },
  },
  {
    name: 'corrected_points',
    translation: 'corrected_yield_points',
    selected: false,
    zindex: 75,
    formats: ['esri', 'agleader-basic', 'corrected-points-isoxml'],
    exportName: {
      esri: 'corrected_points_shp',
      'agleader-basic': 'corrected_points_agl',
      'corrected-points-isoxml': 'corrected_points_isoxml',
    },
  },
]
export const rasterLayers: LayerType[] = [
  {
    name: 'corrected_yield',
    translation: 'corrected_yield_map',
    selected: false,
    zindex: 25,
    formats: ['google-earth', 'agleader-advanced', 'climate', 'sms'],
    exportName: {
      'google-earth': 'corrected_yield_kmz',
      climate: 'corrected_yield_agl',
      'agleader-advanced': 'corrected_yield_agl',
      sms: 'corrected_yield_agl_shp',
    },
  },
  {
    name: 'zones_yield',
    translation: 'classified_yield_map',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'zones_yield_kmz_raster',
    },
  },
  {
    name: 'zones_yield_polygon',
    base_layer: 'zones_yield',
    translation: 'classified_yield_map_polygon',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'zones_yield_kmz_polygon',
    },
  },
  {
    name: 'trend_yield',
    translation: 'yield_map_trend',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'trend_raster_kmz_raster',
    },
  },
  {
    name: 'trend_yield_polygon',
    base_layer: 'trend_yield',
    translation: 'yield_map_trend_polygon',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'trend_raster_kmz_polygon',
    },
  },
  {
    name: 'raw_raster',
    translation: 'raw_yield_map',
    selected: false,
    zindex: 25,
    formats: ['google-earth', 'agleader-advanced', 'climate', 'sms'],
    exportName: {
      'google-earth': 'raw_raster_kmz',
      'agleader-advanced': 'raw_raster_agl',
      climate: 'raw_raster_agl_shp',
      sms: 'raw_raster_agl_shp',
    },
  },
  {
    name: 'raw_moisture',
    translation: 'raw_moisture_map',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'raw_raster_kmz',
    },
  },
  {
    name: 'trend_moisture',
    translation: 'trend_moisture_map',
    selected: false,
    zindex: 25,
    formats: ['google-earth'],
    exportName: {
      'google-earth': 'trend_moisture',
    },
  },
  {
    name: 'swath',
    translation: 'swath_map',
    selected: false,
    zindex: 25,
    formats: ['esri', 'swath-isoxml'],
    exportName: {
      esri: 'swath',
      'swath-isoxml': 'swath_isoxml',
    },
  },
  {
    name: 'header_height',
    translation: 'header_height',
    selected: false,
    zindex: 25,
    formats: ['esri', 'header-height-isoxml'],
    exportName: {
      'google-earth': 'header_height_kmz',
      'header-height-isoxml': 'header_height_isoxml',
    },
  },
]

export const defaultFormatOptions: DefaultLayerFormats[] = [
  {
    label: 'google_earth',
    name: 'google-earth',
    value: 'google-earth',
    isPremium: false,
  },
  {
    label: 'esri',
    name: 'esri',
    value: 'esri',
    isPremium: false,
  },
  {
    label: 'agleader_basic',
    name: 'agleader-basic',
    value: 'agleader-basic',
    isPremium: false,
  },
  {
    label: 'agleader_advanced',
    name: 'agleader-advanced',
    value: 'agleader-advanced',
    isPremium: false,
  },
  {
    label: 'climate',
    name: 'climate',
    value: 'climate',
    isPremium: false,
  },
  {
    label: 'sms',
    name: 'sms',
    value: 'sms',
    isPremium: false,
  },
  {
    label: 'swath_isoxml',
    name: 'swath-isoxml',
    value: 'swath-isoxml',
    isPremium: true,
  },
  {
    label: 'corrected_points_isoxml',
    name: 'corrected-points-isoxml',
    value: 'corrected-points-isoxml',
    isPremium: true,
  },
  {
    label: 'raw_points_isoxml',
    name: 'raw-points-isoxml',
    value: 'raw-points-isoxml',
    isPremium: true,
  },
  {
    label: 'trend_moisture_map',
    name: 'trend_moisture',
    value: 'trend_moisture',
    isPremium: false,
  },
  {
    label: 'header_height',
    name: 'header_height',
    value: 'header_height',
    isPremium: true,
  },
]

export const freeLayers: LayerType[] = [
  vectorLayers[0],
  vectorLayers[1],
  rasterLayers[5],
]
