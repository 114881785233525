import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
} from '@mui/material'
import styled from 'styled-components'
import { updateCountryExtent } from 'actions/field'
import { editTenantPreferences, editTenant } from 'api/tenant'
import { sortBy } from 'lodash-es'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import * as c from 'common/c'

export default function PreferencesComponent() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const permissions = useSelector((state) => state.account.permissions)
  const customClaims = useSelector((state) => state.account.customClaims)
  const preferenceOptions = useSelector((state) => state.account.options)
  const initialPrefs = useSelector((state) => state.account.singleton.prefs)
  const tenantObj = useSelector((state) => state.account.singleton)
  const clients = useSelector((state) => state.client.collection)
  const fetchedFarms = useSelector((state) => state.farm.collection)
  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )
  const selectedClient = useSelector(
    (state) => state.account.singleton.prefs?.cff?.client ?? null,
  )
  const autoSyncEnabled = useSelector(
    (state) => state.account.singleton.prefs?.autosync?.enabled ?? false,
  )

  const [farms, setFarms] = useState([...fetchedFarms])
  const [countries, setCountries] = useState([])
  const [canEdit, setCanEdit] = useState(false)
  const [canEditCountry, setEditCountry] = useState(false)
  const [canEditAutoSync, setEditAutoSync] = useState(false)

  useEffect(() => {
    if (preferenceOptions?.countries) {
      setCountries(
        sortBy(
          Object.keys(preferenceOptions.countries).map((key) => ({
            ...preferenceOptions.countries[key],
            id: key,
          })),
          ['name'],
        ),
      )
    }
  }, [preferenceOptions])

  useEffect(() => {
    if (clients && clients.length && selectedClient) {
      const client = parseInt(selectedClient)
      const foundClient = clients.find((c) => c.id === client)
      if (foundClient) {
        const farmsPerClient = [...foundClient.farms]
        setFarms(farmsPerClient)
        if (farmsPerClient && farmsPerClient.length) {
          dispatch(
            editTenantPreferences({
              path: 'cff.farm',
              value: parseInt(farmsPerClient[0].id),
            }),
          )
        }
      }
    }
  }, [selectedClient, clients])

  useEffect(() => {
    setCanEdit(permissions.includes('farm:write'))
    setEditCountry(
      permissions.includes('farm:write') &&
        permissions.includes('farm:read') &&
        permissions.includes('field:write') &&
        permissions.includes('field:read') &&
        permissions.includes('harvest:write') &&
        permissions.includes('harvest:read') &&
        permissions.includes('harvest:admin') &&
        permissions.includes('reports:read') &&
        permissions.includes('tenant:list') &&
        permissions.includes('users:write'),
    )
    setEditAutoSync(
      permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM],
    )
  }, [permissions])

  const onPrefChange = (e) => {
    const { name, value } = e.target
    let path = ''
    if (name === 'country') {
      let selectedCountry = countries.find((i) => i.id === value)
      dispatch(editTenant(...tenantObj, { country: selectedCountry })).then(
        () => {
          let extent
          countries.filter((item, index) => {
            if (item.id === value) extent = countries[index].extent
          })
          return dispatch(updateCountryExtent(extent))
        },
      )
    } else {
      path = `units.${name}_in`.toLowerCase()
      dispatch(
        editTenantPreferences({
          path,
          value: value,
        }),
      )
    }
  }

  const onCFFChange = (e) => {
    const { name, value } = e.target
    dispatch(
      editTenantPreferences({
        path: `cff.${name}`,
        value: parseInt(value),
      }),
    )
  }

  const onCFFToggleChange = () => {
    const previousCFFStatus = cffEnabled
    dispatch(
      editTenantPreferences({
        path: 'cff.enabled',
        value: !previousCFFStatus,
      }),
    )

    // If the toggle is switched to enabled
    if (!previousCFFStatus) {
      if (!initialPrefs.cff?.farm) {
        let farm = farms[0]
        dispatch(
          editTenantPreferences({
            path: 'cff.farm',
            value: parseInt(farm.id),
          }),
        )
      }
      if (!initialPrefs.cff?.client) {
        let client = clients[0]
        dispatch(
          editTenantPreferences({
            path: 'cff.client',
            value: parseInt(client.id),
          }),
        )
      }
    }
  }

  const onAutoSyncToggleChange = async () => {
    const previousAutoSyncStatus = autoSyncEnabled
    console.log('previousAutoSyncStatus:', previousAutoSyncStatus)
    await dispatch(
      editTenantPreferences({
        path: 'autosync.enabled',
        value: !previousAutoSyncStatus,
      }),
    )

    if (!previousAutoSyncStatus) {
      if (!initialPrefs.autosync?.sync_frequency_min) {
        await dispatch(
          editTenantPreferences({
            path: 'autosync.sync_frequency_min',
            value: 60,
          }),
        )
      }
      if (!initialPrefs.autosync?.layer_processing_start_hour) {
        await dispatch(
          editTenantPreferences({
            path: 'autosync.layer_processing_start_hour',
            value: 0,
          }),
        )
      }
    }
  }

  const onAutoSyncChange = (e) => {
    const { name, value } = e.target
    dispatch(
      editTenantPreferences({
        path: `autosync.${name}`,
        value: parseInt(value),
      }),
    )
  }

  const changeItemKey = (item) => {
    return `${item}_in`.toLowerCase()
  }

  if (initialPrefs) {
    return (
      (<StyledContainer mx={2} my={2} padding={2} width={1}>
        {/* <Typography variant="h5" style={{ textAlign: 'center' }}><b>{t('preferences')}</b></Typography> */}
        <FormControl disabled={!canEdit} component="fieldset" fullWidth>
          <FormGroup aria-label="position">
            <Box my={2}>
              <FormControlLabel
                control={
                  <StyledTextField
                    id="outlined-select-country-native"
                    disabled={!canEditCountry}
                    select
                    name="country"
                    value={tenantObj?.country.id}
                    onChange={onPrefChange}
                    SelectProps={{ native: true }}
                    variant="outlined"
                    size="small"
                    placeholder={t('select_country')}
                  >
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {t(country.name)}
                      </option>
                    ))}
                  </StyledTextField>
                }
                label={t('Country')}
                labelPlacement="start"
              />
            </Box>
            {/* Units Form start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{t('units')}</FormLabel>

                {preferenceOptions.units &&
                Object.keys(preferenceOptions.units) ? (
                  Object.keys(preferenceOptions.units).map((item) => (
                    <FormGroup aria-label="position" key={item}>
                      <Box my={1}>
                        <FormControlLabel
                          control={
                            <StyledTextField
                              disabled={!canEdit}
                              select
                              name={item}
                              value={initialPrefs.units[changeItemKey(item)]}
                              onChange={onPrefChange}
                              SelectProps={{ native: true }}
                              variant="outlined"
                              size="small"
                            >
                              {Object.keys(preferenceOptions.units[item]).map(
                                (innerItem) => (
                                  <option value={innerItem} key={innerItem}>
                                    {t(innerItem)}
                                  </option>
                                ),
                                // <option value={innerItem} key={innerItem}>{t([innerItem])}</option>
                              )}
                            </StyledTextField>
                          }
                          label={t(
                            item
                              .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
                              .toLowerCase(),
                          )}
                          labelPlacement="start"
                        />
                      </Box>
                    </FormGroup>
                  ))
                ) : (
                  <></>
                )}
              </FormControl>
            </Box>
            {/* Units form end */}
            {/* CFF preferences start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{t('cff')}</FormLabel>
                <Box my={1}>
                  <FormControlLabel
                    value="enable"
                    control={
                      <Switch
                        color="primary"
                        onChange={onCFFToggleChange}
                        checked={cffEnabled}
                      />
                    }
                    label={t('enable_cff')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <StyledTextField
                        disabled={!cffEnabled}
                        select
                        name={'client'}
                        value={initialPrefs.cff?.client ?? clients[0]?.id ?? ''}
                        SelectProps={{ native: true }}
                        variant="outlined"
                        size="small"
                        onChange={onCFFChange}
                      >
                        {clients.map((client) => {
                          return (
                            <option value={client.id} key={client.id}>
                              {client.name}
                            </option>
                          )
                        })}
                      </StyledTextField>
                    }
                    label={t('default_client')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <StyledTextField
                        disabled={!cffEnabled}
                        select
                        name={'farm'}
                        value={initialPrefs.cff?.farm ?? farms[0]?.id ?? ''}
                        SelectProps={{ native: true }}
                        variant="outlined"
                        size="small"
                        onChange={onCFFChange}
                      >
                        {farms.map((farm) => {
                          return (
                            <option value={farm.id} key={farm.id}>
                              {farm.name}
                            </option>
                          )
                        })}
                      </StyledTextField>
                    }
                    label={t('default_farm')}
                    labelPlacement="start"
                  />
                </Box>
              </FormControl>
            </Box>
            {/* Automatic File Sync preferences start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{t('auto_sync')}</FormLabel>
                <Box my={1}>
                  <FormControlLabel
                    value="enable"
                    control={
                      <Switch
                        color="primary"
                        disabled={!canEditAutoSync}
                        onChange={onAutoSyncToggleChange}
                        checked={autoSyncEnabled}
                      />
                    }
                    label={t('auto_sync_enabled')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <StyledTextField
                        disabled={!autoSyncEnabled || !canEditAutoSync}
                        select
                        name="sync_frequency_min"
                        value={initialPrefs.autosync?.sync_frequency_min ?? 60}
                        SelectProps={{ native: true }}
                        variant="outlined"
                        size="small"
                        onChange={onAutoSyncChange}
                      >
                        {[
                          <option value={30} key={30}>
                            30
                          </option>,
                          <option value={60} key={60}>
                            60
                          </option>,
                        ]}
                      </StyledTextField>
                    }
                    label={t('sync_frequency_min')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <StyledTextField
                        disabled={!autoSyncEnabled || !canEditAutoSync}
                        name="layer_processing_start_hour"
                        select
                        value={
                          initialPrefs.autosync?.layer_processing_start_hour ??
                          0
                        }
                        SelectProps={{ native: true }}
                        variant="outlined"
                        size="small"
                        onChange={onAutoSyncChange}
                      >
                        {Array.from({ length: 24 }, (_, i) => i).map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </StyledTextField>
                    }
                    label={t('layer_processing_start_hour')}
                    labelPlacement="start"
                  />
                </Box>
              </FormControl>
            </Box>
          </FormGroup>
        </FormControl>
      </StyledContainer>)
    );
  } else {
    return <></>
  }
}

const StyledContainer = styled(Box)`
  border-style: solid;
  border-width: 1px;
  border-color: #003057;
  border-radius: 10px;
  height: max-content;
  margin-top: 30px;
  overflow-y: scroll;
`

const StyledTextField = styled(TextField)`
  width: 70%;
`
