import styled from 'styled-components'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import CardActionArea from '@mui/material/CardActionArea'

const useStyles = makeStyles(() => ({
  optionSelected: {
    border: '3px solid #003057 !important',
  },
}))

export default function CustomRadioButton({ options, setSelectedOption }) {
  //const { options } = props
  const classes = useStyles()

  const [option, setOption] = useState()

  useEffect(() => {
    if (!option) {
      setOption(options[0].defaultSelection)
      setSelectedOption(options[0].defaultSelection)
    }
  }, [options])

  const handleOnChange = useCallback((option) => {
    setOption(option)
    setSelectedOption(option)
  })

  return (
    <Box display="flex" justifyContent="flex-start">
      {options.map((item) => (
        <StyledCard
          key={item.label}
          className={option === item.value ? classes.optionSelected : ''}
          style={{ marginRight: '20px' }}
        >
          <CardActionArea
            centerRipple={false}
            onClick={() => handleOnChange(item.value)}
            style={{
              padding: '10px 30px',
            }}
          >
            <StyledLabel
              htmlFor={item.label}
              style={{ backgroundImage: `url(${item.buttonImage})` }}
            >
              {item.label}
            </StyledLabel>
          </CardActionArea>
        </StyledCard>
      ))}
    </Box>
  )
}
const StyledLabel = styled.label`
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 135px;
  height: 75px;
  line-height: 200px;
`
const StyledCard = styled(Card)`
  border-radius: 10px;
  border: 1px solid #003057;
`
