import styled from 'styled-components'

export default function ModalContainer(props) {
  return <StyledContainer>{props.children}</StyledContainer>
}

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 40em;
`
