import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { get, isEmpty } from 'lodash-es'
import type { AppState } from 'state'
import type { Tenant } from 'state/account'
import { useTranslation } from 'react-i18next'
import { closeModal } from 'actions/app'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog } from '@mui/material'
import logo from 'images/farmtrx-logo.png'
import { clearIntegrationData } from 'common/integrationUtils'

export default function AccountImproperConfig() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  const handleLogout = () => {
    dispatch(closeModal())
    clearIntegrationData()
    //Remove the CFF localStorage items
    localStorage.removeItem('selectedClient')
    localStorage.removeItem('selectedFarms')
    //End
    logout({ returnTo: window.location.origin })
  }
  const partner: Tenant = useSelector((state) =>
    get(state as AppState, 'account.partnerContactDetails'),
  )

  let partnerName: string | undefined = 'FarmTRX'
  let partnerEmail: string | undefined = 'support@farmtrx.com'
  if (!isEmpty(partner)) {
    partnerName = partner.fullName
    partnerEmail = partner.email
  }

  return (
    <Dialog open={true}>
      <Box
        px={3}
        py={4}
        sx={{
          display: 'flex',
          maxHeight: '70vh',
          margin: 'auto',
          overflowY: 'auto',
          padding: '2rem',
          maxWidth: '60vw',
          alignItems: 'center',
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <img width="160" height="30" src={logo} />
          </Grid>
          <Grid item xs={12}>
            {t('account_improper_config', { partner_name: partnerName })}
          </Grid>
          <Grid item xs={12}>
            {t('email')}: {partnerEmail}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              {t('logout')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
