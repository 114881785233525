import { debounce } from 'lodash-es'
import useIsMounted from 'hooks/util/is-mounted'

export default function useDebounce(cb, delay) {
  const options = {
    leading: false,
    trailing: true,
  }
  const inputsRef = useRef({ cb, delay })

  const isMounted = useIsMounted()
  useEffect(() => {
    inputsRef.current = { cb, delay }
  })
  return useCallback(
    debounce(
      (...args) => {
        if (inputsRef.current.delay === delay && isMounted()) {
          inputsRef.current.cb(...args)
        }
      },
      delay,
      options,
    ),
    [delay, debounce],
  )
}
