import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  primary: '#3475E0',
  palette: {
    primary: { main: '#3475E0' },
    secondary: { main: '#f89822' }, //'#F8991D''
    neutral: { main: '#F9F7F7' },
    text: {
      primary: '#262626',
      fontSize: '0.8rem', //not working
    },
  },
  typography: {
    allVariants: { color: '#262626' },
    colorTextSecondary: { color: '#262626' },
    h6: {
      marginBlock: '0 !important',
    },
  },
  spacing: 8,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          // '&:hover': {
          //   color: '#277fb2',
          //   textDecoration: 'none',
          // },
          '&:active': {
            color: '#277fb2',
            textDecoration: 'none',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:checked': {
            color: '#277fb2',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: { root: { color: '#70787D', opacity: 0.8 } },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          color: '#003057',
          fontSize: '45px',
        },
        iconOpen: { transform: 'rotate(180deg)' },
        selectMenu: { height: '50px' },
      },
    },
    MuiIconButton: {
      styleOverrides: { root: { padding: '0' } },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        gutters: {
          paddingLeft: '5px',
          paddingRight: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        labelPlacementStart: {
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          fontSize: '1rem',
          // marginBottom: '10px'
        },
      },
    },
    MuiFormLabel: { styleOverrides: { root: { textDecoration: 'none' } } },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: '5px' },
        input: { padding: '8px' },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '25ch',
          '& .MuiFilledInput-input': {
            padding: '10px',
          },
          '& .MuiNativeSelect-select': { padding: '8px' },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: { inputMarginDense: { paddingTop: '10px' } },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: { background: '#fff' },
        root: {
          '.MuiOutlinedInput-root': {
            padding: '8px',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: { color: '#E0CCB0' },
        root: {
          '& .MuiStepLabel-label.Mui-active': { color: '#E0CCB0' },
          '& .MuiStepLabel-label.Mui-completed': { color: '#E0CCB0' },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#685D53', //'#F8981D',
          fontWeight: 'bold',
          '&.Mui-active': { color: '#F8981D !important' },
          '&.Mui-completed': { color: '#E0CCB0 !important' },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          height: '30px',
          background: '#F8F8F9',
          color: '#61758E',
        },
        body: {
          height: '30px',
          fontWeight: 'bold',
          paddingRight: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: { color: '#439313' },
      },
    },
  },
})

export default theme
