import { FloatingPanel } from 'components/floating'
import { InfoGroup, InfoLine } from 'components/info'
import { convertYieldToPref, convertAreaToPref } from 'common/unitConvert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Popover from '@mui/material/Popover'
import { Typography } from '@mui/material'

export default function FieldInfo({ field, harvest }) {
  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const prefsUnit = userPrefs?.units?.totalyield_in
  const endDate = harvest?.endDate || ''
  const startDate = harvest?.startDate || ''
  const crop = harvest?.crop

  const harvestedArea = harvest?.stats?.harvested_area_m2 || 0
  const fieldArea = harvest?.stats?.field_area_m2 || 0
  const total_yield_g = harvest?.stats?.total_yield_g || 0
  const avg_yield_g_m2 = harvest?.stats?.avg_yield_g_m2 || 0
  const avg_harvested_yield_g_m2 = harvest?.stats?.avg_harvested_yield_g_m2 || 0
  const buPerTonne = crop?.buPerTonne || 1

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <FloatingPanel position="bottom" background="white">
      <FloatingPanel.Header>{t('agronomic_layer_data')}</FloatingPanel.Header>
      <InfoGroup>
        <InfoGroup.Section>
          <InfoLine label={t('field_name')} value={field?.name} />
          <InfoLine label={t('crop_type')} value={crop?.name} />
          <InfoLine
            label={t('total_yield')}
            value={`${convertYieldToPref(
              total_yield_g,
              prefsUnit,
              buPerTonne,
            ).toFixed(2)} ${t(prefsUnit)}`}
          />
        </InfoGroup.Section>
        <InfoGroup.Section>
          <InfoLine
            label={t('field_area')}
            value={`${convertAreaToPref(
              fieldArea,
              userPrefs['units']['area_in'],
            ).toFixed(2)} ${t(userPrefs['units']['area_in'])}`}
          />
          <InfoLine
            label={t('average_yield')}
            value={`${convertYieldToPref(
              avg_yield_g_m2,
              userPrefs['units']['yieldunit_in'],
              buPerTonne,
            ).toFixed(2)} ${t(userPrefs['units']['yieldunit_in'])}`}
          />
          <InfoLine label={t('start_date')} value={startDate} />
        </InfoGroup.Section>
        <InfoGroup.Section>
          <InfoLine
            label={t('harvested_area')}
            icon={<InfoOutlinedIcon onClick={handleClick} />}
            value={`${convertAreaToPref(
              harvestedArea,
              userPrefs['units']['area_in'],
            ).toFixed(2)} ${t(userPrefs['units']['area_in'])}`}
          />
          {open ? (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography
                sx={{
                  p: 1,
                  fontSize: '10px',
                }}
              >
                {t('includes_swath_overlap')}
              </Typography>
            </Popover>
          ) : null}
          <InfoLine
            label={t('harvested_average_yield')}
            value={`${convertYieldToPref(
              avg_harvested_yield_g_m2,
              userPrefs['units']['yieldunit_in'],
              buPerTonne,
            ).toFixed(2)} ${t(userPrefs['units']['yieldunit_in'])}`}
          />
          <InfoLine label={t('end_date')} value={endDate} />
        </InfoGroup.Section>
      </InfoGroup>
    </FloatingPanel>
  )
}
