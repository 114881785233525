import OrganizationsList from 'components/organizations'
import { switchClient, fetchOrganizations } from 'api/user'
import { ImpersonateCheck } from '../impersonate-check'
import { clearIntegrationData } from 'common/integrationUtils'

export function OrganizationsComponent() {
  const [impersonate, setImpersonate] = useState()
  const [visitingFarmName, setVisitingFarmName] = useState()
  const dispatch = useDispatch()
  const organizations = useSelector((state) => state.account.organizations)
  const token = useSelector((state) => state.account['token'])

  useEffect(() => {
    setImpersonate(!!localStorage.getItem('impersonate'))
    setVisitingFarmName(localStorage.getItem('visitingFarmName'))
  }, [])

  useEffect(() => {
    if (typeof impersonate === 'undefined' && token) {
      dispatch(fetchOrganizations())
    } else {
      setImpersonate(impersonate)
    }
  }, [impersonate])

  const onClickSwitch = useCallback(
    (c) => {
      clearIntegrationData()
      dispatch(switchClient(c.tenantId))
      setVisitingFarmName(localStorage.setItem('visitingFarmName', c.farmName))
      //Remove the CFF localStorage items
      localStorage.removeItem('selectedClient')
      localStorage.removeItem('selectedFarms')
      //End
    },
    [visitingFarmName],
  )

  return (
    <ImpersonateCheck showMessage={true}>
      <OrganizationsList
        organizations={organizations}
        onClick={onClickSwitch}
      />
    </ImpersonateCheck>
  )
}
