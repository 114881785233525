import { AppBar, List, ListItem, ListItemText, Toolbar } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import Logo from 'components/logo'
import Logout from 'components/logout'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import NotificationsIcon from '@mui/icons-material/Notifications'
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined'
import IconButton from '@mui/material/IconButton'

import { ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material'

import { ImpersonateCheck } from '../components/impersonate-check'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },

  menuButtons: {
    marginRight: theme.spacing(1),
    flexGrow: 2,
  },
  active: {
    borderRadius: '4px',
    // backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
    color: '#3475E0',
    width: 'inherit',
    fontWeight: 'bold',
  },
  farmLinks: {
    // color: theme.palette.primary.main,
    color: 'gray',
    textDecoration: 'none',
    display: 'flex',
    width: 'inherit',
    // fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}))

const StyledLink = withStyles({})(ListItem)

const StyledLinkText = withStyles({})(ListItemText)

export default function Header() {
  const classes = useStyles()
  const { t } = useTranslation()

  const permissions = useSelector((state) => state.account.permissions)

  const [showFields, setShowField] = useState(false)
  const [showReports, setShowReports] = useState(false)
  const [showAdmin, setShowAdmin] = useState(false)
  const [showSupport] = useState(false)
  const [showFarmSetup, setShowFarmSetup] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const tenantObj = useSelector((state) => state.account.singleton)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onHelp = () => {
    return window.open(`${HELPGUIDE_URL}`, '_blank')
  }

  useEffect(() => {
    setShowField(permissions.includes('field:read'))
    setShowReports(permissions.includes('reports:read'))
    setShowAdmin(
      permissions.includes('users:write') ||
        permissions.includes('tenant:list'),
    )
    //setShowSupport(permissions.includes('farm:read'));
    setShowFarmSetup(permissions.includes('farm:read'))
  }, [permissions])

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Logo />
          <div className={classes.menuButtons}>
            <List style={{ display: 'flex' }}>
              {showFields && (
                <StyledLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                    to="/"
                  >
                    <StyledLinkText disableTypography primary={t('map')} />
                  </NavLink>
                </StyledLink>
              )}
              {showFarmSetup && (
                <StyledLink>
                  <NavLink
                    to="/fields"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                  >
                    <StyledLinkText disableTypography primary={t('fields')} />
                  </NavLink>
                </StyledLink>
              )}
              {showReports && (
                <StyledLink>
                  <NavLink
                    to="/reports"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                  >
                    <StyledLinkText disableTypography primary={t('reports')} />
                  </NavLink>
                </StyledLink>
              )}
              {showSupport && (
                <StyledLink>
                  <NavLink
                    to="/support"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                  >
                    <StyledLinkText disableTypography primary={t('support')} />
                  </NavLink>
                </StyledLink>
              )}
              {showFarmSetup && (
                <StyledLink>
                  <NavLink
                    to="/farmSetup"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                  >
                    <StyledLinkText
                      style={{ whiteSpace: 'nowrap' }}
                      disableTypography
                      primary={t('farm_setup')}
                    />
                  </NavLink>
                </StyledLink>
              )}
              {showAdmin && (
                <StyledLink style={{ textAlign: 'center' }}>
                  <NavLink
                    to="/admin"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes.farmLinks
                    }
                  >
                    <StyledLinkText disableTypography primary={t('admin')} />
                  </NavLink>
                </StyledLink>
              )}
            </List>
          </div>
          <div
            style={{
              display: 'flex',
              flexGrow: '5',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <li style={{ padding: '8px', listStyle: 'none' }}>
              <b>{tenantObj?.['displayName']}</b>
            </li>
            <ImpersonateCheck />
            <Divider orientation="vertical" variant="middle" flexItem />

            {/* Hide NotificationsIcon till we implement Notifications */}
            <IconButton size="small" sx={{ ml: 2, display: 'none' }}>
              <NotificationsIcon />
            </IconButton>
            <Tooltip title={t('help')}>
              <IconButton size="small" sx={{ ml: 2 }} onClick={handleClick}>
                <HelpOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 8,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={onHelp}>
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
                {t('help')}
              </MenuItem>
            </Menu>
            <Divider />
            <Logout />
          </div>
          <Outlet />

          {/*<SettingsApplications color="primary" onClick={showPreferenceModal} />*/}
        </Toolbar>
      </AppBar>
    </div>
  )
}
