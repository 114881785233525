import http from 'common/http'

import type { EquipmentModel } from 'state/equipment'

declare module 'api/equipment-model' {
  export type EquipmentFetchModel = {
    ids?: (string | undefined)[]
    makeBrandId?: number
    equipmentType?: string
  }
  export type equipmentCreateModel = {
    name: string
    id: string
  }
  export type EquipmentModelAPIRes = {
    id: number
    equipmentType: string
    name: string
    timeDelaySec: null | number
    createdAt: string
    updatedAt: string
    tenantId: string
    makeBrandId: number
  }
  //@ts-ignore
  export function createEquipmentModel(
    data: Partial<EquipmentModelAPIRes> | EquipmentModelAPIRes,
  ): Promise<EquipmentModelAPIRes | void>
  //@ts-ignore
  export function updateEquipmentModel(
    id: number,
    data: EquipmentModel,
  ): Promise<EquipmentModelAPIRes | void>
  //@ts-ignore
  export function deleteEquipmentModel(id: number): Promise<void>
  //@ts-ignore
  export function fetchEquipmentModels(
    data: EquipmentFetchModel,
  ): Promise<EquipmentModelAPIRes[] | void>
}

export function createEquipmentModel(
  data: Partial<EquipmentModelAPIRes> | EquipmentModelAPIRes,
): Promise<EquipmentModelAPIRes | void> {
  return http
    .post('/equipment-model', data)
    .then((res) => {
      const val: EquipmentModelAPIRes = res.data
      return val
    })
    .catch(() => {
      return
    })
}

export function updateEquipmentModel(
  id: number,
  data: EquipmentModel,
): Promise<EquipmentModelAPIRes> | Promise<void> {
  return http
    .put(`/equipment-model/${id}`, data)
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return
    })
}

export function deleteEquipmentModel(id: number): Promise<void> {
  return http
    .delete(`/equipment-model/${id}`)
    .then(() => {
      return
    })
    .catch(() => {
      return
    })
}

export function fetchEquipmentModels(
  data: EquipmentFetchModel,
): Promise<EquipmentModelAPIRes[] | void> {
  const { ids, makeBrandId, equipmentType } = data
  return http
    .get(`/equipment-model`, {
      params: {
        ids: ids ? ids.join(',') : undefined,
        makeBrandId,
        equipmentType,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch(() => {})
}
