import ModalInternal from './modal-internal'

const MODAL_ROOT = document.getElementById('modal-el')

export default function Modal(props) {
  const { component, onClose, title } = props

  useEffect(() => {
    const overlayEl = document.createElement('div')
    overlayEl.className = 'overlay'
    document.body.appendChild(overlayEl)

    return function cleanup() {
      document.body.removeChild(overlayEl)
    }
  }, [component])

  const modalEl = (
    <ModalInternal onClose={onClose} title={title}>
      {component}
    </ModalInternal>
  )

  return ReactDOM.createPortal(modalEl, MODAL_ROOT)
}

Modal.propTypes = {
  title: PropTypes.string,
  component: PropTypes.node,
  onClose: PropTypes.func,
}
