import useMap from './use-map'

import { useEffect, useState } from 'react'

export default function useMapCoordinate(): number[] {
  const map = useMap()

  const [coordinate, setCoordinate] = useState([0, 0])

  useEffect(() => {
    if (!map) return

    function onClick(e) {
      setCoordinate(e.coordinate)
    }

    map.on('singleclick', onClick)

    return () => {
      map.un('singleclick', onClick)
    }
  }, [map])

  return coordinate
}
