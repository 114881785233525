import * as c from 'common/c'

export const tenantFetched = (data) => {
  return {
    type: c.TENANT_FETCHED,
    payload: data,
  }
}

export const setEditTenant = (data) => {
  return {
    type: c.EDIT_TENANT_BOOL,
    payload: data,
  }
}

export const optionsFetched = (data) => {
  return {
    type: c.OPTIONS_FETCHED,
    payload: data,
  }
}

export const setAuth = (data) => {
  return {
    type: c.AUTH_DATA,
    payload: data,
  }
}

export const setPermissions = (data) => {
  return {
    type: c.PERMISSIONS,
    payload: data,
  }
}

export const setHasTokenWithPermissionsRetries = (data) => {
  return {
    type: c.HAS_TOKEN_WITH_PERMISSIONS_RETRIES,
    payload: data,
  }
}

export const setToken = (data) => {
  return {
    type: c.SET_TOKEN,
    payload: data,
  }
}

export const hasToken = () => {
  return { type: c.HAS_TOKEN }
}

export const setLocalTenantDetails = (data) => {
  return {
    type: c.SET_LOCAL_TENANT_FORM_DATA,
    payload: data,
  }
}

export const accessibleAccountsFetched = (data) => {
  return {
    type: c.ACCESSIBLE_ACCOUNTS_FETCHED,
    payload: data,
  }
}

export const invitedUsersFetched = (data) => {
  return {
    type: c.INVITED_USERS_FETCHED,
    payload: data,
  }
}

export const setCustomClaims = (data) => {
  return {
    type: c.SET_CUSTOM_CLAIMS,
    payload: data,
  }
}
