import http from 'common/http'
import { hardwareFetched } from 'actions/hardware'

export const fetchHardware = () => {
  return (dispatch) => {
    return http.get('/hardware').then((res) => {
      dispatch(hardwareFetched(res.data))
    })
  }
}
