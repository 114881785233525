import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'

import ClearIcon from '@mui/icons-material/Clear'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import { fetchCropsWithCropVariety } from 'api/crop'
import {
  addCropVariety,
  deleteCropVariety,
  editCropVariety,
} from 'api/crop-variety'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import ShortTextField from '../short-text-field'
import colors from 'common/colors'

import { convertTestweight, KG_HL } from 'common/unitConvert'
import { getTranslatedName } from 'common/misc'
import Alert from '@mui/material/Alert'

const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  button: { margin: theme.spacing(1) },
  borderBottomCustom: { borderBottom: 'none' },
  customFontSize: { fontSize: '1rem' },
  requiredField: { color: 'red' },
  styledButton: {
    borderRadius: '25px',
    marginTop: '20px',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
}))

export default function CropsComponent() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const permissions = useSelector((state) => state.account.permissions)
  const cropConstants = useSelector(
    (state) => state.crop['cropConstantsCollection'],
  )
  const [sortedCropConstants, setSortedCropConstants] = useState([])
  const cropVarieties = useSelector((state) => state.cropVariety['collection'])
  const [rows, setRows] = useState(cropVarieties)
  const testWeightUnit = useSelector(
    (state) => state.account.singleton.prefs?.units?.testweight_in,
  )

  const [canEdit, setCanEdit] = useState(false)
  const [rowSelected, setSelectedRow] = useState()
  const [addButtonClicked, toggleAddRow] = useState(false)
  const [errors, setErrors] = useState({})

  const [editObj, setEditObj] = useState({})
  const [disableDeleteItem, setDisableDeleteItem] = useState(false)

  useEffect(() => {
    setCanEdit(permissions.includes('farm:write'))
  }, [permissions])

  useEffect(() => {
    if (cropConstants.length > 0) {
      const _sortedCropConstants = cropConstants.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA > nameB) {
          return 1
        } else if (nameA < nameB) {
          return -1
        }
        return 0
      })
      setSortedCropConstants(_sortedCropConstants)
    }
  }, [cropConstants])

  useEffect(() => {
    if (cropVarieties.length) {
      setRows(cropVarieties)
    } else {
      setRows([])
    }
  }, [cropVarieties])

  const enableSelectedRow = useCallback(
    (row) => {
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      row.testWeightKgHl = convertTestweight(
        row.testWeightKgHl,
        KG_HL,
        testWeightUnit,
      )
      setEditObj(row)
      toggleAddRow(false)
      setSelectedRow(row && row.id)
    },
    [rows, disableDeleteItem],
  )

  const addEmptyRow = useCallback(
    (e) => {
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      if (addButtonClicked) {
        e.preventDefault()
      } else {
        toggleAddRow(true)
        let id = uuidv4()
        setSelectedRow(id)
        const newCrop = getNewCrop(sortedCropConstants[0].id)
        setRows([
          {
            id: id,
            manufacturer: '',
            ...newCrop,
          },
          ...cropVarieties,
        ])
        setEditObj({ id })
      }
    },
    [cropVarieties, sortedCropConstants, disableDeleteItem, addButtonClicked],
  )

  const saveData = useCallback(
    async (row) => {
      row.testWeightKgHl = convertTestweight(
        row.testWeightKgHl,
        testWeightUnit,
        KG_HL,
      )
      if (addButtonClicked) {
        await dispatch(addCropVariety(row))
        setSelectedRow(-1)
      } else {
        await dispatch(editCropVariety(row.id, row))
        setSelectedRow(-1)
      }
      toggleAddRow(false)
      await dispatch(fetchCropsWithCropVariety())
    },
    [cropVarieties, sortedCropConstants, addButtonClicked],
  )

  const deleteVariety = useCallback(
    (id) => {
      if (cropVarieties?.length > 1) {
        dispatch(deleteCropVariety(id))
        toggleAddRow(false)
      } else {
        setDisableDeleteItem(true)
      }
    },
    [cropVarieties, sortedCropConstants, addButtonClicked, disableDeleteItem],
  )

  const handleInputChange = useCallback((e, row) => {
    const { name, value } = e.target
    const { id } = row
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (name === 'cropId') {
          let valuesToChange = getNewCrop(value)
          //remove name so that we don't change it
          delete valuesToChange.name
          return { ...row, ...valuesToChange }
        } else if (name === 'marketMoisture') {
          return { ...row, [name]: value }
        } else {
          return { ...row, [name]: value }
        }
      }
      return row
    })
    validate({ [name]: value })
    setRows(newRows)
  })

  const handleOnBlur = useCallback((e, row) => {
    const { name, value } = e.target
    const { id } = row
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (name === 'cropId') {
          let valuesToChange = getNewCrop(value)
          return { ...row, ...valuesToChange }
        } else {
          if (name === 'testWeightKgHl') {
            return {
              ...row,
              [name]: value,
            }
          }
          return { ...row, [name]: value }
        }
      }
      return row
    })
    validate({ [name]: value })
    setRows(newRows)
  })

  function getNewCrop(id) {
    const obj = cropConstants.filter((item) => item.id === parseInt(id))[0]
    let valuesToChange = {
      testWeightKgHl: convertTestweight(
        obj.defaultTestWeightKgHl > 0 ? obj.defaultTestWeightKgHl : 40,
        KG_HL,
        testWeightUnit,
      ),
      marketMoisture: obj.marketMoisture,
      cropId: obj.id,
      name: obj.name,
    }
    return valuesToChange
  }

  const clearData = useCallback((obj) => {
    editObj.testWeightKgHl = convertTestweight(
      editObj.testWeightKgHl,
      testWeightUnit,
      KG_HL,
    )
    const newRows = rows.map((row) => {
      if (row.id === obj.id) {
        return { ...row, ...editObj }
      }
      return row
    })
    setRows(newRows)
    if (!addButtonClicked) {
      setSelectedRow(-1)
    } else {
      toggleAddRow(false)
      setRows(rows.filter((item) => item.id !== obj.id))
    }
  })

  const disableButton = useCallback(
    (data) => {
      const testWeight =
        !isNaN(data.testWeightKgHl) && parseFloat(data.testWeightKgHl) <= 0.0
      if (
        data.testWeightKgHl === '' ||
        data.marketMoisture === '' ||
        data.name === '' ||
        testWeight
      ) {
        return true
      }
      return false
    },
    [rows, cropVarieties],
  )

  const validate = (data) => {
    let temp = { ...errors }
    if ('name' in data) {
      temp.name = data.name ? '' : t('required_field')
    }
    if ('testWeightKgHl' in data) {
      temp.testWeightKgHl = data.testWeightKgHl > 0 ? '' : t('required_field')
    }
    if ('marketMoisture' in data) {
      temp.marketMoisture = data.marketMoisture > 0 ? '' : t('required_field')
    }
    setErrors({
      ...temp,
    })
  }

  const getCropCategoryName = useCallback((cropId) => {
    let crop = cropConstants.filter((item) => item.id === cropId)[0]
    return crop && crop.name ? getTranslatedName(crop.name) : ''
  })

  return (
    <>
      {/* <Box width={1} > */}
      <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
        {disableDeleteItem ? (
          <Alert severity="error">
            {t('delete_item_disable_message', { item: t('crop_variety') })}
          </Alert>
        ) : null}
        <Box my={1} className={classes.borderedBox}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('name')}</TableCell>
                  <TableCell align="left">{t('manufacturer')}</TableCell>
                  <TableCell align="left">{t('crop_category')}</TableCell>
                  <TableCell align="left">
                    {`${t('test_weight')}`}{' '}
                    <span className={classes.lowerCase}>{`(${t(
                      testWeightUnit,
                    )})`}</span>
                  </TableCell>
                  <TableCell align="left">{t('market_moisture')}</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length
                  ? rows &&
                    rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <TextField
                              type="text"
                              name="name"
                              value={row.name ? row.name : ''}
                              size="small"
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                              {...(row.id == rowSelected && {
                                ...(errors['name'] && {
                                  error: true,
                                  helperText: errors['name'],
                                }),
                              })}
                            />
                          ) : (
                            getTranslatedName(row.name)
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <TextField
                              value={row.manufacturer || ''}
                              size="small"
                              name="manufacturer"
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                            />
                          ) : row.manufacturer === '' || !row.manufacturer ? (
                            '-'
                          ) : (
                            row.manufacturer
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <TextField
                              id="variety"
                              select
                              name="cropId"
                              SelectProps={{ native: true }}
                              variant="outlined"
                              size="small"
                              placeholder={t('select_crop_variety')}
                              value={row.cropId}
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                            >
                              {sortedCropConstants &&
                              Object.keys(sortedCropConstants) ? (
                                sortedCropConstants.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {getTranslatedName(item.name)}
                                    </option>
                                  )
                                })
                              ) : (
                                <></>
                              )}
                            </TextField>
                          ) : (
                            getCropCategoryName(row.cropId)
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <ShortTextField
                              type="number"
                              value={
                                row.testWeightKgHl > 0
                                  ? row.testWeightKgHl
                                  : 40.0
                              }
                              inputProps={{ min: 0.01 }}
                              size="small"
                              name="testWeightKgHl"
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                              onBlur={(e) => handleOnBlur(e, row)}
                              {...(row.id == rowSelected && {
                                ...(errors['testWeightKgHl'] && {
                                  error: true,
                                  helperText: errors['testWeightKgHl'],
                                }),
                              })}
                            />
                          ) : convertTestweight(
                              row.testWeightKgHl,
                              KG_HL,
                              testWeightUnit,
                            ).toFixed(2) > 0 ? (
                            convertTestweight(
                              row.testWeightKgHl,
                              KG_HL,
                              testWeightUnit,
                            ).toFixed(2)
                          ) : (
                            40
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <ShortTextField
                              type="number"
                              value={row.marketMoisture || ''}
                              inputProps={{ min: 0.0 }}
                              name="marketMoisture"
                              size="small"
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                              {...(row.id == rowSelected && {
                                ...(errors['marketMoisture'] && {
                                  error: true,
                                  helperText: errors['marketMoisture'],
                                }),
                              })}
                            />
                          ) : (
                            row.marketMoisture || ''
                          )}
                        </TableCell>

                        {canEdit && (
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.borderBottomCustom}
                          >
                            {!rowSelected || row.id != rowSelected ? (
                              <Box display="flex">
                                <IconButton
                                  color="primary"
                                  aria-label={t('edit_crop_variety')}
                                  className={classes.button}
                                  onClick={() => enableSelectedRow(row)}
                                  size="large"
                                >
                                  <ModeEditOutlineOutlinedIcon
                                    fontSize="small"
                                    style={{ color: colors.brandLight }}
                                  />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  aria-label={t('delete_crop_variety')}
                                  className={classes.button}
                                  onClick={() => deleteVariety(row.id)}
                                  size="large"
                                >
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="small"
                                    style={{ color: '#B2023B' }}
                                  />
                                </IconButton>
                              </Box>
                            ) : (
                              <div hidden={row.id != rowSelected}>
                                <IconButton
                                  color="primary"
                                  aria-label={t('save')}
                                  className={classes.button}
                                  disabled={disableButton(row)}
                                  onClick={() => saveData(row)}
                                  size="large"
                                >
                                  <SaveOutlinedIcon
                                    fontSize="small"
                                    style={{ color: colors.brandLight }}
                                  />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  aria-label={t('cancel')}
                                  className={classes.button}
                                  onClick={() => clearData(row)}
                                  size="large"
                                >
                                  <ClearIcon
                                    fontSize="small"
                                    style={{ color: '#B2023B' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {canEdit && (
          <Box display="flex">
            <Button
              variant="outlined"
              aria-label={`${t('add')} ${t('crop_variety')} `}
              onClick={(e) => addEmptyRow(e)}
              className={classes.styledButton}
              endIcon={<AddIcon />}
            >
              {`${t('add')} ${t('crop_variety')} `}
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}
