import 'olgm/olgm.css'
import GoogleLayer from 'olgm/layer/Google'
import OLGoogleMaps from 'olgm/OLGoogleMaps'
import useSplitMap from 'hooks/use-split-map'

export default function GoogleMaps() {
  const { map_a, map_b } = useSplitMap()

  const [layer_a, setLayer_a] = useState()

  const [layer_b, setLayer_b] = useState()

  useEffect(() => {
    setLayer_a(new GoogleLayer({ mapTypeId: google.maps.MapTypeId.SATELLITE }))
    setLayer_b(new GoogleLayer({ mapTypeId: google.maps.MapTypeId.SATELLITE }))
  }, [])

  useEffect(() => {
    let gmap_a
    if (map_a && layer_a) {
      map_a.addLayer(layer_a)
      gmap_a = new OLGoogleMaps({ map: map_a })
      gmap_a.activate()
    }

    let gmap_b
    if (map_b && layer_b) {
      map_b.addLayer(layer_b)
      gmap_b = new OLGoogleMaps({ map: map_b })
      gmap_b.activate()
    }

    return () => {
      if (gmap_a) {
        gmap_a.deactivate()
      }
      map_a.removeLayer(layer_a)

      if (gmap_b) {
        gmap_b.deactivate()
      }
      map_b.removeLayer(layer_b)
    }
  }, [map_a, map_b, layer_a, layer_b])

  return null
}
