import useMapCoordinate from 'hooks/use-click-coordinate'
import useClickPixel from 'hooks/use-click-pixel'
import useFeaturesAtPixel from 'hooks/use-feature-at-pixel'
import { showPopup } from 'actions/app'
import NoteEditor from 'components/note-editor'
import * as c from 'common/c'

export default function AddNote() {
  const dispatch = useDispatch()

  const coordinate = useMapCoordinate()

  const pixel = useClickPixel()

  const features = useFeaturesAtPixel()

  const [activePixel, setActivePixel] = useState()

  const feature = useMemo(() => {
    for (let i = 0; i !== features.length; i++) {
      const _feature = features[i]
      if (_feature.get(c.OL_PROP_TYPE) === c.FTYPE_NOTE) {
        return _feature
      }
    }
  }, [features])

  useEffect(() => {
    if (coordinate[0] !== 0 && coordinate[1] !== 0) {
      if (activePixel) {
        if (activePixel[0] !== pixel[0] || activePixel[1] !== pixel[1]) {
          if (pixel[0] !== 0 && pixel[1] !== 0) setActivePixel(pixel)
        }
      } else {
        if (pixel[0] !== 0 && pixel[1] !== 0) setActivePixel(pixel)
      }
    }
  }, [pixel, coordinate])

  useEffect(() => {
    if (activePixel && activePixel[0] !== 0 && activePixel[1] !== 0) {
      const modalProps = {
        title: 'Add Note',
        pixel: activePixel,
      }
      dispatch(
        showPopup(
          <NoteEditor coords={coordinate} feature={feature} />,
          modalProps,
        ),
      )
    }
  }, [activePixel, coordinate, feature])

  return null
}
