import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { getDeviceString } from 'common/misc'

const useStyles = makeStyles(() => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  borderBottomCustom: { borderBottom: 'none' },
  customFontSize: { fontSize: '1rem' },
}))

export default function HardwareComponent() {
  const classes = useStyles()
  const { t } = useTranslation()

  let hardware = useSelector((state) => state.hardware.collection)

  return (
    <>
      {/* <Box width={1} > */}
      <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
        <Box my={1} className={classes.borderedBox}>
          <TableContainer>
            <Table aria-label="FarmTRX Hardware table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('hardware_id')}</TableCell>
                  <TableCell align="left">{t('hardware_type')} </TableCell>
                  <TableCell align="left">{t('hardware_name')}</TableCell>
                  <TableCell align="left">{t('hardware_version')} </TableCell>
                  <TableCell align="left">{t('firmware_version')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hardware && hardware.length ? (
                  hardware &&
                  hardware.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {row.farmtrxHardwareId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {t(row.hardwareType.toLowerCase())}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {getDeviceString(row.deviceType)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {row.hardwareVersion}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {Array.isArray(row?.metadata?.firmware_version) &&
                        row?.metadata?.firmware_version.length > 0
                          ? row.metadata.firmware_version[
                              row?.metadata?.firmware_version.length - 1
                            ].version
                          : t('unknown')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Box width={1} justifyContent="center">
                        {t('hw_populate_cloud_upload_msg')}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
