import { ReactElement } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
export default function V(props: {
  children: (React.Component | ReactElement | JSX.Element | null)[]
}) {
  const { children } = props

  return <StyledContainer {...props}>{children}</StyledContainer>
}

V.displayName = 'VerticalLayout'

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export type Item = {
  propTypes: {
    space: boolean
    displayName: string
  }
}

V.Item = function (props: {
  children: React.Component | ReactElement | null
  space: boolean
}) {
  const { children } = props
  return <StyledItem {...props}>{children}</StyledItem>
}
//@ts-ignore
V.Item.propTypes = {
  space: PropTypes.bool,
}

//@ts-ignore
V.Item.defaultProps = {
  space: false,
}

//@ts-ignore
V.Item.displayName = 'VerticalLayout.Item'

const StyledItem = styled.div`
  & + & {
    margin-top: ${(props: { space: boolean }) => (props.space ? 0 : '8px')};
  }
`
