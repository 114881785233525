import { useRef, useState, useCallback, useEffect, ReactElement } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noop } from 'lodash-es'
import { isNotBlank } from 'common/misc'
import useOnClickOutside from 'hooks/click-outside'
import { defaultShadow, BlankLink } from 'components/foundation'
import colors from 'common/colors'
//Import types defined in definition file.
import type { PopupInternalProps, PixelCoords } from './popup-internal.d'

export default function PopupInternal(
  props: PopupInternalProps,
): ReactElement<any, any> | null {
  const { onClose, children, title, pixel } = props

  const ref = useRef<HTMLDivElement>(null)
  const [withTitle, setWithTitle] = useState(false)

  useOnClickOutside(ref, () => {
    onClose()
  })

  const onCloseClicked = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    setWithTitle(isNotBlank(title))
  }, [title])

  return (
    <StyledWrapper ref={ref} pixel={pixel} withTitle={withTitle}>
      {withTitle ? <StyledHeader key="title">{title}</StyledHeader> : null}
      <StyledClose key="close-link" onClick={onCloseClicked}>
        <FontAwesomeIcon icon="times" color={colors.grey} />
      </StyledClose>
      {children}
      <Arrow pixel={pixel} />
    </StyledWrapper>
  )
}

PopupInternal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  pixel: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

PopupInternal.defaultProps = {
  onClose: noop,
  pixel: {
    x: 0,
    y: 0,
  },
}

const StyledHeader = styled.h1<StyledHeaderProps>`
  font-size: large;
  margin-top: 9px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
`

interface StyledWrapperProps {
  pixel: PixelCoords
  withTitle?: boolean
  children: any
  ref:any
}

interface StyledHeaderProps {
  key: string
  children?: string
}

interface StyledHeaderProps {
  key: string
  children?: string
}


const StyledWrapper = styled.div<StyledWrapperProps>`
  background-color: white;
  padding: 8px;
  position: fixed;
  top: ${({ pixel }) => pixel.y - 20 + 'px'};
  left: ${({ pixel }) => pixel.x - 5 + 'px'};
  width: 238px;
  min-height: 100px;
  padding-top: 40px;
  z-index: 1000;
  overflow-y: auto;
  ${defaultShadow}
  border-radius: 4px;
  padding-top: ${(props) => (props.withTitle ? '0px' : '40px')};
`

StyledWrapper.propTypes = { withTitle: PropTypes.bool }

StyledWrapper.defaultProps = { withTitle: false }

const StyledClose = styled(BlankLink)`
  position: absolute;
  right: 12px;
  top: 10px;
`
interface ArrowProps {
  pixel: PixelCoords
}
const Arrow = styled.div<ArrowProps>`
  position: fixed;
  top: ${(props) => props.pixel.y + 38 + 'px'};
  left: ${({ pixel }) => pixel.x + 229 + 'px'};
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid white;
  height: 0;
  width: 0;
`
