import styled from 'styled-components'
import { Popper, Typography, Button } from '@mui/material'
import Harvexp from './harvexp'
import { MIN_SCREEN_HEIGHT, FT_ADMIN_CLAIM } from 'common/c'
import { isNil, get, reduce, findIndex, isEmpty } from 'lodash-es'
import { getTranslatedName } from 'common/misc'
import LayersGroup from 'components/layers-group'
import LayerDownload from 'components/layer-download'
import HarvestSettingsPanel from './harvest-settings'
import { showModal } from 'actions/app'
import { getLayerInfoByHarvest, exportLayer } from 'api/field'
import { vectorLayers, rasterLayers } from 'common/layer-data'

//actions
import { harvestFetched } from 'actions/harvest'
import { setLayers } from 'actions/field'
import { hideLayerLegend, removeLayer, showLayer } from 'actions/map'

//hooks
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useCallback, useMemo, useEffect } from 'react'

//Icons
import { FarmtrxIconWheat2 } from '@troo/farmtrx-icons'

//Types
import { HarvestModel } from 'state/harvest'
import { AppState } from 'state'
import { LayerInfoModel } from 'state/field'

export type HarvestPanelProps = {
  anchorEl: null | HTMLElement
  onClickHandler: Function
  open: boolean
  showExportModal: Function
  onClose: Function
}

export type HarvestPermissions = {
  editCropCalibration: boolean
  editCropType: boolean
  editSensorCalibration: boolean
  editTimeDelay: boolean
  exportHarvest: boolean
  refreshHarvest: boolean
  setHeader: boolean
  updateTare: boolean
  updateTestWeight: boolean
  updateTotalYield: boolean
  downloadUbj: boolean
}

export default function HarvestPanel(props: HarvestPanelProps) {
  const { t } = useTranslation()
  const [isHarvestOpen, setIsHarvestOpen] = useState(true)
  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )
  const permissions = useSelector(
    (state: AppState) => state.account.permissions,
  )
  const dispatch = useDispatch()
  const harvest = useSelector((state: AppState) => state.harvest.current)
  const harvests: HarvestModel[] = useSelector(
    (state: AppState) => state.harvest.collection,
  )
  const layers = useSelector((state) => get(state, 'field.layers', {}))
  const layerInfo: LayerInfoModel[] = useSelector((state) =>
    get(state, 'field.layerInfo', []),
  )
  const [showPermissions, setShowPermissions] = useState<HarvestPermissions>({
    editCropCalibration: false,
    editCropType: false,
    editSensorCalibration: false,
    editTimeDelay: false,
    exportHarvest: false,
    refreshHarvest: false,
    setHeader: false,
    updateTare: false,
    updateTestWeight: false,
    updateTotalYield: false,
    downloadUbj: false,
  })
  const [layerInfoMap, setLayerInfoMap] = useState<{
    [key: string]: LayerInfoModel
  }>({})
  const [harvestLayers, setHarvestLayers] = useState({})
  const [isHarvestActionDrawerOpen, setIsHarvestActionDrawerOpen] =
    useState(false)
  const [harvestOptionsAnchor, setHarvestOptionsAnchor] = useState(null)

  const baseVectorLayers = useMemo(() => {
    const _baseVectorLayers = {}
    for (let i = 0; i < vectorLayers.length; i++) {
      _baseVectorLayers[vectorLayers[i].name] = vectorLayers[i]
    }
    return _baseVectorLayers
  }, [vectorLayers])

  const baseRasterLayers = useMemo(() => {
    const _baseRasterLayers = {}
    for (let i = 0; i < rasterLayers.length; i++) {
      _baseRasterLayers[rasterLayers[i].name] = rasterLayers[i]
    }
    return _baseRasterLayers
  }, [rasterLayers])

  useEffect(() => {
    if (harvests.length >= 1) {
      setIsHarvestOpen(true)
      dispatch(harvestFetched(harvests[0]))
    }
  }, [harvests])

  useEffect(() => {
    setShowPermissions({
      editCropCalibration: permissions.includes('harvest:admin'),
      editCropType: permissions.includes('farm:write'),
      editSensorCalibration: permissions.includes('harvest:admin'),
      editTimeDelay: permissions.includes('harvest:admin'),
      exportHarvest: permissions.includes('farm:read'),
      refreshHarvest: permissions.includes('harvest:admin'),
      setHeader: permissions.includes('harvest:write'),
      updateTare: permissions.includes('harvest:admin'),
      updateTestWeight: permissions.includes('harvest:write'),
      updateTotalYield: permissions.includes('harvest:write'),
      downloadUbj:
        permissions.includes('farmtrx:admin') || customClaims?.[FT_ADMIN_CLAIM],
    })
  }, [permissions])

  useEffect(() => {
    if (layers !== undefined) {
      let id = harvest?.id
      const _harvestLayers =
        id && layers[id] != null
          ? layers[id]
          : Object.assign({}, baseRasterLayers, baseVectorLayers)

      setHarvestLayers(_harvestLayers)
    }
  }, [layers, harvest])

  useEffect(() => {
    if (!isEmpty(layerInfo)) {
      const _layerInfoMap = {}
      layerInfo.map((l) => {
        _layerInfoMap[l.layerName] = l
      })
      setLayerInfoMap(_layerInfoMap)
    }
  }, [layerInfo])

  useEffect(() => {
    if (!isNil(harvest)) {
      dispatch(getLayerInfoByHarvest(harvest.id, harvest.cropYear))
    }
  }, [harvest])

  const onHarvSelected = useCallback(
    (h) => {
      if (h === harvest) {
        setIsHarvestOpen(!isHarvestOpen)
      } else {
        setIsHarvestOpen(true)
        dispatch(harvestFetched(h))

        const initialLayers = {}
        initialLayers[h.id] =
          layers[h.id] != null
            ? layers[h.id]
            : Object.assign({}, baseRasterLayers, baseVectorLayers)
        dispatch(setLayers(initialLayers))
      }

      setIsHarvestActionDrawerOpen(false)
    },
    [layers, harvest],
  )

  const onSettingClick = useCallback((element) => {
    if (element) {
      setIsHarvestActionDrawerOpen(true)
    } else {
      setIsHarvestActionDrawerOpen(false)
    }
    setHarvestOptionsAnchor(element)
  }, [])

  const showExportModal = useCallback(
    (h) => {
      dispatch(
        showModal(
          <LayerDownload
            data={{
              id: h.id,
              title: `${h.cropYear}-${getTranslatedName(h.crop.name)}`,
              type: 'harvest',
              tenant_id: h.tenantId,
              startDate: h.startDate,
              endDate: h.endDate,
            }}
            harvestLayers={harvestLayers}
          />,
        ),
      )
    },
    [harvestLayers, layerInfo],
  )
  return (
    <Popper
      style={{ width: '230px' }}
      anchorEl={props.anchorEl}
      open={props.open}
      placement={'left-start'}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [100, -240],
            tether: false,
            altBoundary: true,
          },
        },
      ]}
      id="harvest-panel"
    >
      {harvests.length !== 0 ? (
        <StyledHarvList>
          {harvests.map((h) => {
            return (
              <Harvexp
                key={h.id}
                id={h.id}
                title={`${h.cropYear}-${getTranslatedName(h.crop.name)}`}
                open={isHarvestOpen && h.id === harvest?.id}
                status={h.status}
                //subscriptionStatus={h?.subscriptionStatus}
                onClick={() => {
                  onHarvSelected(h)
                }}
                onSettingClick={onSettingClick}
                settingsOpen={isHarvestActionDrawerOpen}
              >
                <HarvestSettingsPanel
                  open={isHarvestActionDrawerOpen}
                  anchorEl={harvestOptionsAnchor}
                  permissions={showPermissions}
                  onClickHandler={props.onClickHandler}
                  harvest={h}
                  showExportModal={showExportModal}
                  onClose={onSettingClick}
                />
                <StyledHarvListItem key={h.id}>
                  <LayersGroup
                    harvestLayers={harvestLayers}
                    layerInfoMap={layerInfoMap}
                    hideLayerLegend={hideLayerLegend}
                    removeLayer={removeLayer}
                    showLayer={showLayer}
                    harvestId={harvest?.id}
                    setLayers={setLayers}
                  />
                </StyledHarvListItem>
              </Harvexp>
            )
          })}
        </StyledHarvList>
      ) : (
        <StyledHarvList>
          <StyledNoHarvest>
            <FarmtrxIconWheat2 /> {t('no_harvests')}
          </StyledNoHarvest>
        </StyledHarvList>
      )}
    </Popper>
  )
}

const StyledHarvList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  padding: 1px 0;
  background: #fff;
`

const StyledHarvestCount = styled.span`
  opacity: 0.28;
  margin-left: 0.25em;
`

const StyledList = styled.ul`
  margin: 18px 0 0 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 1px 11px;
  top: 70px;
  bottom: 8px;
  list-style-type: none;
`

const StyledHarvListItem = styled.li`
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  // border: 1px solid rgba(0,0,0,.125);
  padding: 10px 5px;

  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  & + & {
    border-top-width: 0;
  }

  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`

const StyledNoHarvest = styled.span`
  padding-left: 8px;
  font-size: 11px;
  font-weight: 500;
  color: #000000;
  opacity: 0.4;
  & svg {
    font-size: 16px;
    & path {
      stroke: #000000;
    }
  }
`
