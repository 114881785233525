import ModalContainer from '../../modal/modal-container'
import ModalActions from '../../modal/modal-actions'
import Button from '@mui/material/Button'
import { closeModal } from 'actions/app'
import { deleteFarm } from 'api/farm'
import { useTranslation } from 'react-i18next'
import type { AppState } from 'state'
import { useDispatch, useSelector } from 'react-redux'
import { get, find } from 'lodash-es'
import { useEffect, useState } from 'react'

export default function OCFFDeleteFarm(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedFarm } = props
  const { id, farmName } = selectedFarm

  const clients = useSelector((state) =>
    get(state as AppState, 'client.collection' || []),
  )

  const farms = useSelector((state) =>
    get(state as AppState, 'farm.collection' || []),
  )

  const defaultFarm = useSelector((state) =>
  (state as AppState).account.singleton.prefs?.cff?.farm,
)
  
  const [defaultDeletionMsg, showDefaultDeletionMsg] = useState(false)

  useEffect(() => {
      if (defaultFarm && parseInt(defaultFarm) === parseInt(id) || farms.length === 1) {
        showDefaultDeletionMsg(true)
      }
  }, [selectedFarm, defaultFarm, farms])

  const closePopup = () => dispatch(closeModal())

  const deleteItem = () => {
    const clientId = find(clients, ['name', selectedFarm['clientName']])?.id
    dispatch(deleteFarm(id, clientId))
  }

  return (
    <ModalContainer>
      {defaultDeletionMsg ? (
        <>
          <div>
            {t('cannot_delete_default', {
              entity: t('farm'),
            })}
          </div>
          <ModalActions>
            <Button variant="contained" color="primary" onClick={closePopup}>
              {t('ok')}
            </Button>
          </ModalActions>
        </>
      ) : selectedFarm['fieldsQuantity'] > 0 ? (
        <>
          <div>
            {t('delete_child_entity_first', {
              parentEntityName: farmName,
              childEntity: t('fields'),
              parentEntity: t('farm'),
            })}
          </div>
          <ModalActions>
            <Button variant="contained" color="primary" onClick={closePopup}>
              {t('ok')}
            </Button>
          </ModalActions>
        </>
      ) : (
        <>
          <div>{t('delete_item_warning', { name: farmName })}</div>
          <ModalActions>
            <Button variant="contained" color="inherit" onClick={closePopup}>
              {t('cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={deleteItem}>
              {t('delete')}
            </Button>
          </ModalActions>
        </>
      )}
    </ModalContainer>
  )
}
