import { Typography, Container, Card, CardContent } from '@mui/material'

export default function Support() {
  return (
    <Container fixed>
      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Documentation
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Video Tutorials
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}
