import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import { makeStyles } from '@mui/styles'
import { css } from 'styled-components'

const useStyle = makeStyles({
  test: css`
    border-color: #d4d4d4;
  `,
  input: {
    borderColor: '#D4D4D4',
    color: '#4A4A4A',
    opacity: 0.8,
    height: '100%',
    maxHeight: '48px',
    fontSize: '12px',
    '& svg': {
      fontSize: '1.5em',
    },
  },
})

export default function SearchField({
  onSearch,
  name,
  placeholder,
  className,
}) {
  const classes = useStyle()
  const { t } = useTranslation()

  const onChange = useCallback((e) => {
    onSearch(e.target.value)
  })

  // return (<input placeholder={t('search_by_name')} value={name} onChange={onChange} />)
  return (
    <Box className='is_visible'>
      <FormControl className={className}>
        <OutlinedInput
          className={classes.input}
          variant="outlined"
          size="small"
          value={name}
          onChange={onChange}
          id="input-with-icon-adornment"
          placeholder={placeholder ?? t('search_by_name')}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  )
}
