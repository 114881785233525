import { H } from 'components/layout'
import {
  Add as AddIcon,
  Home as HomeIcon,
  ArrowBackIosTwoTone as ArrowBackIcon,
  ArrowForwardIosTwoTone as ArrowForwardIcon,
} from '@mui/icons-material'
import { Trans } from 'react-i18next'
import { fetchBBox } from 'api/field'

import useMap from 'hooks/use-map'
import { transformExtent } from 'ol/proj'
import { makeStyles } from '@mui/styles'
import { useCallback } from 'react'

const useStyles = makeStyles({
  name: {
    fontSize: '19px',
    margin: '0',
    width: '150px',
  },
  fieldLabel: {
    fontSize: '16px',
    color: '#4A4A4A',
    margin: '0 0 10px 0',
    opacity: '0.8',
  },
  fieldIcon: {
    cursor: 'pointer',
  },
  button: {
    '&:disabled svg path': {
      color: '#ECE9F1',
    },
  },
  displayArrowIcon: {
    display: 'block',
  },
  hideArrowIcon: {
    display: 'none',
  },
})

export default function SideHeader(props) {
  const classes = useStyles()
  const { onCreateField } = props

  const map = useMap()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [sideHeaderSeen, setSideHeaderSeen] = useState(true)

  const defaultExtent = useSelector((state) => state.field['defaultExtent'])
  const recenter = useCallback(() => {
    if (defaultExtent.length > 0 && map) {
      map
        .getView()
        .fit(transformExtent(defaultExtent, 'EPSG:4326', 'EPSG:3857'))
    } else {
      dispatch(fetchBBox())
    }
  }, [defaultExtent])

  const hideElement = useCallback(() => {
    setSideHeaderSeen(!sideHeaderSeen)
  }, [sideHeaderSeen])

  useEffect(() => {
    if (sideHeaderSeen) {
      for (
        var x = 0;
        x < document.getElementsByClassName('is_visible').length;
        ++x
      ) {
        document.getElementsByClassName('is_visible')[x].style.visibility =
          'visible'
      }
      document.getElementById('sidePanel').style.width = '250px'
      document.getElementById('pageContent').style.width = 'calc(100% - 250px)'
      document.getElementById('pageContent').style.left = '250px'
      const harvestPanel = document.getElementById('harvest-panel')
      if (harvestPanel) {
        const width = window.innerWidth
        harvestPanel.style.transform = `translate3d(-${
          width - 495
        }px, 148px, 0px)`
      }
    } else {
      for (
        var y = 0;
        y < document.getElementsByClassName('is_visible').length;
        ++y
      ) {
        document.getElementsByClassName('is_visible')[y].style.visibility =
          'hidden'
      }
      document.getElementById('sidePanel').style.width = '50px'
      document.getElementById('pageContent').style.width = 'calc(100% - 50px)'
      document.getElementById('pageContent').style.left = '50px'
      const harvestPanel = document.getElementById('harvest-panel')
      if (harvestPanel) {
        const width = window.innerWidth
        harvestPanel.style.transform = `translate3d(-${
          width - 295
        }px, 148px, 0px)`
      }
    }
  }, [sideHeaderSeen])

  return (
    <>
      {sideHeaderSeen ? (
        <H>
          <H
            style={{
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <H.Item>
              <h2 className={classes.fieldLabel}>{t('fields')}</h2>
            </H.Item>
            <H>
              <H.Item>
                <AddIcon
                  className={classes.fieldIcon}
                  title={<Trans>{t('add_field')}</Trans>}
                  titleAccess={t('add_field')}
                  onClick={onCreateField}
                />
              </H.Item>
              <H.Item>
                <HomeIcon
                  className={classes.fieldIcon}
                  titleAccess={t('to_default_extent')}
                  onClick={() => {
                    recenter()
                  }}
                />
              </H.Item>
              <H.Item className={classes.displayArrowIcon}>
                <ArrowBackIcon
                  style={{ cursor: 'pointer' }}
                  title={<Trans>{t('collapse')}</Trans>}
                  titleAccess={t('collapse')}
                  visibility={'visible'}
                  onClick={hideElement}
                />
              </H.Item>
            </H>
          </H>
        </H>
      ) : (
        <H>
          <H.Item className={classes.displayArrowIcon}>
            <ArrowForwardIcon
              id={'invisible'}
              style={{ cursor: 'pointer', marginLeft: '3px' }}
              title={<Trans>{t('expand')}</Trans>}
              titleAccess={t('expand')}
              onClick={hideElement}
            />
          </H.Item>
        </H>
      )}
    </>
  )
}
