import { default as OLMap } from 'ol/Map'
import View from 'ol/View'
import { DragPan, MouseWheelZoom } from 'ol/interaction'
import { fromLonLat } from 'ol/proj'
import SplitMapContext from 'context/split-map-context'
import LazyRender from 'components/lazy-render'
import { isNil, get } from 'lodash-es'
import * as c from 'common/c'

const SplitMapProvider = (props) => {
  const { children } = props

  const [map_a, setMap_a] = useState()

  const [map_b, setMap_b] = useState()

  const [lock_a, setLockA] = useState(true)

  const [lock_b, setLockB] = useState(true)
  const fieldA = useSelector((state) => get(state, 'field.field_a'))
  const fieldB = useSelector((state) => get(state, 'field.field_b'))

  const onSwitchLockA = useCallback(() => {
    setLockA(!lock_a)
  }, [lock_a])

  const onSwitchLockB = useCallback(() => {
    setLockB(!lock_b)
  }, [lock_b])

  useEffect(() => {
    const viewA = new View({
      zoom: c.DEFAULT_ZOOM,
      center: fromLonLat(c.DEFAULT_CENTER),
      enableRotation: false,
      projection: 'EPSG:3857',
    })

    // default controls
    const controlsA = []

    // default interactions
    const interactionsA = []
    interactionsA.push(new DragPan())
    interactionsA.push(new MouseWheelZoom())

    // default layers
    const layersA = []

    const mapPropsA = {
      view: viewA,
      layers: layersA,
      controls: controlsA,
      overlays: [],
      interactions: interactionsA,
    }

    const mapInstanceA = new OLMap(mapPropsA)

    setMap_a(mapInstanceA)

    const viewB = new View({
      zoom: c.DEFAULT_ZOOM,
      center: fromLonLat(c.DEFAULT_CENTER),
      enableRotation: false,
      projection: 'EPSG:3857',
    })

    // default controls
    const controlsB = []

    // default interactions
    const interactionsB = []
    interactionsB.push(new DragPan())
    interactionsB.push(new MouseWheelZoom())

    // default layers
    const layersB = []

    const mapPropsB = {
      view: viewB,
      layers: layersB,
      controls: controlsB,
      overlays: [],
      interactions: interactionsB,
    }

    const mapInstanceB = new OLMap(mapPropsB)

    setMap_b(mapInstanceB)

    return function cleanup() {
      mapInstanceA.setTarget(undefined)
      mapInstanceB.setTarget(undefined)
    }
  }, [])

  const onCenterChangeA = useCallback(
    (e) => {
      if (lock_a && fieldA?.id === fieldB?.id) {
        map_b.getView().setCenter(e.target.get(e.key))
      }
    },
    [lock_a, map_b, fieldA, fieldB],
  )

  const onCenterChangeB = useCallback(
    (e) => {
      if (lock_b && fieldA?.id === fieldB?.id) {
        map_a.getView().setCenter(e.target.get(e.key))
      }
    },
    [lock_b, map_a, fieldA, fieldB],
  )

  useEffect(() => {
    if (map_a && map_b && fieldA?.id === fieldB?.id) {
      map_a.getView().on('change:center', onCenterChangeA)
      map_b.getView().on('change:center', onCenterChangeB)
    }

    return function () {
      if (map_a && map_b) {
        map_a.getView().un('change:center', onCenterChangeA)
        map_b.getView().un('change:center', onCenterChangeB)
      }
    }
  }, [map_a, map_b, lock_a, lock_b, fieldA, fieldB])

  const isDone = useMemo(() => {
    return !isNil(map_a) && !isNil(map_b)
  }, [map_a, map_b])

  return (
    <LazyRender
      done={isDone}
      render={() => {
        return (
          <SplitMapContext.Provider
            value={{
              map_a,
              map_b,
              lock_a,
              onSwitchLockA,
              lock_b,
              onSwitchLockB,
            }}
          >
            {children}
          </SplitMapContext.Provider>
        )
      }}
    />
  )
}

export default SplitMapProvider
