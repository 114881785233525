import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import ModalContainer from '../../modal/modal-container'
import ModalActions from '../../modal/modal-actions'
import ModalHeader from '../../modal/modal-header'
import Button from '@mui/material/Button'
import { closeModal } from 'actions/app'
import { createFarm, editFarm } from 'api/farm'
import { find } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash-es'
import type { AppState } from 'state'
import Alert from '@mui/material/Alert'

export default function OCFFAddFarmComponent(props) {
  const { isEditOrAdd, selectedFarm } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const clients = useSelector((state) =>
    get(state as AppState, 'client.collection' || []),
  )

  const [selectedClient, setClient] = useState(
    selectedFarm
      ? find(clients, ['name', selectedFarm?.clientName])?.id
      : clients[0].id,
  )
  const [farmName, setFarmName] = useState(selectedFarm?.name ?? '')

  const [clientChanged, setClientChanged] = useState(false)

  const [farmExists, setFarmExists] = useState(false)

  const firstUpdate = useRef(true)

  const closePopup = () => dispatch(closeModal())

  const onChangeClient = useCallback(
    (e) => {
      setClientChanged(true)
      setClient(parseInt(e.target.value))
    },
    [selectedClient],
  )

  const onSetFarmName = useCallback(
    (e) => {
      if (farmExists) {
        setFarmExists(false)
      }
      setFarmName(e.target.value)
    },
    [selectedClient, farmName],
  )

  useEffect(() => {
    checkIfFarmExists()
  }, [selectedClient, farmName])

  const checkIfFarmExists = () => {
    //To fix the error, seeing the item exists message on edit item
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const clientObj = find(clients, ['id', selectedClient])
    if (clientObj && clientObj.farms?.length) {
      const farm = find(clientObj.farms, ['name', farmName])
      farm ? setFarmExists(true) : setFarmExists(false)
    } else {
      setFarmExists(false)
    }
  }

  const saveFarm = useCallback(() => {
    checkIfFarmExists()
    //Update the localStorage object
    const defaultClient = localStorage.getItem('selectedClient')
    //end
    if (isEditOrAdd === 'add') {
      let data = {
        clientId: selectedClient,
        name: farmName,
      }
      //@ts-ignore
      dispatch(createFarm(data)).then((res) => {
        if (defaultClient) {
          const client = JSON.parse(defaultClient)
          client['farms'].push({ id: res.id, name: res.name })
          localStorage.setItem('selectedClient', JSON.stringify(client))
        }
      })
    } else {
      const oldClientId = find(clients, ['name', selectedFarm?.clientName])?.id
      let data = {
        id: selectedFarm['id'],
        clientId: selectedClient,
        name: farmName,
        clientChanged,
        oldClientId,
      }
      dispatch(editFarm(data))
      if (defaultClient) {
        const client = JSON.parse(defaultClient)
        const index = client.farms.findIndex(
          (obj) => obj.id === selectedFarm['id'],
        )
        client.farms[index] = { id: selectedFarm['id'], farmName }
        localStorage.setItem('selectedClient', JSON.stringify(client))
      }
    }
  }, [selectedClient, farmName])

  return (
    <ModalContainer>
      <ModalHeader>
        {isEditOrAdd === 'add' ? t('add_farm') : t('edit_farm')}
      </ModalHeader>
      <StyledForm>
        <FormControlLabel
          control={
            <TextField
              id="client-name"
              name="client"
              variant="outlined"
              size="small"
              placeholder={t('client')}
              sx={{ marginLeft: '10px' }}
              value={selectedClient}
              onChange={onChangeClient}
              select
              SelectProps={{ native: true }}
            >
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </TextField>
          }
          label={t('client')}
          labelPlacement="start"
        />
        {farmExists ? (
          <Alert severity="warning">
            {t('item_name_exists_for_selection', {
              item: t('farm'),
              selectedItem: t('client'),
            })}
          </Alert>
        ) : null}
        <FormControlLabel
          control={
            <TextField
              id="farm-name"
              name="farmName"
              variant="outlined"
              size="small"
              placeholder={t('farm_name')}
              sx={{ marginLeft: '10px' }}
              onChange={onSetFarmName}
              value={farmName}
              inputProps={{ maxLength: 40 }}
              helperText={
                farmName.length >= 40
                  ? t('input_character_limit', {
                      character_count: 40,
                      field_name: t('farm_name'),
                    })
                  : ''
              }
              error={farmName.length >= 40}
            />
          }
          label={t('farm_name')}
          labelPlacement="start"
        />
      </StyledForm>
      <ModalActions>
        <Button variant="contained" color="inherit" onClick={closePopup}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={saveFarm}
          disabled={firstUpdate.current || !farmName.length || farmExists}
        >
          {isEditOrAdd === 'add' ? t('add') : t('save')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
`
