import * as c from 'common/c'

export const dirty = (form, dirty) => ({
  type: c.FORM_DIRTY,
  payload: {
    form,
    dirty,
  },
})

export const destroy = (id) => ({
  type: c.FORM_DESTROY,
  payload: id,
})
