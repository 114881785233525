import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { get } from 'lodash-es'
import Header from 'containers/header'
import { ContentWrapper } from 'components/page'
import { GlobalStyle } from 'common/init'
import { closeModal, closePopup } from 'actions/app'
import Modal from 'components/modal'
import Popup from 'components/popup'
import 'ol/ol.css'
import MapPanel from 'containers/map-panel'

import FarmSettings from 'components/farm-settings'
import { AdminComponent } from 'components/admin'
import LandsComponent from 'components/lands'

import Support from 'components/support'
import SplitMapPanel from 'containers/split-map-panel'
import { useNavigate } from 'react-router-dom'
import Reports from 'components/reports'

export default function AppInternal() {
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.account.permissions)
  const [showPermissions, setShowPermissions] = useState({})

  useEffect(() => {
    setShowPermissions({
      admin:
        permissions.includes('users:write') ||
        permissions.includes('tenant:list'),
      reports: permissions.includes('reports:read'),
    })
  }, [permissions])

  // Modal window
  const modal = useSelector((state) => get(state, 'app.modal'))
  const modalProps = get(modal, 'props', {})
  const modalComponent = get(modal, 'component')

  // Popup window
  const popup = useSelector((state) => get(state, 'app.popup'))
  const popupComponent = get(popup, 'component')
  const popupProperties = get(popup, 'props')

  const view = useSelector((state) => get(state, 'app.view'))

  const onModalClose = useCallback(() => {
    dispatch(closeModal())
  }, [modal])

  const onPopupClose = useCallback(() => {
    dispatch(closePopup())
  }, [popup])

  function Redirect({ to }) {
    let navigate = useNavigate()
    useEffect(() => {
      navigate(to)
    })
    return null
  }

  return (
    <Router>
      <GlobalStyle />
      <Header />
      <ContentWrapper id="content-area">
        <Routes>
          {showPermissions.reports && (
            <Route path="/reports" element={<Reports />} />
          )}
          {showPermissions.admin && (
            <Route path="/admin/*" element={<AdminComponent />} />
          )}
          <Route path="/support" element={<Support />} />
          <Route path="/farmSetup/*" element={<FarmSettings />} />
          <Route path="/fields/*" element={<LandsComponent />} />

          {localStorage.getItem('wizardActiveStep') == 'null' ? (
            <Route path="/wizardStepper" element={<Redirect to="/" />} />
          ) : null}
          <Route
            path="/"
            element={view === 'split' ? <SplitMapPanel /> : <MapPanel />}
          ></Route>
          {/* <Toaster /> */}
        </Routes>
      </ContentWrapper>
      {modal ? (
        <Modal
          component={modalComponent}
          onClose={onModalClose}
          title={modalProps.title}
        />
      ) : null}
      {popup ? (
        <Popup
          component={popupComponent}
          onClose={onPopupClose}
          title={popupProperties.title}
          pixel={{
            x: popupProperties.pixel[0],
            y: popupProperties.pixel[1],
          }}
        />
      ) : null}
    </Router>
  )
}
