import http from 'common/http'
import {
  clientCreated,
  clientsFetched,
  clientEdited,
  clientDeleted,
  farmReferencesUpdated,
} from 'actions/client'
import { farmsUpdated } from 'actions/farm'
import { closeModal } from 'actions/app'
import { getClientFarm } from 'common/misc'
import { assignSelectedClient } from 'actions/client'
import { assignSelectedFarms } from 'actions/farm'
import { get, isEmpty } from 'lodash-es'
import store from 'store'

export const createClient = (data) => {
  return (dispatch) => {
    return http.post('/client', data).then((res) => {
      dispatch(clientCreated(res.data))
      dispatch(closeModal())
      return {
        data: res.data,
        message: 'Client created',
      }
    })
  }
}

export const getClients = () => {
  return (dispatch) => {
    return http.get('/client').then((res) => {
      dispatch(clientsFetched(res.data))
      const account = get(store.getState(), 'account.singleton', {})
      if (!isEmpty(account)) {
        const cffPrefDefault = account?.prefs?.cff
        //@ts-ignore
        const { client, selectedFarms } = getClientFarm(
          cffPrefDefault?.enabled,
          res.data,
          cffPrefDefault?.client,
        )
        dispatch(assignSelectedClient(client))

        dispatch(
          assignSelectedFarms(selectedFarms?.length ? selectedFarms : []),
        )
      }
    })
  }
}

export const editClient = (data) => {
  return (dispatch) => {
    let clientId = data.id
    return http.put(`/client/${clientId}`, data).then((res) => {
      dispatch(clientEdited(res.data))
      dispatch(closeModal())
    })
  }
}

export const deleteClient = (id) => {
  return (dispatch) => {
    return http.delete(`/client/${id}`).then(() => {
      dispatch(clientDeleted(id))
      dispatch(closeModal())
    })
  }
}

export const assignFarms = (data) => {
  const { selectedClient, selectedIds } = data
  return (dispatch) => {
    return http
      .post(`/client/${selectedClient}/farms`, selectedIds)
      .then((res) => {
        dispatch(closeModal())
        dispatch(farmsUpdated(res.data))
        //For updating the client to show the updated farms
        dispatch(farmReferencesUpdated(data))
      })
  }
}
