import useMap from 'hooks/use-map'
import { transformExtent } from 'ol/proj'

const DEFAULT_PADDING = [65, 60, 110, 15]

export default function FocusField(props) {
  const { boundary, active = true, defaultExtent } = props

  const map = useMap()

  useEffect(() => {
    if (active) {
      if (defaultExtent) {
        map
          .getView()
          .fit(transformExtent(defaultExtent, 'EPSG:4326', 'EPSG:3857'))
      }
    }
  }, [map, defaultExtent, active])

  useEffect(() => {
    if (active) {
      if (boundary) {
        try {
          map.getView().fit(boundary.getGeometry().getExtent(), {
            padding: DEFAULT_PADDING,
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [map, boundary, active])

  return null
}
