import { RefObject, useEffect } from 'react'
export default function useOnClickOutside(
  ref: RefObject<any>,
  handler: Function,
  ignore?: string,
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (ignore && (event.target as Element).className.includes(ignore)) {
        return
      }

      // Do nothing if within the element or its descendants
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return function cleanup(): void {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, ignore])
}
