import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cerealImg from 'images/cereals.png'
import pulsesImg from 'images/pulses.png'
import oilseedsImg from 'images/oilseeds.png'
import CustomRadioButton from 'components/form/radio-button-custom-images'
import { filter, sortBy } from 'lodash-es'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useEffect } from 'react'
import { getTranslatedName } from 'common/misc'

const useStyles = makeStyles(() => ({
  labelCustomStyle: {
    alignItems: 'flex-start',
    width: '100%',
  },
  styledButtonGroup: {
    width: '65vw',
    display: 'block',
  },
  customRadioButton: {
    width: '100%',
    display: 'block',
  },
}))
export default function CropVarieties(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [cropClassification, setCropClassification] = useState('CEREAL')
  const [classifiedCrops, setClassifiedCrops] = useState([]) //top list
  const [selectedCrops, setSelectedCrops] = useState([]) //bottom list

  const crops = useSelector((state) => state.crop.cropConstantsCollection)
  const cropVarieties = useSelector((state) => state.cropVariety['collection'])

  const options = [
    {
      buttonImage: cerealImg,
      label: t('cereal'),
      value: 'CEREAL',
      name: 'preferredCropVariety',
      inputAppearance: 'none',
      defaultSelection: 'CEREAL',
    },
    {
      buttonImage: pulsesImg,
      label: t('pulse'),
      value: 'PULSE',
      name: 'preferredCropVariety',
      inputAppearance: 'none',
    },
    {
      buttonImage: oilseedsImg,
      label: t('oil_seed'),
      value: 'OILSEED',
      name: 'preferredCropVariety',
      inputAppearance: 'none',
    },
  ]

  const setSelectedOption = (option) => {
    setCropClassification(option)
  }

  const addCropSelections = useCallback((e) => {
    let cropId = e.target.id

    //Add the selected crop to the below list - selectedCrops
    let selectedCrop = filter(classifiedCrops, function (o) {
      return o.id == cropId
    })

    const { id } = selectedCrop[0]
    const selectedValue = { ...selectedCrop[0], cropId: id, id: uuidv4() }

    const finalArray = [...selectedCrops, selectedValue]

    setSelectedCrops(sortBy(finalArray, ['name']))
  })

  const removeSelection = useCallback((e, crop) => {
    let cropId = crop.id

    //Remove the selected crop from selectedCrops
    let arr = filter(selectedCrops, function (o) {
      return o.id != cropId
    })

    setSelectedCrops(arr)
  })

  useEffect(() => {
    const selectedCropIds = selectedCrops.map((c) => c.cropId)

    setClassifiedCrops(
      sortBy(
        crops.filter(
          (c) =>
            c.cropClassification === cropClassification &&
            !selectedCropIds.includes(c.id),
        ),
        ['name'],
      ),
    )
  }, [cropClassification, selectedCrops])

  useEffect(() => {
    if (selectedCrops && selectedCrops.length) {
      props.enableFinish(true)
      props.selectedCrops(selectedCrops)
    } else {
      props.enableFinish(false)
    }
  })

  useEffect(() => {
    if (Array.isArray(cropVarieties) && cropVarieties.length > 0) {
      setSelectedCrops([...cropVarieties])
    }
  }, [])

  return (
    <>
      <Box
        mx={5}
        my={5}
        sx={{
          height: '80vh',
          margin: 'auto',
          overflowY: 'auto',
          padding: '2rem',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          {t('select_crop_varieties_header')}
        </h2>
        <h6 style={{ color: '#4A4A4A', textAlign: 'center' }}>
          {t('add_more_later')}
        </h6>
        <Box my={1}>
          <CustomRadioButton
            options={options}
            setSelectedOption={setSelectedOption}
            className={classes.customRadioButton}
          />
        </Box>
        <Box my={4} display="flex">
          {/* <ArrowBackIosIcon sx={{ display: classifiedCrops.length ? 'block' : 'none', height: '45px' }} onClick={moveLeft} /> */}
          <StyledButtonGroup
            aria-label="outlined primary button group"
            className={classes.styledButtonGroup}
          >
            {classifiedCrops.length
              ? classifiedCrops &&
                classifiedCrops.map((crop) => (
                  <Button
                    key={crop.id}
                    id={crop.id}
                    sx={{
                      marginRight: '10px',
                    }}
                    onClick={(e) => addCropSelections(e)}
                  >
                    {getTranslatedName(crop.name)}
                  </Button>
                ))
              : null}
          </StyledButtonGroup>
          {/* <ArrowForwardIosIcon sx={{ display: classifiedCrops.length ? 'block' : 'none', height: '45px' }} onClick={moveRight} /> */}
        </Box>

        <Box my={8} display="flex">
          {/* <ArrowBackIosIcon sx={{ display: selectedCrops.length ? 'block' : 'none', height: '45px' }} onClick={moveLeft} /> */}
          <StyledButtonGroup
            aria-label="outlined primary button group"
            className={classes.styledButtonGroup}
          >
            {selectedCrops.length
              ? selectedCrops &&
                selectedCrops.map((crop) => (
                  <Button
                    key={crop.id}
                    id={crop.id}
                    variant="contained"
                    sx={{
                      marginRight: '10px',
                    }}
                    endIcon={<CloseIcon />}
                    onClick={(e) => removeSelection(e, crop)}
                  >
                    {getTranslatedName(crop.name)}
                  </Button>
                ))
              : null}
          </StyledButtonGroup>
          {/* <ArrowForwardIosIcon sx={{ display: selectedCrops.length ? 'block' : 'none', height: '45px' }} onClick={moveRight} /> */}
        </Box>
      </Box>
    </>
  )
}

const StyledButtonGroup = styled(ButtonGroup)({
  padding: '1px',
  // change the button group dividers color
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    //borderColor: "red"
    borderColor: '#003057',
    borderRadius: '25px',
    minWidth: 'fit-content',
    height: '45px',
    marginTop: '10px',
    // display: 'flex'
  },
  '& .MuiButtonGroup-grouped:last-of-type': {
    borderRadius: '25px',
    borderColor: '#003057',
    minWidth: 'fit-content',
    height: '45px',
    marginTop: '10px',
  },
})
