import { Alert, Box } from '@mui/material'
import { closeModal } from 'actions/app'
import Checkbox from '@mui/material/Checkbox'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { getYMIDHeaderHarvestSummary, updateHarvestHeader } from 'api/harvest'
import { uniqWith, isEqual, filter, uniq, cloneDeep, find } from 'lodash-es'
import { convertToFeetInches } from 'common/unitConvert'
import ModalTool from 'components/modal/modal-tool'

const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  button: { margin: theme.spacing(1) },
  borderBottomCustom: { borderBottom: 'none' },
  customFontSize: { fontSize: '1rem' },
  requiredField: { color: 'red' },
}))

export default function SetHeader() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const combines = useSelector((state) => state.equipment.collection)
  const headers = useSelector((state) => state.headers.collection)
  const harvest = useSelector((state) => state.harvest.current)
  const fieldFeatures = useSelector((state) => state.field.features[0].geometry)
  const hardware = useSelector((state) => state.hardware.collection)
  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const prefsUnit =
    userPrefs?.units?.length_in === 'meters' ||
    userPrefs?.units?.length_in === undefined
      ? 'm'
      : 'ftIn'

  const ymIDHeaders = useSelector(
    (state) => state.harvest.current.ymIDHeaders || [],
  )

  const [rows, setRows] = useState([])
  const [header, setHeader] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [showNoHeaderHarvestData, setShowNoHeaderHarvestData] = useState(false)
  const [headerUpdateFail, setHeaderUpdateFail] = useState(false)
  const [selected, setSelected] = useState([])
  const [filteredYMIDs, setFilteredYMIDs] = useState([])

  useEffect(() => {
    dispatch(
      getYMIDHeaderHarvestSummary(harvest.id, {
        range: {
          startDate: harvest.startDate,
          endDate: harvest.endDate || null,
        },
        fieldBoundary: fieldFeatures.coordinates,
      }),
    )
  }, [])

  const toFeetInches = useCallback((val) => {
    if (val != '') {
      const { feet, inches } = convertToFeetInches(val)
      return {
        widthin: inches,
        widthft: feet,
      }
    } else {
      return {
        widthin: '',
        widthft: '',
      }
    }
  }, [])

  useEffect(() => {
    let yms = []
    let filteredYMs = filter(hardware, ['hardwareType', 'YieldMonitor'])
    let defaultValues = []
    /*variable ymIDHeaders => the '/header-summary' response from the OpenSearch */
    if ((ymIDHeaders && ymIDHeaders.length) > 0) {
      ymIDHeaders.map((ymIDHeader) => {
        let obj = {}
        obj.ymID = ymIDHeader.ymHeader.ymID
        /*Filter with Hardware that are avilable in the FarmSetup page. More testing will happen when we sync mobile and web.*/
        let filteredYMIDObjs = filter(filteredYMs, [
          'farmtrxHardwareId',
          ymIDHeader.ymHeader.ymID,
        ])
        filteredYMIDObjs.forEach((item) => {
          obj = { ...filter(combines, ['id', item.equipmentId])[0], ...obj }
        })
        obj.ymHeader = []
        ymIDHeader.ymHeader.headerWidthMeters = parseFloat(
          ymIDHeader.ymHeader.headerWidthMeters.toFixed(2),
        )
        /*
        Check if any header width in the FarmSetup page is matching to any header width from the '/header-summary' call response. 
        If there are more than one headers with the same header widths, take only the first occurance. 
        */
        let filteredHeader = find(headers, [
          'cutWidth',
          ymIDHeader.ymHeader.headerWidthMeters,
        ])
        let filteredHeaders = []
        if (filteredHeader) {
          filteredHeaders.push(filteredHeader)
        }
        if (filteredHeaders.length == 0) {
          const { widthin, widthft } = toFeetInches(
            ymIDHeader.ymHeader.headerWidthMeters,
          )
          obj.ymHeader.push({
            fromES: true,
            percentage: ymIDHeader.ymHeader.percentage,
            cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
            id: obj.ymID,
            name: obj.ymID,
            widthin: widthin,
            widthft: widthft,
          })
          defaultValues[obj.ymID] = {
            id: obj.ymID,
            cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
          }
        } else {
          filteredHeaders.map((fh) => {
            //fh.fromES = true
            fh.percentage = ymIDHeader.ymHeader.percentage
            obj.ymHeader.push(fh)
            defaultValues[obj.id] = {
              id: obj.ymID,
              cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
            }
          })
        }
        //add the headers that are there in the farm setup page
        obj.ymHeader = uniq([...headers, ...obj.ymHeader])
        /* If the header widths are matching, show the farmSetup record in red color.*/
        if (filteredHeaders.length > 0) {
          let ind = obj.ymHeader.findIndex(
            (s) => s.id === filteredHeaders[0]?.id,
          )
          obj.ymHeader[ind].fromES = true
        }
        if (obj.id == undefined) {
          obj.id = obj.ymID
        }
        /*
        Add further records from the OpenSearch, '/header-summary' response for the same YMID. 
        Add only when there is no match with header widths in the FarmSetup.
        */
        if (yms.length && yms.filter((s) => s.ymID === obj.ymID).length) {
          let ymIDIndex = yms.findIndex((s) => s.ymID === obj['ymID'])
          const { widthin, widthft } = toFeetInches(
            ymIDHeader.ymHeader.headerWidthMeters,
          )
          if (filteredHeaders.length == 0) {
            yms[ymIDIndex].ymHeader.push({
              fromES: true,
              percentage: ymIDHeader.ymHeader.percentage,
              cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
              id: obj.ymID,
              name: obj.ymID,
              widthin: widthin,
              widthft: widthft,
            })
          }
        } else {
          yms.push(obj)
        }
      })
      setFilteredYMIDs(yms)
      const combinesWithHeaders = combines
      combinesWithHeaders.map((item) => {
        item.ymHeader = []
        headers.map((h) => {
          const { widthin, widthft } = toFeetInches(h.cutWidth)
          h.widthin = widthin
          h.widthft = widthft
          item.ymHeader.push(h)
        })
      })
      setHeader(defaultValues)
    } else {
      setShowNoHeaderHarvestData(true)
    }
  }, [ymIDHeaders, hardware, combines])

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  const selectHeader = useCallback(
    (e, row) => {
      const rowIndex = selected.findIndex((p) => p === row.id)
      if (e.type == 'change' && rowIndex == -1) {
        setShowAlert(false)
        let selectedHeader = header[row.id]
        let width = row.ymHeader[0].cutWidth
        let headerId = row.ymHeader[0].id
        if (selectedHeader != undefined) {
          width = selectedHeader.cutWidth
          headerId = selectedHeader.id
        }
        let obj = {
          combineId: row.id,
          harvestId: harvest.id,
          ymId: row.ymID,
          headerId: headerId,
          headerWidthMeters: width,
        }
        setSelected([...selected, row.id])
        setRows([...rows, obj])
      } else {
        const index = rows.findIndex((p) => p.ymId === row.ymID)
        if (rowIndex > -1) {
          let newRows = [...selected]
          newRows.splice(rowIndex, 1)
          setSelected(newRows)
        }
        if (index > -1) {
          let newRows = cloneDeep(rows)
          newRows.splice(index, 1)
          setRows(newRows)
        } else setRows([])
      }
    },
    [header, rows, selected],
  )

  const handleHeaderChange = useCallback(
    (event, row) => {
      event.stopPropagation()
      let _header = { ...header }
      const newHeader = row.ymHeader.find(
        (e) => `${e['id']} - ${e['cutWidth']}` === event.currentTarget.value,
      )
      if (newHeader) {
        _header[row.id] = newHeader
        setHeader(_header)
        const _rows = [...rows]
        const index = _rows.findIndex((r) => r.combineId == row.id)
        if (index > -1) {
          _rows[index].headerWidthMeters = newHeader.cutWidth
          _rows[index].headerId = newHeader.id
          setRows(_rows)
        }
      }
    },
    [header, headers, rows],
  )

  const setSelectedHeaderToHarvest = useCallback(() => {
    const finalRows = uniqWith(rows, isEqual)
    if (finalRows.length) {
      setShowAlert(false)

      rows.map((row) => {
        dispatch(updateHarvestHeader(harvest.id, row)).then((res) => {
          if (res === 'failed') {
            setHeaderUpdateFail(true)
          } else {
            dispatch(closeModal())
          }
        })
      })
    } else {
      setShowAlert(true)
    }
  }, [rows])

  const isSelected = (id) => selected && selected.indexOf(id) !== -1

  const selectAllHeaders = (event) => {
    let allHeadersChecked = event.target.checked
    if (allHeadersChecked) {
      let existingRows = [],
        notExistingRows = []
      const newSelecteds = combines.map((c) => c.id)
      setSelected(newSelecteds)
      rows.map((row) => {
        existingRows.push(
          combines.filter((item) => row.combineId === item.id)[0],
        )
      })
      notExistingRows = filteredYMIDs.filter((s) => {
        return existingRows.findIndex((e) => e.combineId == s.id) == -1
      })
      if (existingRows.length < combines.length) {
        let addRows = []
        notExistingRows.map((n) => {
          let selectedHeader = header[n.ymID]
          let headerId = n.ymHeader[0].id
          let width = n.ymHeader[0].cutWidth
          if (selectedHeader != undefined) {
            width = selectedHeader.cutWidth
            headerId = selectedHeader.id
          }
          let obj = {
            combineId: n.id,
            harvestId: harvest.id,
            ymId: n.ymID,
            headerId: headerId,
            headerWidthMeters: width,
          }
          addRows.push(obj)
        })
        const finalArray = [...rows, ...addRows]
        setRows(finalArray)
      }
    } else {
      setRows([])
      setSelected([])
    }
  }

  return (
    <ModalTool
      header={t('edit_layer_header')}
      content={
        <>
          <p>{t('set_header_map_reprocess')}</p>

          <Box mx={1} my={1} sx={{ display: showAlert ? 'block' : 'none' }}>
            <Alert severity="warning">{t('empty_harvest_info')}</Alert>
          </Box>
          <Box
            mx={1}
            my={1}
            sx={{ display: headerUpdateFail ? 'block' : 'none' }}
          >
            <Alert severity="warning">{t('update_header_error')}</Alert>
          </Box>

          <TableContainer
            component={Paper}
            style={{
              overflowY: 'auto',
              maxHeight: '35vh',
            }}
          >
            <Table
              className={classes.table}
              aria-label="set-header-table"
              stickyheader
              style={{
                overflowY: 'auto',
              }}
            >
              <TableHead>
                <TableRow role="checkbox">
                  <TableCell padding="checkbox">
                    <Checkbox onChange={selectAllHeaders} />
                  </TableCell>
                  <TableCell align="left">
                    {t('combine')}
                    <span className={classes.requiredField}>*</span>
                  </TableCell>
                  <TableCell align="left">
                    {t('header')}
                    <span className={classes.requiredField}>*</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  overflowY: 'auto',
                }}
              >
                {filteredYMIDs && filteredYMIDs.length > 0 ? (
                  filteredYMIDs.map((row) => {
                    const isItemSelected = isSelected(row.id)
                    return (
                      <TableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.borderBottomCustom}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(e) => selectHeader(e, row)}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.borderBottomCustom}
                        >
                          <TextField
                            disabled
                            name="combine"
                            variant="outlined"
                            size="small"
                            placeholder={t('select_equipment')}
                            value={row?.nickname?.trim() || row?.ymID}
                          ></TextField>
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.borderBottomCustom}
                        >
                          <>
                            <TextField
                              id="header"
                              name="header"
                              variant="outlined"
                              select
                              size="small"
                              placeholder={t('select_header')}
                              SelectProps={{ native: true }}
                              value={
                                `${header[row.id]?.id} - ${
                                  header[row.id]?.['cutWidth']
                                }` || row?.ymHeader[0]?.id
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              onChange={(e) => handleHeaderChange(e, row)}
                            >
                              {row.ymHeader && row.ymHeader.length ? (
                                row.ymHeader.map((item, index) => {
                                  return (
                                    <option
                                      value={`${item['id']} - ${item['cutWidth']}`}
                                      key={index}
                                      style={{
                                        color: item['fromES'] ? 'red' : 'black',
                                      }}
                                    >
                                      {item?.name} (
                                      {prefsUnit == 'm'
                                        ? `${item['cutWidth'].toFixed(2)} m`
                                        : `${item.widthft}' ${item.widthin}"`}
                                      ){' '}
                                      {item['percentage']
                                        ? item['percentage'].toFixed(1) + ' %'
                                        : ''}
                                    </option>
                                  )
                                })
                              ) : (
                                <></>
                              )}
                            </TextField>
                          </>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell>
                      <Box
                        mx={1}
                        my={1}
                        sx={{
                          display: showNoHeaderHarvestData ? 'block' : 'none',
                          width: 1,
                        }}
                      >
                        <Alert severity="info">{t('no_data')}</Alert>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      onClose={closePopup}
      onApply={setSelectedHeaderToHarvest}
    />
  )
}
