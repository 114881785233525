import { combineReducers } from 'redux'
import account from './account'
import app from './app'
import field from './field'
import map from './map'
import farm from './farm'
import cropVariety from './crop-variety'
import crop from './crop'
import brand from './brand'
import equipment from './equipment'
import hardware from './hardware'
import headers from './headers'
import integrations from './integrations'
import client from './client'
import ubjUploads from './ubj-upload'
import harvest from './harvest'
import job from './job'

export default combineReducers({
  account,
  app,
  field,
  map,
  farm,
  cropVariety,
  crop,
  brand,
  equipment,
  hardware,
  headers,
  integrations,
  client,
  ubjUploads,
  harvest,
  job,
})
