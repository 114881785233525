export const colorIds = [
  'brand',
  'brandLight',
  'brandTint',
  'brandSecondary',
  'brandSecondaryLight',
  'brandSecondaryTint',
  'grey',
  'greyLight',
  'greyLighter',
  'white',
  'highlight',
  'info',
  'infoLight',
  'infoLighter',
  'danger',
  'dangerLight',
  'dangerLighter',
  'success',
  'successLight',
  'successLighter',
  'secondary',
  'secondaryLight',
  'secondaryLighter',
  'warn',
  'warnLight',
  'warnLighter',
] as const
type colorId = typeof colorIds[number]
const colors: Record<colorId, string> = {
  brand: '#003057',
  brandLight: '#277FB2',
  brandTint: 'rgba(0, 48, 87, 0.3)',
  brandSecondary: '#F89822',
  brandSecondaryLight: '#F7D084',
  brandSecondaryTint: 'rgba(248, 152, 34, 0.3)',
  grey: '#aaa',
  greyLight: '#ccc',
  greyLighter: '#eee',
  white: '#fff',
  highlight: '#FFEEBA',
  info: '#055160',
  infoLight: '#b6effb',
  infoLighter: '#cff4fc',
  danger: '#842029',
  dangerLight: '#f5c2c7',
  dangerLighter: '#f8d7da',
  success: '#0f5132',
  successLight: '#badbcc',
  successLighter: '#d1e7dd',
  secondary: '#41464b',
  secondaryLight: '#ded6d8',
  secondaryLighter: '#e2e3e5',
  warn: '#664d03',
  warnLight: '#ffecb5',
  warnLighter: '#fff3cd',
}

export default colors
