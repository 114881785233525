import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash-es'
import type { AppState } from 'state'
import type { Tenant } from 'state/account'

const useStyles = makeStyles(() => ({
  labelCustomStyle: {
    alignItems: 'flex-start',
    width: '100%',
  },
  styledButton: {
    borderRadius: '25px',
  },
}))

export default function AccountDisabledPage() {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const partner: Tenant = useSelector((state) =>
    get(state as AppState, 'account.partnerContactDetails'),
  )

  let partnerName: string | undefined = 'FarmTRX'
  let partnerEmail: string | undefined = 'support@farmtrx.com'
  if (!isEmpty(partner)) {
    partnerName = partner.fullName
    partnerEmail = partner.email
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={12}>
        {t('account_deactivated', {
          partner_name: partnerName,
        })}
      </Grid>
      {partnerEmail ? (
        <Grid item md={12} lg={12}>
          {t('email')} {partnerEmail}
        </Grid>
      ) : null}
      {partner?.phoneNumber ? (
        <Grid item md={12} lg={12}>
          {t('phone_number')} {partner?.phoneNumber}
        </Grid>
      ) : null}
    </Grid>
  )
}
