import { Draw } from 'ol/interaction'
import useMap from 'hooks/use-map'
import useSource from 'hooks/use-source-boundary'
import Feature from 'ol/Feature'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { fromCircle } from 'ol/geom/Polygon'
import { isUndefined, noop, get, cloneDeep } from 'lodash-es'
import { useEffect } from 'react'
import { boundaryChanged } from 'actions/map'
import {
  toGeoJson,
  unKink,
  convertGeoJsonToOpenLayersFeature,
} from 'common/ol/utils'
import difference from '@turf/difference'
import Collection from 'ol/Collection'

export default function DrawInteraction(props) {
  const {
    onDraw = noop,
    active = false,
    type = 'MultiPolygon',
    withMemory = true,
  } = props
  let collection = new Collection([])

  const map = useMap()
  const dispatch = useDispatch()
  const source = useSource()
  const field = useSelector((state) => get(state, 'field.singleton'))
  const drawBoundary = useSelector((state) => get(state, 'map.drawBoundary'))
  const drawHole = useSelector((state) => get(state, 'map.drawHole'))

  const [interaction, setInteraction] = useState()
  const [feature, setFeature] = useState()
  const [holeToRemove, setHoleToRemove] = useState(null)

  useEffect(() => {
    function removeHole(e) {
      if (holeToRemove == e.feature) {
        source.removeFeature(e.feature)
        setHoleToRemove(null)
      }
    }

    source.on('addfeature', removeHole)
    return () => {
      source.un('addfeature', removeHole)
    }
  }, [source, holeToRemove])

  useEffect(() => {
    const interaction = new Draw({
      className: 'ol-draw',
      source,
      features: collection,
      type,
    })

    map.addInteraction(interaction)
    setInteraction(interaction)

    return () => {
      map.removeInteraction(interaction)
    }
  }, [map, source, type])

  function addHole(holes, boundary) {
    let field = toGeoJson(boundary)

    for (let i = 0; i < holes.features.length; i++) {
      const currentGeometry = holes.features[i].geometry
      currentGeometry.type = 'Polygon'
      const coordinateArray = cloneDeep(currentGeometry.coordinates)
      for (let j = 0; j < coordinateArray.length; j++) {
        currentGeometry.coordinates = coordinateArray[j]
        for (let k = 0; k < field.features.length; k++) {
          const res = difference(field.features[k], currentGeometry)
          if (res != null) {
            if (res.geometry.type == 'Polygon') {
              res.geometry.type = 'MultiPolygon'
              res.geometry.coordinates = [res.geometry.coordinates]
            }
            field.features[k] = res
          }
        }
      }
    }
    return field
  }

  useEffect(() => {
    if (!interaction) return

    function onDrawEnd(e) {
      let _feature

      if (isUndefined(feature)) {
        if (e.feature.getGeometry().getType() === 'Circle') {
          const p = fromCircle(e.feature.getGeometry(), 64)
          _feature = new Feature({
            geometry: new MultiPolygon([p.getCoordinates()]),
          })
        } else {
          const p = e.feature.getGeometry()
          _feature = new Feature({
            geometry: new MultiPolygon(p.getCoordinates()),
          })
        }
      } else {
        if (feature.getGeometry().getType() !== 'Circle') {
          feature
            .getGeometry()
            .appendPolygon(e.feature.getGeometry().getPolygons()[0])
        }

        _feature = feature
      }

      const unkinked = unKink(_feature, drawHole)

      if (drawBoundary) {
        _feature = convertGeoJsonToOpenLayersFeature(unkinked)
        const oldFeature = source.getFeatureById(field.id)
        if (oldFeature && oldFeature.ol_uid != _feature.ol_uid) {
          source.removeFeature(oldFeature)
        }

        const newFeature = source.getFeatures()
        let toRemove = []
        for (let f in newFeature) {
          if (
            newFeature[f].ol_uid != _feature.ol_uid &&
            newFeature[f].id_ == _feature.id_
          ) {
            toRemove.push(newFeature[f])
          }
        }
        for (let f in toRemove) {
          source.removeFeature(toRemove[f])
        }
        _feature.setId(field.id)
      } else if (drawHole) {
        let currentBoundary = source.getFeatureById(field.id)
        if (currentBoundary == null) {
          const features = source.getFeatures()

          for (let f in features) {
            if (features[f].id_ == field.id) {
              currentBoundary = features[f]
            } else if (
              features[f].getId() == undefined &&
              currentBoundary == null
            ) {
              currentBoundary = features[f]
              features[f].setId(field.id)
            }
          }
        }
        setHoleToRemove(e.feature)
        const tmp = cloneDeep(currentBoundary)
        const updatedField = addHole(unkinked, tmp)
        _feature = convertGeoJsonToOpenLayersFeature(updatedField)
        currentBoundary.setGeometry(_feature.getGeometry())
        //
      }

      _feature.setId(field.id)
      e.feature.setGeometry(_feature.getGeometry())
      e.feature.setId(field.id)
      dispatch(boundaryChanged())
      onDraw(_feature)

      if (withMemory) {
        setFeature(_feature)
      }
    }

    interaction.on('drawend', onDrawEnd)

    return () => {
      interaction.un('drawend', onDrawEnd)
    }
  }, [
    interaction,
    feature,
    onDraw,
    withMemory,
    drawBoundary,
    drawHole,
    source,
    map,
  ])

  useEffect(() => {
    if (interaction) {
      interaction.setActive(active)
    }

    if (active === false) {
      setFeature(undefined)
    }
  }, [interaction, active])

  return null
}

DrawInteraction.propTypes = {
  onDraw: PropTypes.func,
  active: PropTypes.bool,
  withMemory: PropTypes.bool,
  type: PropTypes.oneOf(['MultiPolygon', 'Circle']),
}
