import * as c from 'common/c'

export const cropConstantsFetched = (data) => {
  return {
    type: c.CROP_CONSTANTS_FETCHED,
    payload: data,
  }
}

export const cropsFetchedWithVariety = (data) => {
  return {
    type: c.CROPS_WITH_VARIETIES_FETCHED,
    payload: data,
  }
}
