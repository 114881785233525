import * as c from 'common/c'

export const equipmentsFetched = (data) => {
  return {
    type: c.EQUIPMENT_FETCHED,
    payload: data,
  }
}

export const addEquipment = (data) => {
  return {
    type: c.ADD_EQUIPMENT,
    payload: data,
  }
}

export const editEquipmentDetails = (data) => {
  return {
    type: c.EDIT_EQUIPMENT,
    payload: data,
  }
}

export const removeEquipment = (id) => {
  return {
    type: c.DELETE_EQUIPMENT,
    payload: id,
  }
}
