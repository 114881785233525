import styled from 'styled-components'
// import logo from 'images/farmtrx-logo-ondark.png'
import logo from 'images/farmtrx-logo.png'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  appLogo: {
    zIndex: theme.zIndex.drawer + 1,
    width: '250px',
    flex: '0 0 auto',
  },
}))

export default function Logo() {
  const classes = useStyles()
  return (
    <Link className={classes.appLogo} to={{ pathname: '/' }} target="_blank">
      <StyledImg width="100" height="17" src={logo} />
    </Link>
  )
}

const StyledImg = styled.img`
  max-width: 100px;
`
