import styled from 'styled-components'
import { deleteField } from 'api/field'

export default function DeleteField(props) {
  const { fieldName, id } = props

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const confirmDeleteField = useCallback(() => {
    dispatch(deleteField(id))
  })

  return (
    <>
      <Label>{t('delete_field_warning', { field_name: fieldName })}</Label>
      <ButtonStyled onClick={confirmDeleteField}>{t('delete')}</ButtonStyled>
    </>
  )
}

const ButtonStyled = styled.button`
  width: 100%;
  margin-top: 4px;
`

const Label = styled.label`
  color: black;
`
