import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from 'locales/en.json'
import translationFR from 'locales/fr.json'
import translationES from 'locales/es.json'
import translationESAR from 'locales/es-AR.json'
import translationUK from 'locales/uk.json'
import translationFI from 'locales/fi.json'
import translationPTBR from 'locales/pt-BR.json'
import translationPL from 'locales/pl.json'
import translationLT from 'locales/lt.json'
import translationLV from 'locales/lv.json'
import translationSV from 'locales/sv.json'

const options = {
  order: ['navigator'],
  excludeCacheFor: ['cimode'],
}

const resources = {
  en: { translation: translationEN },
  'en-US': { translation: translationEN },
  'fr-CA': { translation: translationFR },
  fr: { translation: translationFR },
  es: { translation: translationES },
  'es-AR': { translation: translationESAR },
  uk: { translation: translationUK },
  fi: { translation: translationFI },
  'pt-BR': { translation: translationPTBR },
  pl: { translation: translationPL },
  lt: { translation: translationLT },
  lv: { translation: translationLV },
  sv: { translation: translationSV },
}

i18n.use(initReactI18next).init({
  detection: options,
  lng: navigator.language || navigator.userLanguage,
  resources,
  fallbackLng: 'en',
  returnEmptyString: false,
  supportedLngs: [
    'en',
    'en-US',
    'fr-CA',
    'fr',
    'es',
    'es-AR',
    'uk',
    'fi',
    'pt-BR',
    'pl',
    'lt',
    'lv',
    'sv',
  ],
  interpolation: { escapeValue: false },
})

export default {}
