import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

export const middlewares = []

middlewares.push(thunk)

if (!IS_PRODUCTION) {
  console.info('enabling redux logger') // eslint-disable-line no-console
  middlewares.push(
    createLogger({
      collapsed: true,
      diff: true,
    }),
  )
}
