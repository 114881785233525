import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import ModalContainer from '../../modal/modal-container'
import ModalActions from '../../modal/modal-actions'
import ModalHeader from '../../modal/modal-header'
import Button from '@mui/material/Button'
import { closeModal } from 'actions/app'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { find, get } from 'lodash-es'
import type { AppState } from 'state'
import { assignFarms } from 'api/client'
import Alert from '@mui/material/Alert'

export default function OCFFAssignFarmsComponent(props) {
  const { selectedFarms, selectedIds } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const clients = useSelector((state) =>
    get(state as AppState, 'client.collection' || []),
  )

  const [selectedClient, setClient] = useState(clients[0].id)
  const [duplicateFarmMsg, showDuplicateFarmMsg] = useState(false)

  const closePopup = () => dispatch(closeModal())

  const onChangeClient = useCallback((e) => {
    setClient(parseInt(e.target.value))
  }, [])

  useEffect(()=>{
    if(duplicateFarmMsg){
      showDuplicateFarmMsg(false)
    }
    let clientObj = find(clients, ['id', selectedClient])
    let farms = clientObj?.['farms']
    let existingFarm
    selectedFarms?.map(farm=>{
      existingFarm = find(farms,['name',farm.name] )
      if(existingFarm){
        showDuplicateFarmMsg(true)
        return
      }
    })
  },[selectedClient])

  const assignFarmsToClient = useCallback(() => {
    dispatch(assignFarms({ selectedIds, selectedClient, selectedFarms }))
    const defaultClient = localStorage.getItem('selectedClient')
    if (defaultClient) {
      const client = JSON.parse(defaultClient)
      //If the selected client is the default client from localStorage
      if (client && client?.id === selectedClient) {
        const updatedFarms = selectedFarms.map(({ id, name }) => ({
          id,
          name,
        }))
        client.farms.push(...updatedFarms)
        localStorage.setItem('selectedClient', JSON.stringify(client))
      }
    }
  }, [selectedClient])

  return (
    <ModalContainer>
      <ModalHeader>{t('assign_farms_to_client')}</ModalHeader>
      {duplicateFarmMsg ? (
          <Alert severity="warning" style={{marginBottom:'15px'}}>
            {t('item_name_exists_for_selection', {
              item: t('farm'),
              selectedItem: t('client'),
            })}
          </Alert>
        ) : null}
      <StyledForm>
        <FormControlLabel
          control={
            <TextField
              id="client-name"
              name="client"
              variant="outlined"
              size="small"
              placeholder={t('client_name')}
              sx={{ marginLeft: '10px' }}
              value={selectedClient}
              onChange={onChangeClient}
              select
              SelectProps={{ native: true }}
            >
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </TextField>
          }
          label={t('client_name')}
          labelPlacement="start"
        />
      </StyledForm>
      <ModalActions>
        <Button variant="contained" color="inherit" onClick={closePopup}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={duplicateFarmMsg}
          onClick={assignFarmsToClient}
        >
          {t('assign')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
`
