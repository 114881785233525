import http from 'common/http'
import {
  ubjUploadsFetched,
} from 'actions/ubj'

export const getUbjUploads = () => {
    return (dispatch) => {
      return http.get('/ubj/history').then((res) => {
        dispatch(ubjUploadsFetched(res.data))
      })
    }
  }