import http from 'common/http'
import { cropConstantsFetched, cropsFetchedWithVariety } from 'actions/crop'

export const fetchDefaultCrops = () => {
  return (dispatch) => {
    return http.get('/crop').then((res) => {
      dispatch(cropConstantsFetched(res.data))
    })
  }
}

export const fetchCropsWithCropVariety = () => {
  return (dispatch) => {
    return http.get('/crop/with-crop-variety').then((res) => {
      dispatch(cropsFetchedWithVariety(res.data))
    })
  }
}
