import * as c from 'common/c'

declare module 'state/ubj-upload' {
  interface UbjUpload {
    rxtime: bigint
    tenantId: string
    data: {
      bucket: string
      size: bigint
      ubjTime: bigint
      ubjFileName: string
      status: string
      ymId: string
      epochseconds: bigint
    }
  }
  interface ubjUploads {
    uploads: UbjUpload[]
  }
}

const initialState = {
  uploads: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.UBJ_UPLOADS_FETCHED: {
      //@ts-ignore
      return update(state, { uploads: { $set: action.payload } })
    }
    default:
      return state
  }
}
