import styled from 'styled-components'
import { uniqueId, noop } from 'lodash-es'
import useMap from 'hooks/use-map'
import { defaultBackground, defaultShadow } from 'components/foundation'

/**
 * Create a Map.
 *
 * @param {object}   props
 * @param {string}   props.id      An identifier for the map. If none is provided one will get genereted. In either case
 *                                 the id will be available on props.onReady function.
 * @param {function} props.onReady When component is rendered and ready this function will run with the map's id as an argument.
 */
const OLMap = React.forwardRef((props, ref) => {
  const [id, setId] = useState(props.id)

  const map = useMap()

  useEffect(() => {
    if (map) {
      map.setTarget(ref.current)
      setId(uniqueId('map-'))
    }
  }, [map])

  useEffect(() => {
    if (id) {
      props.onReady(id)
    }
  }, [id])

  return (
    <StyledWrapper>
      <StyledMap id={id} ref={ref}>
        {props.children}
      </StyledMap>
    </StyledWrapper>
  )
})

OLMap.displayName = 'OpenLayersMap'

OLMap.propTypes = {
  id: PropTypes.string,
  onReady: PropTypes.func,
  children: PropTypes.node,
}

OLMap.defaultProps = { onReady: noop }

export default OLMap

const StyledWrapper = styled.div`
  height: 100%;
  ${defaultBackground}
  ${defaultShadow}
`

const StyledMap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
