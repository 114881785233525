import 'olgm/olgm.css'
import GoogleLayer from 'olgm/layer/Google'
import OLGoogleMaps from 'olgm/OLGoogleMaps'

import useMap from 'hooks/use-map'

export default function GoogleMaps() {
  const map = useMap()

  const [layer, setLayer] = useState()

  useEffect(() => {
    const l = new GoogleLayer({
      mapTypeId: google.maps.MapTypeId.HYBRID,
      maxZoom: 21,
    })
    l.set('title', 'Google Maps')
    setLayer(l)
  }, [])

  useEffect(() => {
    let olGM
    if (map && layer) {
      map.addLayer(layer)
      olGM = new OLGoogleMaps({ map })
      let gmap = olGM.getGoogleMapsMap()
      gmap.setTilt(0)
      olGM.activate()

      map.getView().on('change:resolution', () => {
        const zoom = map.getView().getZoom()
        gmap.setZoom(zoom)
        if (zoom > 19) {
          const typeId = gmap.getMapTypeId()
          if (typeId !== google.maps.MapTypeId.TERRAIN)
            gmap.setMapTypeId(google.maps.MapTypeId.TERRAIN)
        } else {
          gmap.setMapTypeId(google.maps.MapTypeId.HYBRID)
        }
      })
    }

    return () => {
      if (olGM) {
        olGM.deactivate()
      }
      map.removeLayer(layer)
    }
  }, [map, layer])

  return null
}
