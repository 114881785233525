import WelcomeScreen from './welcome-screen'
import WizardStepper from './wizard-stepper'
import AcceptEUSAScreen from './eusa-accept'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

export default function RegistrationWizard({ partner }) {
  return (
    <Router>
      <Routes>
        <Route
          path="/wizardStepper"
          element={<WizardStepper partner={partner} />}
        />
        <Route path="/welcomeScreen" element={<WelcomeScreen />} />
        <Route path="/" element={<AcceptEUSAScreen />} />
      </Routes>
    </Router>
  )
}
