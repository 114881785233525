import * as c from 'common/c'
declare module 'state/hardware' {
  export type HardwareModel = {
    createdAt?: string
    equipmentId: string
    id: number
    hardwareType: string
    hardwareVersion: string
    tenantId: string
    firstUbjDate: string
    farmtrxHardwareId: string
    deviceType: number
    updatedAt?: string
    metadata: object
  }
  export type hardware = {
    collection: HardwareModel[]
  }
}
const initialState = { collection: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case c.HARDWARE_FETCHED: {
      //@ts-ignore
      return update(state, { collection: { $set: action.payload } })
    }

    default:
      return state
  }
}
