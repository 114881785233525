import styled from 'styled-components'
import { defaultShadow, colors } from 'components/foundation'

export const FormGroup = styled.div`
  & + & {
    margin-top: 8px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  label > span.required {
    padding-left: 5px;
    font-size: 60%;
    color: ${({ error }) => (error ? colors.danger : colors.greyLight)};
  }

  & > input,
  select,
  input:focus {
    ${({ error }) => (error ? `border-color: ${colors.danger}` : '')};
  }
`

export const FormControl = styled.input`
  display: block;
  background: white;
  width: 400px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  line-height: 1.42857143;
  color: ${colors.text};
  border-radius: 4px;
  outline: 0;
  border: 1px solid ${colors.greyLight};

  &.disabled {
    background-color: ${colors.greyLighter};
  }

  &:focus {
    border: 1px solid ${colors.brandTint};
    ${defaultShadow}
  }
`

export const FormTextArea = styled(FormControl).attrs({ as: 'textarea' })`
  width: auto;
`

export const FormText = styled(FormControl)`
  width: auto;
`
