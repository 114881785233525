import * as c from 'common/c'

export const fieldFetched = (data) => {
  return {
    type: c.FIELD_FETCHED,
    payload: data,
  }
}
export const fieldFetchedA = (data) => {
  return {
    type: c.FIELD_FETCHED_A,
    payload: data,
  }
}
export const fieldFetchedB = (data) => {
  return {
    type: c.FIELD_FETCHED_B,
    payload: data,
  }
}

export const editFieldDetails = (data) => {
  return {
    type: c.EDIT_FIELD,
    payload: data,
  }
}

export const removeField = (idField) => {
  return {
    type: c.DELETE_FIELD,
    payload: idField,
  }
}

export const fieldsFetched = (data) => {
  return {
    type: c.FIELDS_FETCHED,
    payload: data,
  }
}

export const harvestedFieldsFetched = (data) => {
  return {
    type: c.HARVESTED_FIELDS_FETCHED,
    payload: data,
  }
}

export const addFeature = (data) => {
  return {
    type: c.ADD_FIELD_FEATURE,
    payload: data,
  }
}

export const clearFeatures = () => {
  return { type: c.CLEAR_FIELD_FEATURES }
}

export const setFeatures = (data) => {
  return {
    type: c.SET_FIELD_FEATURES,
    payload: data,
  }
}

export const featuresFetched = (data) => {
  return {
    type: c.FEATURES_FETCHED,
    payload: data,
  }
}

export const featuresFetchedA = (data) => {
  return {
    type: c.FEATURES_A_FETCHED,
    payload: data,
  }
}

export const featuresFetchedB = (data) => {
  return {
    type: c.FEATURES_B_FETCHED,
    payload: data,
  }
}

export const setDefaultExtent = (extent) => {
  return {
    type: c.SET_DEFAULT_EXTENT,
    payload: extent,
  }
}

export const setFeaturesOverview = (featuresArr) => {
  return {
    type: c.SET_FEATURES_OVERVIEW,
    payload: featuresArr,
  }
}

export const updateFeaturesOverview = (featuresArr) => {
  return {
    type: c.UPDATE_FEATURES_OVERVIEW,
    payload: featuresArr,
  }
}

export const updateCountryExtent = (data) => {
  return {
    type: c.UPDATE_COUNTRY_EXTENT,
    payload: data,
  }
}

export const setLayers = (o) => {
  return {
    type: c.SET_LAYERS,
    payload: o,
  }
}

export const setLayersA = (o) => {
  return {
    type: c.SET_LAYERS_A,
    payload: o,
  }
}

export const setLayersB = (o) => {
  return {
    type: c.SET_LAYERS_B,
    payload: o,
  }
}

export const resetLayers = () => {
  return { type: c.RESET_LAYERS }
}

export const resetLayersA = () => {
  return { type: c.RESET_LAYERS_A }
}

export const resetLayersB = () => {
  return { type: c.RESET_LAYERS_B }
}

export const layerInfoFetched = (data) => {
  return {
    type: c.LAYER_INFO_FETCHED,
    payload: data,
  }
}

export const layerInfoAFetched = (data) => {
  return {
    type: c.LAYER_INFO_A_FETCHED,
    payload: data,
  }
}

export const layerInfoBFetched = (data) => {
  return {
    type: c.LAYER_INFO_B_FETCHED,
    payload: data,
  }
}

export const fieldsHarvestedAFetched = (data) => {
  return {
    type: c.FIELDS_HARVESTED_A_FETCHED,
    payload: data,
  }
}

export const fieldsHarvestedBFetched = (data) => {
  return {
    type: c.FIELDS_HARVESTED_B_FETCHED,
    payload: data,
  }
}

export const fieldsImported = (data) => {
  return {
    type: c.FIELDS_IMPORTED,
    payload: data,
  }
}

export const removeFields = (data) => {
  return {
    type: c.DELETE_FIELDS,
    payload: data,
  }
}

export const setCrop = (data) => {
  return {
    type: c.SET_CROP,
    payload: {
      data,
    },
  }
}

export const setYear = (data) => {
  return {
    type: c.SET_YEAR,
    payload: {
      data,
    },
  }
}

export const setYearA = (data) => {
  return {
    type: c.SET_YEAR_A,
    payload: {
      data,
    },
  }
}

export const setYearB = (data) => {
  return {
    type: c.SET_YEAR_B,
    payload: {
      data,
    },
  }
}

export const downloadingLayer = (data) => {
  return {
    type: c.DOWNLOADING_LAYER,
    payload: data,
  }
}

export const editFieldName = () => {
  return {
    type: c.EDIT_FIELD_NAME,
    payload: true,
  }
}

export const cancelEditFieldName = () => {
  return {
    type: c.EDIT_FIELD_NAME,
    payload: false,
  }
}
