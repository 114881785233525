import { FloatingPanel } from 'components/floating'
import Histogram from 'components/histogram'
import styled, { css } from 'styled-components'
import { V } from 'components/layout'
import Button from '@mui/material/Button'

import { colors } from 'components/foundation'
import { vectorLayers, rasterLayers } from 'common/layer-data'
import { useState, useEffect, ReactChild, Component, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LegendURL } from 'state/map'
import { AppState } from 'state'
import { closeModal, showModal } from 'actions/app'
import { useDispatch } from 'react-redux'
import * as c from 'common/c'

export default function LayerLegend(props: {
  url: LegendURL[]
  harvestId: number
  lpVersion: string
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector(
    (state: AppState) => state.account.permissions,
  )

  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )

  const { harvestId, url, lpVersion } = props

  const isAdmin =
    permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM]
  const [filteredUrls, setFilteredUrls] = useState<LegendURL[]>([])
  const [legendItems, setLegendItems] = useState<
    (React.Component | ReactElement | JSX.Element | null)[]
  >([])
  const [histogramLayerName, setHistogramLayerName] = useState<any>('')

  useEffect(() => {
    const _filteredUrls: LegendURL[] = url.filter(
      (el) => el.harvest === harvestId,
    )
    setFilteredUrls(_filteredUrls)
  }, [url, harvestId])

  useEffect(() => {
    let _legendItems: (React.Component | ReactElement | JSX.Element | null)[] =
      []
    const vectorItems = vectorLayers.map((layer) => {
      const layerUrl = filteredUrls.find(
        (obj) => obj.name === layer.name && obj.harvest === harvestId,
      )
      return layerUrl ? (
        <V key={`${layer.name}_container`}>
          <V.Item key={layer.translation} space={false}>
            <LegendHeader>{t(layer.translation)}</LegendHeader>
          </V.Item>
          <V.Item key={`${layer.name}_itm`} space={false}>
            <img key={`${layer.name}_img`} src={layerUrl.url} alt="legend" />
          </V.Item>
        </V>
      ) : null
    })
    setHistogramLayerName('')
    const rasterItems = rasterLayers.map((layer) => {
      const layerUrl = filteredUrls.find(
        (obj) => obj.name === layer.name && obj.harvest === harvestId,
      )
      if (layerUrl) {
        if (layer.name === 'corrected_yield' || layer.name === 'raw_raster') {
          setHistogramLayerName(layer.name)
        }
      }
      return layerUrl ? (
        <V key={`${layer.name}_container`}>
          <V.Item key={layer.translation} space={false}>
            <LegendHeader>{t(layer.translation)}</LegendHeader>
          </V.Item>
          <V.Item key={`${layer.name}_itm`} space={false}>
            <img key={`${layer.name}_img`} src={layerUrl.url} alt="legend" />
          </V.Item>
        </V>
      ) : null
    })
    _legendItems = rasterItems.concat(vectorItems)
    setLegendItems(_legendItems)
  }, [filteredUrls, vectorLayers, rasterLayers])

  return filteredUrls.length > 0 ? (
    <FloatingPanel position={'right'} key={harvestId} background="white">
      {<div>{legendItems}</div>}
      {isAdmin ? (
        <div>
          <LegendHeader>{`LP Version: ${lpVersion}`}</LegendHeader>
          <Button
            color="secondary"
            variant="contained"
            disabled={histogramLayerName === ''}
            onClick={() => {
              dispatch(
                showModal(
                  <Histogram
                    harvestId={harvestId}
                    layerType={histogramLayerName}
                  />,
                ),
              )
            }}
          >
            {t('view_histogram')}
          </Button>
        </div>
      ) : null}
    </FloatingPanel>
  ) : null
}

const LegendHeader = styled.h1`
  color: ${colors.brand};
  font-size: clamp(
    10px,
    ${((window.screen.width + window.screen.height) / 2) * 0.01}px,
    15px
  );
  max-font-size: 10px;
  text-align: center;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
`

const selectedBorder = css`
  border: 2px solid ${colors.brandTint};
  border-radius: 4px;
`

const ButtonStyled = styled.button`
  width: 100%;
  margin-top: 4px;
`
