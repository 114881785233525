import styled from 'styled-components'
import { noop } from 'lodash-es'
import { isNotBlank } from 'common/misc'
import { BlankLink } from 'components/foundation'
import { Close } from '@troo/farmtrx-icons'
import useOnClickOverlay from '../../hooks/click-overlay'

export default function ModalInternal(props) {
  const { title, onClose, children } = props

  const ref = useRef()
  const [withTitle, setWithTitle] = useState(false)

  const onCloseClicked = useCallback(() => {
    onClose()
  }, [onClose])

  useOnClickOverlay(ref, () => {
    onClose()
  })

  useEffect(() => {
    setWithTitle(isNotBlank(title))
  }, [title])

  return (
    <StyledModal>
      <StyledWrapper ref={ref} withTitle={withTitle}>
        <StyledScrollingContent>
          {withTitle ? <StyledHeader key="title">{title}</StyledHeader> : null}
          <StyledClose key="close-link" onClick={onCloseClicked}>
            <Close />
          </StyledClose>
          {children}
        </StyledScrollingContent>
      </StyledWrapper>
    </StyledModal>
  )
}

ModalInternal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
}

ModalInternal.defaultProps = { onClose: noop }

const StyledScrollingContent = styled.div`
  max-height: 70vh;
  overflow-y: 'auto';
`

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

const StyledWrapper = styled.div`
  position: relative;
  margin: auto;
  top: 50%;
  transform: translate(0, -50%);
  background-color: white;
  padding: 40px;
  height: fit-content;
  width: fit-content;
  max-width: 80vw;
  max-height: 80vh;
  z-index: 1000;
  border-radius: 10px;
`

StyledWrapper.propTypes = { withTitle: PropTypes.bool }

StyledWrapper.defaultProps = { withTitle: false }

const StyledHeader = styled.h1`
  font-size: large;
  margin-top: 9px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eee;
`

const StyledClose = styled(BlankLink)`
  position: absolute;
  right: 12px;
  top: 10px;
`
