import TileLayer from 'ol/layer/Tile'
import { default as BingMapsOriginal } from 'ol/source/BingMaps'
import useMap from 'hooks/use-map'

export default function BingMaps() {
  const map = useMap()

  const [layer, setLayer] = useState()

  useEffect(() => {
    const l = new TileLayer({
      className: 'ol-bing-layer',
      preload: Infinity,
      source: new BingMapsOriginal({
        key: 'AsKplsu18X62IMEiHu-tFtngFldskZHHv0tr7ynr7lUkdLU74ynaD8LK1GLoIyDH',
        imagerySet: 'AerialWithLabelsOnDemand',
        maxZoom: 19,
      }),
    })
    l.set('title', 'Bing Maps')
    setLayer(l)
  }, [])

  useEffect(() => {
    if (map && layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [map, layer])

  return null
}
