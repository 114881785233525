import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { closeModal } from 'actions/app'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import ModalActions from '../modal/modal-actions'
import ModalHeader from '../modal/modal-header'
import ModalContainer from '../modal/modal-container'

const useStyles = makeStyles({
  styledButton: {
    width: '160px',
  },
})

export default function RequestReceived() {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { t } = useTranslation()

  const closeDialog = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <StyledContainer>
      <ModalHeader>{t('bulk_export')}</ModalHeader>
      <StyledContent>
        <Typography>{t('bulk_export_message')}</Typography>
      </StyledContent>
      <ModalActions>
        <Button
          variant="contained"
          onClick={closeDialog}
          className={classes.styledButton}
        >
          {t('ok')}
        </Button>
      </ModalActions>
    </StyledContainer>
  )
}

const ButtonStyled = styled.button`
  width: 100%;
  margin-top: 4px;
`

const Label = styled.label`
  color: black;
`

const StyledContainer = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
`
const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
`
