import * as c from 'common/c'
import { get, findIndex, cloneDeep } from 'lodash-es'
import update from 'immutability-helper'
import { FarmOptionModel } from 'state/farm'

declare module 'state/client' {
  export type IntegrationData = {
    id: string
    farms: string
    fields: string
    self: string
    archived: boolean
  }

  export type ClientModel = {
    id: number
    name: string
    streetAddress?: string
    postalCode?: string
    email?: string
    farms: FarmOptionModel[]
    integrationIds: { [key: string]: IntegrationData }
  }
  export type client = {
    collection: ClientModel[]
    singleton: ClientModel | undefined
  }
}

const initialState = {
  collection: [],
  singleton: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CLIENT_CREATED: {
      let obj = { ...action.payload, farms: [] }
      return update(state, {
        //@ts-ignore
        collection: { $unshift: [obj] },
        singleton: { $set: obj },
      })
    }

    case c.CLIENTS_FETCHED: {
      return update(state, {
        collection: { $set: action.payload },
        singleton: { $set: action.payload[0] },
      })
    }

    case c.CLIENT_EDITED: {
      const index = findIndex(
        get(state, 'collection', []),
        (client: ClientModel) => client.id === action.payload.id,
      )
      if (index > -1) {
        return update(state, {
          //@ts-ignore
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    case c.CLIENT_DELETED: {
      const index = findIndex(
        get(state, 'collection', []),
        (client: ClientModel) => client.id === action.payload,
      )
      if (index > -1) {
        return update(state, {
          collection: { $splice: [[index, 1]] },
        })
      } else return
    }

    case c.FARM_REFERENCE_ADDED: {
      const { id, clientId, name } = action.payload

      const index = findIndex(
        get(state, 'collection', []),
        (client: ClientModel) => client.id === clientId,
      )

      if (index > -1) {
        return update(state, {
          //@ts-ignore
          collection: {
            [index]: {
              farms: {
                $push: [
                  {
                    id,
                    name,
                  },
                ],
              },
            },
          },
        })
      } else return
    }

    case c.FARM_REFERENCE_UPDATED: {
      const { data } = action.payload

      if (!data.clientChanged) {
        const index = findIndex(
          get(state, 'collection', []),
          (client: ClientModel) => client.id === data.clientId,
        )

        if (index > -1) {
          const clientObj = state.collection.at(index)
          //@ts-ignore
          const farmIndex = findIndex(clientObj?.farms, ['id', data['id']])
          return update(state, {
            //@ts-ignore
            collection: {
              [index]: {
                farms: {
                  $splice: [[farmIndex, 1, { id: data.id, name: data.name }]],
                },
              },
            },
          })
        } else return
      } else {
        const newClientIndex = findIndex(
          get(state, 'collection', []),
          (client: ClientModel) => client.id === data['clientId'],
        )

        const oldClientIndex = findIndex(
          get(state, 'collection', []),
          (client: ClientModel) => client.id === data['oldClientId'],
        )

        //Add the farm to the new selected client and Delete the farm from the old selected client
        if (newClientIndex > -1 && oldClientIndex > -1) {
          const clientObj = state.collection.at(oldClientIndex)
          //@ts-ignore
          const farmIndex = findIndex(clientObj?.farms, ['id', data['id']])

          const newData = update(state, {
            //@ts-ignore
            collection: {
              [newClientIndex]: {
                farms: {
                  $push: [
                    {
                      id: data['id'],
                      name: data['name'],
                    },
                  ],
                },
              },
              [oldClientIndex]: {
                farms: {
                  $splice: [[farmIndex, 1]],
                },
              },
            },
          })
          return newData
        } else return
      }
    }

    case c.FARM_REFERENCE_DELETED: {
      const { id, clientId } = action.payload

      const index = findIndex(
        get(state, 'collection', []),
        (client: ClientModel) => client.id === clientId,
      )

      if (index > -1) {
        const clientObj = state.collection.at(index)
        //@ts-ignore
        const farmIndex = findIndex(clientObj?.farms, ['id', id])
        //clientObj?.farms?.splice(farmIndex, 1)
        if (farmIndex > -1) {
          return update(state, {
            //@ts-ignore
            collection: {
              [index]: { farms: { $splice: [[farmIndex, 1]] } },
            },
          })
        } else return
      }
    }

    case c.FARM_REFERENCES_UPDATED: {
      const { selectedIds, selectedClient, selectedFarms } = action.payload
      let clients: client[] = cloneDeep(state.collection)

      const newClientIndex = findIndex(
        get(state, 'collection', []),
        (client: ClientModel) => client.id === selectedClient,
      )

      selectedFarms.map((item) => {
        const oldClientIndex = findIndex(
          get(state, 'collection', []),
          (client: ClientModel) => client.id === item.clientId,
        )
        //For adding the newly selected farms to the client
        if (newClientIndex > -1 && oldClientIndex > -1) {
          //@ts-ignore
          item.clientId = selectedClient
          clients[newClientIndex]['farms'].push(item)

          const farmIndex = findIndex(clients[oldClientIndex]['farms'], [
            'id',
            item['id'],
          ])
          clients[oldClientIndex]['farms'].splice(farmIndex, 1)
        }
      })
      //@ts-ignore
      return update(state, { collection: { $set: clients } })
    }

    case c.CLIENT_SELECTED: {
      return update(state, { singleton: { $set: action.payload } })
    }

    default:
      return state
  }
}
