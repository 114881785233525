import { Alert } from '@mui/material'
import { closeModal, closePopup, showModal } from 'actions/app'
import {
  harvestsByYearFetched,
  addSingleFeatureToHarvest as addSingleFeatureToHarvestAction,
  setHarvestFeatures,
  harvestFetched,
  updateHarvestById,
  overrideHarvestHeader,
  harvestHeadersFetched,
  harvestYearsFetched,
  setYear,
  harvestsFetched,
  harvestsAFetched,
  harvestsBFetched,
} from 'actions/harvest'
import { downloadingLayer } from 'actions/field'
import { setYear as setYearField } from 'actions/field'

import http from 'common/http'
import { getYearsWithHarvests } from 'common/misc'
import i18next from 'i18next'
import { fetchFilteredFields } from './field'
import { isEmpty } from 'lodash'

import RequestReceived from 'components/bulk-export/request-received'

export const getHarvestsByYear = (year) => {
  return (dispatch) => {
    return http.get(`/harvest?year=${year}`).then((res) => {
      dispatch(harvestsByYearFetched(res.data))
    })
  }
}

export const getHarvestsByFarm = (farmId, year) => {
  return (dispatch) => {
    return http.get(`/harvest?farmId=${farmId}&year=${year}`).then((res) => {
      dispatch(harvestsByYearFetched(res.data))
    })
  }
}

export const getHarvestsByClient = (clientId, year) => {
  return (dispatch) => {
    return http
      .get(`/harvest?clientId=${clientId}&year=${year}`)
      .then((res) => {
        dispatch(harvestsByYearFetched(res.data))
      })
  }
}

export const addSingleFeatureToHarvest = (idHarvest, feature) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + idHarvest + '/features', feature)
      .then((res) => {
        dispatch(addSingleFeatureToHarvestAction(res.data))
        dispatch(closePopup())
      })
  }
}

export const updateSingleFeatureOfHarvest = (idHarvest, feature) => {
  return (dispatch) => {
    return http.put('/harvest/' + idHarvest + '/features', feature).then(() => {
      dispatch(closePopup())
    })
  }
}

export const getHarvestFeatures = (idHarvest) => {
  return (dispatch) => {
    return http.get('/harvest/' + idHarvest + '/features').then((res) => {
      dispatch(setHarvestFeatures(res.data))
    })
  }
}

export const editHarvestCropType = (id, payload) => {
  return (dispatch) => {
    return http.put('/harvest/' + id, payload).then((res) => {
      dispatch(harvestFetched(res.data))
      dispatch(updateHarvestById(res.data, payload))
    })
  }
}

export const updateTotalYield = (id, totalYield, totalYieldUnit) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/total-yield', {
        totalYield: totalYield,
        totalYieldUnit: totalYieldUnit,
      })
      .then(() => {
        dispatch(closeModal())
      })
  }
}

export const updateTimeDelay = (id, val, ymId, tenant_id) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/time-delay', {
        time_delay: parseFloat(val),
        ym_id: ymId,
        tenant_id: tenant_id,
      })
      .then(() => {
        dispatch(closeModal())
      })
  }
}

export const changeTestWeight = (id, val) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/test-weight', { testWeight: val })
      .then(() => {
        dispatch(closeModal())
      })
  }
}

export const updateHarvestHeader = (id, headerObj) => {
  return (dispatch) => {
    return http
      .put('/harvest/' + id + '/update-header', { headerObj })
      .then((res) => {
        if (res.data['request_status'] === 'failed') {
          return 'failed'
        } else {
          dispatch(overrideHarvestHeader(id, res))
        }
      })
  }
}

export const updateTare = (id, tareHz, tare, ym_id, tenant_id) => {
  return (dispatch) => {
    let updatedTare = tare
    if (tare > 1) {
      updatedTare = tare / 100.0
    }
    return http
      .post('/harvest/' + id + '/tare', {
        tare_hz: tareHz,
        tare: updatedTare,
        ym_id: ym_id,
        tenant_id: tenant_id,
      })
      .then(() => {
        dispatch(closeModal())
      })
  }
}

export const getYMIDHeaderHarvestSummary = (id, payload) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/header-summary', payload)
      .then((res) => {
        if (res.data.message ? res.data.message : res.data.Message) {
          dispatch(
            showModal(
              <Alert severity="error">
                {res.data.message ? res.data.message : res.data.Message}
              </Alert>,
              { title: i18next.t('alert') },
            ),
          )
        } else {
          dispatch(harvestHeadersFetched(id, res.data))
        }
      })
  }
}

export const updateCropCal = (id, val, ymId, tenant_id) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/crop-cal', {
        crop_cal: val,
        ym_id: ymId,
        tenant_id: tenant_id,
      })
      .then(() => {
        dispatch(closeModal())
      })
  }
}

export const getHarvestYears = () => {
  return (dispatch) => {
    return http.get('/harvest/years').then((res) => {
      let year = new Date().getFullYear()
      dispatch(harvestYearsFetched(res.data))

      const yearsWithHarvests = getYearsWithHarvests(res.data)
      if (yearsWithHarvests?.length) {
        year = yearsWithHarvests[0]
        if (year) {
          dispatch(setYear(year))
          dispatch(setYearField(year))
        }
      }
      //dispatch(fetchFilteredFields(year, 'all'))
    })
  }
}

export const reprocessHarvest = (id, tenant_id) => {
  return (dispatch) => {
    return http
      .post('/harvest/' + id + '/process', { tenant_id: tenant_id })
      .then(() => {
        dispatch(
          showModal(
            <Alert severity="info">
              {i18next.t('refresh_harvest_received')}
            </Alert>,
            { title: i18next.t('refresh_harvest') },
          ),
        )
      })
      .catch(() => {
        dispatch(
          showModal(
            <Alert severity="error">
              {i18next.t('refresh_harvest_received_error')}
            </Alert>,
            { title: i18next.t('refresh_harvest') },
          ),
        )
      })
  }
}

export const findHarvestsByFieldYearCrop = (id, year, crop = 'all') => {
  return (dispatch) => {
    if (id && year) {
      const url = `/harvest?fieldId=${id}&year=${year}&cropId=${crop}`
      return http.get(url).then((res) => {
        dispatch(harvestsFetched(res.data))
      })
    }
  }
}

export const findHarvestsByFieldYearCropA = (id, year, crop = 'all') => {
  return (dispatch) => {
    if (id && year) {
      const url = `/harvest?fieldId=${id}&year=${year}&cropId=${crop}`
      return http.get(url).then((res) => {
        dispatch(harvestsAFetched(res.data))
      })
    }
  }
}

export const findHarvestsByFieldYearCropB = (id, year, crop = 'all') => {
  return (dispatch) => {
    if (id && year) {
      const url = `/harvest?fieldId=${id}&year=${year}&cropId=${crop}`
      return http.get(url).then((res) => {
        dispatch(harvestsBFetched(res.data))
      })
    }
  }
}

export const findHarvestsByFieldA = (id) => {
  return (dispatch) => {
    if (id) {
      const url = `/harvest?fieldId=${id}`
      return http.get(url).then((res) => {
        dispatch(harvestsAFetched(res.data))
      })
    }
  }
}

export const findHarvestsByFieldB = (id) => {
  return (dispatch) => {
    if (id) {
      const url = `/harvest?fieldId=${id}`
      return http.get(url).then((res) => {
        dispatch(harvestsBFetched(res.data))
      })
    }
  }
}

export const getYieldHistogram = (
  id: number,
  layerType: string,
  yieldUnits: string,
): Promise<String> => {
  const url = `/harvest/${id}/histogram/${layerType}?yieldUnit=${yieldUnits}`
  return http.get(url).then((res) => {
    return res.data
  })
}

export const requestBulkExport = (data: {
  export_type: string
  crop_id?: number
  farm_id?: number
  client_id?: number
  year: number
}) => {
  const url = `/harvest/bulk-export`
  return (dispatch) =>
    http.post(url, data).then((res) => {
      dispatch(showModal(<RequestReceived />, {}))
    })
}
