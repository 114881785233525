import http from 'common/http'
import {
  subscriptionsFetched,
  subscriptionCreated,
  subscriptionPeriodCreated,
  harvestedAreaFetched,
  paymentInfoFetched,
  currentSubscriptionFetched,
  subscriptionPaymentFetched,
} from 'actions/subscriptions'

export const getSubscriptionDetails = () => {
  return (dispatch) => {
    return http.get('/subscription').then((res) => {
      dispatch(subscriptionsFetched(res.data))
      res.data[0]?.['SubscriptionPeriods']?.map((s) => {
        dispatch(getHarvestedArea(s.startDate, s.endDate))
        if (
          s.subscriptionStatus != 'free_year' &&
          s.subscriptionStatus != 'paid' &&
          s.subscriptionStatus != 'comped'
        ) {
          dispatch(getPaymentInfo(s.id))
        }
      })
    })
  }
}

export const createSubscription = (data) => {
  return (dispatch) => {
    return http
      .post('/subscription', data)
      .then((res) => {
        dispatch(subscriptionCreated(res.data))
        return res.data
      })
      .catch(() => {
        // do nothing
      })
  }
}

export const createSubscriptionPeriod = (data) => {
  return (dispatch) => {
    return http
      .post('/subscription/period', data)
      .then((res) => {
        dispatch(subscriptionPeriodCreated(res.data))
        return 'SubscriptionPeriodCreated'
      })
      .catch(() => {
        // do nothing
      })
  }
}

export const getHarvestedArea = (startDate, endDate) => {
  return (dispatch) => {
    const url = `/harvest/area?startDate=${startDate}&endDate=${endDate}`
    return http.get(url).then((res) => {
      dispatch(harvestedAreaFetched(res.data))
    })
  }
}

export const getPaymentInfo = (id) => {
  return (dispatch) => {
    return http.get(`/subscription/period/${id}/payment-link`).then((res) => {
      dispatch(paymentInfoFetched(id, res.data))
    })
  }
}

export const getCurrentSubscription = () => {
  return (dispatch) => {
    return http.get(`/subscription/status`).then((res) => {
      dispatch(currentSubscriptionFetched(res.data))
    })
  }
}

export const createSubscriptionPayment = (data) => {
  return (dispatch) => {
    return http
      .post('/subscription/period/create-payment', data)
      .then((res) => {
        dispatch(subscriptionPaymentFetched(res.data))
      })
  }
}
