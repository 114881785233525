import * as c from 'common/c'

const initialState = { cropConstantsCollection: [], cropsWithCropVarieties: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CROP_CONSTANTS_FETCHED: {
      return update(state, {
        cropConstantsCollection: { $set: action.payload },
      })
    }

    case c.CROPS_WITH_VARIETIES_FETCHED: {
      return update(state, { cropsWithCropVarieties: { $set: action.payload } })
    }

    default:
      return state
  }
}
