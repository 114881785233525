import styled from 'styled-components'
import { Lock, LockOpen } from '@mui/icons-material'
import { get } from 'lodash-es'
import { ButtonBase } from '@mui/material'
import colors from 'common/colors'
import useSplitMap from 'hooks/use-split-map'

export default function LockView(props) {
  const { lockViewRef } = props
  const { lock, onSwitchLock } = useSplitMap(lockViewRef)

  const fieldA = useSelector((state) => get(state, 'field.field_a'))
  const fieldB = useSelector((state) => get(state, 'field.field_b'))

  const lockUnlockIcon = lock ? (
    <Lock fontSize="inherit" sx={{ color: colors.brand }} />
  ) : (
    <LockOpen fontSize="inherit" sx={{ color: colors.brand }} />
  )

  return fieldA?.id === fieldB?.id ? (
    lockViewRef === 'lock_a' ? (
      <StyledLockA onClick={() => onSwitchLock()}>{lockUnlockIcon}</StyledLockA>
    ) : (
      <StyledLockB onClick={() => onSwitchLock()}>{lockUnlockIcon}</StyledLockB>
    )
  ) : null
}

const StyledLockA = styled(ButtonBase)`
  display: flex;
  position: absolute;
  height: 20px;
  width: 20px;
  padding: 2px;
  top: 10px;
  right: 10px;
  z-index: 100;
  background: white;
  border-radius: 4px;
`

const StyledLockB = styled(StyledLockA)`
  right: 50px;
`
