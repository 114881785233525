import { SvgIcon } from '@mui/material'

export function DrawPolygonIcon(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_4"
            data-name="Clip 4"
            d="M0,1.8A1.8,1.8,0,0,0,1.8,3.6h0A1.8,1.8,0,0,0,3.6,1.8h0A1.8,1.8,0,0,0,1.8,0h0A1.8,1.8,0,0,0,0,1.8Z"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Clip_22"
            data-name="Clip 22"
            d="M1.8,3.6A1.8,1.8,0,1,0,0,1.8,1.8,1.8,0,0,0,1.8,3.6Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g transform="scale(1.35)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M1.8,3.6A1.8,1.8,0,1,0,0,1.8,1.8,1.8,0,0,0,1.8,3.6"
          transform="translate(2.401)"
          fill="#00253b"
        />
        <g id="Group_6" data-name="Group 6" transform="translate(2.401)">
          <path
            id="Clip_4-2"
            data-name="Clip 4"
            d="M0,1.8A1.8,1.8,0,0,0,1.8,3.6h0A1.8,1.8,0,0,0,3.6,1.8h0A1.8,1.8,0,0,0,1.8,0h0A1.8,1.8,0,0,0,0,1.8Z"
            fill="#00253b"
          />
        </g>
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M1.8,3.6A1.8,1.8,0,1,0,0,1.8,1.8,1.8,0,0,0,1.8,3.6"
          transform="translate(0 12.6)"
          fill="#00253b"
        />
        <path
          id="Fill_13"
          data-name="Fill 13"
          d="M1.8,3.6A1.8,1.8,0,1,0,0,1.8,1.8,1.8,0,0,0,1.8,3.6"
          transform="translate(14.4 12.6)"
          fill="#00253b"
        />
        <g id="Group_18" data-name="Group 18" transform="translate(14.4 12.6)">
          <path
            id="Clip_16-2"
            data-name="Clip 16"
            d="M0,1.8A1.8,1.8,0,0,0,1.8,3.6h0A1.8,1.8,0,0,0,3.6,1.8h0A1.8,1.8,0,0,0,1.8,0h0A1.8,1.8,0,0,0,0,1.8Z"
            fill="#00253b"
          />
        </g>
        <g id="Group_24" data-name="Group 24" transform="translate(12)">
          <path
            id="Clip_22-2"
            data-name="Clip 22"
            d="M1.8,3.6A1.8,1.8,0,1,0,0,1.8,1.8,1.8,0,0,0,1.8,3.6Z"
            fill="none"
          />
          <g id="Group_24-2" data-name="Group 24" clipPath="url(#clip-path-3)">
            <path
              id="Fill_21"
              data-name="Fill 21"
              d="M0,555.6H864V0H0Z"
              transform="translate(-430.199 -201.6)"
              fill="#00253b"
            />
            <path
              id="Fill_23"
              data-name="Fill 23"
              d="M0,4.8H4.8V0H0Z"
              transform="translate(-0.599 -0.6)"
              fill="#00253b"
              stroke="#000"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="Group_28" data-name="Group 28" transform="translate(3.6 1.2)">
          <path
            id="Fill_25"
            data-name="Fill 25"
            d="M0,.6H9V0H0Z"
            transform="translate(1.8)"
            fill="#00253b"
          />
          <path
            id="Fill_27"
            data-name="Fill 27"
            d="M0,.6H11.4V0H0Z"
            transform="translate(0 12.6)"
            fill="#00253b"
          />
        </g>
        <g id="Group_33" data-name="Group 33" transform="translate(1.67 1.618)">
          <path
            id="Fill_29"
            data-name="Fill 29"
            d="M.591,12.17,0,12.067,2.113,0,2.7.1Z"
            transform="translate(0 0.234)"
            fill="#00253b"
          />
          <path
            id="Fill_31"
            data-name="Fill 31"
            d="M2.135,12.355,0,.1.591,0,2.726,12.252Z"
            transform="translate(11.632)"
            fill="#00253b"
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export function DrawCircleIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(1.227119, 0, 0, 1.227119, 0.304317, 0)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M.552,6.706A.552.552,0,0,1,.423,5.617,7.1,7.1,0,0,0,5.616.424.552.552,0,0,1,6.69.681,8.2,8.2,0,0,1,.682,6.691a.525.525,0,0,1-.13.015Z"
          transform="translate(10.618 10.618)"
          fill="#00253b"
        />
        <path
          id="Fill_2"
          data-name="Fill 2"
          d="M6.162,6.706a.544.544,0,0,1-.528-.422,7.011,7.011,0,0,0-5.209-5.2A.551.551,0,0,1,.013.43.544.544,0,0,1,.663.014,8.109,8.109,0,0,1,6.691,6.026a.55.55,0,0,1-.4.664.508.508,0,0,1-.128.016Z"
          transform="translate(10.618 1.676)"
          fill="#00253b"
        />
        <path
          id="Fill_3"
          data-name="Fill 3"
          d="M.556,6.706A.531.531,0,0,1,.425,6.69a.555.555,0,0,1-.409-.669,8.136,8.136,0,0,1,6-6,.554.554,0,1,1,.262,1.077A7.119,7.119,0,0,0,1.094,6.282a.555.555,0,0,1-.538.424Z"
          transform="translate(1.676 1.676)"
          fill="#00253b"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M6.153,6.706a.523.523,0,0,1-.13-.016A8.221,8.221,0,0,1,.016.684.553.553,0,0,1,1.091.425,7.1,7.1,0,0,0,6.283,5.615a.553.553,0,0,1-.129,1.091Z"
          transform="translate(1.676 10.618)"
          fill="#00253b"
        />
        <path
          id="Fill_5"
          data-name="Fill 5"
          d="M2.236,4.47A2.235,2.235,0,1,1,4.47,2.236,2.238,2.238,0,0,1,2.236,4.47Zm0-3.366A1.132,1.132,0,1,0,3.368,2.237,1.133,1.133,0,0,0,2.236,1.1Z"
          transform="translate(14.529 7.265)"
          fill="#00253b"
        />
        <path
          id="Fill_6"
          data-name="Fill 6"
          d="M2.236,4.47A2.235,2.235,0,1,1,4.47,2.236,2.238,2.238,0,0,1,2.236,4.47Zm0-3.366A1.132,1.132,0,1,0,3.368,2.236,1.133,1.133,0,0,0,2.236,1.1Z"
          transform="translate(0 7.265)"
          fill="#00253b"
        />
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M2.236,4.47A2.235,2.235,0,1,1,4.47,2.236,2.238,2.238,0,0,1,2.236,4.47Zm0-3.366A1.132,1.132,0,1,0,3.368,2.237,1.134,1.134,0,0,0,2.236,1.1Z"
          transform="translate(7.265)"
          fill="#00253b"
        />
        <path
          id="Fill_8"
          data-name="Fill 8"
          d="M2.236,4.47A2.235,2.235,0,1,1,4.47,2.236,2.238,2.238,0,0,1,2.236,4.47Zm0-3.367A1.132,1.132,0,1,0,3.368,2.236,1.134,1.134,0,0,0,2.236,1.1Z"
          transform="translate(7.265 15.088)"
          fill="#00253b"
        />
      </g>
    </SvgIcon>
  )
}

export function EditBoundaryIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        id="Group_12_Copy_4-2"
        data-name="Group 12 Copy 4"
        clipPath="url(#clip-path-boundary)"
        transform="matrix(1.330539, 0, 0, 1.330539, 0, -0.000141)"
      >
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M3.782,4.271H.488A.489.489,0,0,1,0,3.782V.488A.488.488,0,0,1,.488,0H3.782a.489.489,0,0,1,.489.488V3.782A.489.489,0,0,1,3.782,4.271ZM.977.977V3.294H3.294V.977Z"
          transform="translate(0 3.847)"
          fill="#00253b"
        />
        <path
          id="Fill_3"
          data-name="Fill 3"
          d="M3.782,4.271H.488A.489.489,0,0,1,0,3.782V.488A.488.488,0,0,1,.488,0H3.782A.488.488,0,0,1,4.27.488V3.782A.489.489,0,0,1,3.782,4.271ZM.976.977V3.294H3.294V.977Z"
          transform="translate(9.883 13.73)"
          fill="#00253b"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M3.782,4.271H.488A.489.489,0,0,1,0,3.782V.488A.488.488,0,0,1,.488,0H3.782a.489.489,0,0,1,.489.488V3.782A.489.489,0,0,1,3.782,4.271ZM.977.977V3.294H3.294V.977Z"
          transform="translate(0 13.73)"
          fill="#00253b"
        />
        <path
          id="Fill_5"
          data-name="Fill 5"
          d="M.488,7.564A.488.488,0,0,1,0,7.076V.488a.488.488,0,0,1,.976,0V7.076a.488.488,0,0,1-.488.488"
          transform="translate(11.53 7.141)"
          fill="#00253b"
        />
        <path
          id="Fill_6"
          data-name="Fill 6"
          d="M7.076.976H.488A.488.488,0,0,1,.488,0H7.076a.488.488,0,0,1,0,.976"
          transform="translate(3.294 5.494)"
          fill="#00253b"
        />
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M.488,7.564A.488.488,0,0,1,0,7.076V.488a.488.488,0,0,1,.976,0V7.076a.488.488,0,0,1-.488.488"
          transform="translate(1.647 7.142)"
          fill="#00253b"
        />
        <path
          id="Fill_8"
          data-name="Fill 8"
          d="M7.076.976H.488A.488.488,0,0,1,.488,0H7.076a.488.488,0,0,1,0,.976"
          transform="translate(3.294 15.377)"
          fill="#00253b"
        />
        <path
          id="Fill_11"
          data-name="Fill 11"
          d="M.428,12.346a.427.427,0,0,1-.416-.531L.795,8.669a.436.436,0,0,1,.113-.2L8.259.615a2.1,2.1,0,0,1,2.971,2.97L3.878,11.44a.43.43,0,0,1-.2.113l-3.146.782A.444.444,0,0,1,.428,12.346ZM7.186,2.9,1.6,8.991l-.581,2.34,2.34-.581,5.59-6.092ZM9.745.857a1.236,1.236,0,0,0-.88.364L7.792,2.293,9.551,4.052l1.073-1.073A1.243,1.243,0,0,0,9.745.857Z"
          transform="translate(5.583)"
          fill="#00253b"
        />
      </g>
    </SvgIcon>
  )
}

export function DrawCircleHoleIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        transform="scale(1.35)"
        id="Group_Copy_10"
        data-name="Group Copy 10"
        opacity="0.82"
      >
        <path
          id="Path_2"
          data-name="Path 2"
          d="M0,0,15.81,1.209V14.151L0,16Z"
          fill="#00253b"
        />
        <circle
          id="Oval"
          cx="3.634"
          cy="3.634"
          r="3.634"
          transform="translate(4.258 4.088)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  )
}

export function DrawPolygonHoleIcon(props) {
  return (
    <SvgIcon {...props}>
      <g
        transform="scale(1.35)"
        id="Group_Copy_11"
        data-name="Group Copy 11"
        opacity="0.9"
      >
        <path
          id="Path_2"
          data-name="Path 2"
          d="M0,0,16,1.224v13.1L0,16.193Z"
          fill="#00253b"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M0,1.282,6.348,0,7.918,6.628H2.067Z"
          transform="translate(3.778 4.402)"
          fill="#d8d8d8"
        />
      </g>
    </SvgIcon>
  )
}
