import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { getStyleByType } from 'common/ol/styles'
import useMap from 'hooks/use-map'
import * as c from 'common/c'

export default function MapNotesLayer({ featureCollection }) {
  const map = useMap()

  const [layer, setLayer] = useState()

  useEffect(() => {
    if (map) {
      const _layer = new VectorLayer({
        className: 'notes-layer',
        source: new VectorSource({ useSpatialIndex: false }),
        style: function (feature) {
          return getStyleByType(
            feature ? feature.get(c.OL_PROP_TYPE) : c.FTYPE_NOTE,
          )
        },
      })

      setLayer(_layer)
    }
  }, [map])

  useEffect(() => {
    if (layer) {
      console.log(featureCollection.getArray())
      const parsedFeatures = []
      const features = featureCollection.getArray()
      for (let i = 0; i < features.length; i++) {
        if (features[i].getGeometry() != null) {
          parsedFeatures.push(features[i])
        }
      }
      layer.getSource().clear({ fast: true })
      layer.getSource().addFeatures(parsedFeatures)
    }
  }, [layer, featureCollection])

  useEffect(() => {
    if (layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [layer])

  return null
}
