import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CommonSidebar, CommonSidebarLink } from '../common-sidebar'
import OCFFClientsComponent from '../ocff/clients'
import OCFFFarmsComponent from '../ocff/farms'
import OCFFieldsComponent from '../ocff/fields'
import UploadBoundariesComponent from './upload-boundaries'
import Divider from '@mui/material/Divider'
import { fetchFieldImport } from 'api/field'
import {
  FarmtrxIconUploadNew,
  FarmtrxIconClients,
  FarmtrxIconFarms,
  FarmtrxIconFields,
} from '@troo/farmtrx-icons'

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', overflowY: 'auto' },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflowX: 'auto',
  },
}))

export default function LandsComponent() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [reader, setReader] = useState()
  const dispatch = useDispatch()
  const enabledCFF = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  useEffect(() => {
    setReader(new FileReader())
  }, [])

  const onImportField = useCallback((fileList) => {
    let files = fileList
    if (files.length > 0) {
      let name = files[0].name
      if (name.indexOf('.zip') != -1) {
        convertFilePayload(files[0])
      } else {
        alert('Files to import should be zip files')
      }
    }
  })

  const convertFilePayload = useCallback((files) => {
    reader.readAsDataURL(files)
    reader.onload = function () {
      let fieldImportPayload = reader.result.split(',')[1]
      let fieldImportObj = {
        fileName: files.name,
        payload: fieldImportPayload,
      }
      dispatch(fetchFieldImport(fieldImportObj))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error) // eslint-disable-line no-console
    }
  })

  return (
    <Box className={classes.root} width={1}>
      <CommonSidebar>
        {/* OCFF tabs */}
        {enabledCFF ? (
          <>
            <CommonSidebarLink
              to="clients"
              icon={<FarmtrxIconClients />}
              label={t('clients')}
            />
            <Divider />
            <CommonSidebarLink
              to="farms"
              icon={<FarmtrxIconFarms />}
              label={t('farms')}
            />
            <Divider />
          </>
        ) : (
          <></>
        )}
        <CommonSidebarLink
          to="fields"
          icon={<FarmtrxIconFields />}
          label={t('fields')}
        />
        <Divider />
        <CommonSidebarLink
          to="upload-boundaries"
          icon={<FarmtrxIconUploadNew />}
          label={t('upload_boundaries')}
        />
        <Divider />
      </CommonSidebar>
      {/* End */}

      <Routes>
        <Route
          exact
          path="/"
          element={
            enabledCFF ? (
              <Navigate to="/fields/clients" replace={true} />
            ) : (
              <Navigate to="/fields/fields" replace={true} />
            )
          }
        />

        {enabledCFF ? (
          <Route path="/clients" element={<OCFFClientsComponent />} />
        ) : (
          <Route
            path="/clients"
            element={
              <Box m={6}>
                <div>{t('no_permissions', { item: t('clients') })}</div>
              </Box>
            }
          />
        )}

        {enabledCFF ? (
          <Route path="/farms" element={<OCFFFarmsComponent />} />
        ) : (
          <Route
            path="/farms"
            element={
              <Box m={6}>
                <div>{t('no_permissions', { item: t('farms') })}</div>
              </Box>
            }
          />
        )}

        <Route path="/farms" element={<OCFFFarmsComponent />} />
        <Route path="/fields" element={<OCFFieldsComponent />} />
        <Route
          path="/upload-boundaries"
          element={<UploadBoundariesComponent onImportField={onImportField} />}
        />
      </Routes>
    </Box>
  )
}
