import { useCallback, ReactElement } from 'react'
import { changeAccountStatus } from 'api/user'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { closeModal } from 'actions/app'
import ModalTool from 'components/modal/modal-tool'

type AccountStatusPropType = {
  tenantId: string
  fullName: string
  organizationName: string
  email: string
  accountEnabled: boolean
}

export default function ChangeAccountStatus(props: {
  data: AccountStatusPropType
}): ReactElement<any, any> {
  const { data } = props
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const onSubmit = useCallback(() => {
    dispatch(changeAccountStatus(data.tenantId, !data.accountEnabled))
    dispatch(closeModal())
  }, [])

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <ModalTool
      header={t('account_access_change')}
      content={
        data?.accountEnabled
          ? t('disable_account_confirm', {
              farm_name: data.organizationName,
            })
          : t('enable_account_confirm', {
              farm_name: data.organizationName,
            })
      }
      onClose={closePopup}
      onApply={onSubmit}
    />
  )
}
