import { FloatingPanel } from 'components/floating'
import {
  // FarmtrxIconDocumentation,
  Info,
} from '@troo/farmtrx-icons'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
//import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import Tooltip from '@mui/material/Tooltip'
import { useEffect } from 'react'

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiButtonGroup-grouped': {
      border: 'none !important',
      '&:hover': {
        background: '#F2F2F2',
      },
      '&:disabled': {
        background: '#F2F2F2',
      },
      '&:focus': {
        background: '#003057',
        color: '#fff',
        '& > span:nth-child(1) svg': {
          stroke: '#fff !important',
        },
      },
    },
    '.MuiButtonGroup-grouped:last-child': {
      borderTop: '1px solid gray !important',
    },
    '.MuiTooltip-tooltip': {
      position: 'relative',
      width: 'auto',
      height: '25px',
      background: '#003057',
      borderRadius: '0 8px 8px 0',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '50%',
        width: 0,
        height: 0,
        border: '13px solid transparent',
        borderRightColor: '#003057',
        borderLeft: 0,
        marginTop: '-13px',
        marginLeft: '-13px',
      },
    },
  },
  defaultMode: {
    maxWidth: 'fit-content',
    '& > span:nth-child(2)': {
      display: 'none',
    },
    '& > span:nth-child(1)': {
      marginRight: 0,
    },
  },
  expandedMode: {
    maxWidth: 'initial',
    '& > span:nth-child(2)': {
      display: 'block',
    },
    '& > span:nth-child(1)': {
      marginRight: '8px',
    },
  },
}))

const StyledButton = withStyles({
  root: {
    justifyContent: 'start',
    background: '#F2F2F2',
    color: '#003057',
  },
})(Button)

export default function HarvestToolbar(props) {
  const {
    onPointInfo,
    //onAreaInfo,
    //onAddNote
  } = props

  const { t } = useTranslation()
  const classes = useStyles()
  const [defaultState, setDefaultState] = useState(true)
  const [selectedBtn, setSelectedBtn] = useState()

  useEffect(() => {
    return () => {
      if (selectedBtn === 'point-inspection') {
        onPointInfo()
      }
    }
  }, [selectedBtn])

  const expandToggle = useCallback(() => {
    setDefaultState(!defaultState)
  })

  const showBtnText = useCallback((e, id) => {
    document.querySelector('#harvest-toolbar').style.boxShadow = 'none'
    let prevSelectedBtn = selectedBtn
    const el = prevSelectedBtn && document.querySelector(`#${prevSelectedBtn}`)
    if (el) {
      let classList = el && Array.from(el.classList)
      classList.map((cls) => {
        if (cls.indexOf('makeStyles-expandedMode') != -1) {
          document.getElementById(prevSelectedBtn).classList.remove(cls)
        }
      })
    }

    document.getElementById(id).classList.add(classes.expandedMode)
    setSelectedBtn(id)
  })
  return (
    <FloatingPanel position="top">
      <ButtonGroup
        id="harvest-toolbar"
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
      >
        <Tooltip title={t('point_inspection')} placement="right">
          <StyledButton
            id="point-inspection"
            className={`toolButtons ${
              defaultState ? classes.defaultMode : classes.expandedMode
            }`}
            key="point-inspection"
            startIcon={
              <Info onClick={(e) => showBtnText(e, 'point-inspection')} />
            }
            onClick={onPointInfo}
          >
            <span>{t('point_inspection')}</span>
          </StyledButton>
        </Tooltip>
        {/*<Tooltip title={t('area_inspection')} placement="right">
          <StyledButton
            id="area-inspection"
            className={`toolButtons ${
              defaultState ? classes.defaultMode : classes.expandedMode
            }`}
            key="area-inspection"
            startIcon={
              <CircleOutlinedIcon
                onClick={(e) => showBtnText(e, 'area-inspection')}
              />
            }
            onClick={onAreaInfo}
          >
            <span>{t('area_inspection')}</span>
          </StyledButton>
        </Tooltip>
        <Tooltip title={t('add_note')} placement="right">
                    <StyledButton id='add-note' className={`toolButtons ${defaultState ? classes.defaultMode : classes.expandedMode}`} key="add-note" startIcon={<FarmtrxIconDocumentation onClick={(e) => showBtnText(e, 'add-note')} />} onClick={onAddNote}>
                        <span> {t('add_note')} </span>
                    </StyledButton>
    </Tooltip>*/}

        <StyledButton
          className={defaultState ? classes.defaultMode : classes.expandedMode}
          key="expand"
          startIcon={
            defaultState ? (
              <DoubleArrowIcon />
            ) : (
              <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
            )
          }
          onClick={expandToggle}
        >
          <span>{t('collapse')}</span>
        </StyledButton>
      </ButtonGroup>
    </FloatingPanel>
  )
}
