import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { closeModal } from 'actions/app'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import * as c from 'common/c'

export default function ScreenSizeWarning({ onScroll }) {
  const width = window.screen.width * 0.6
  const height = window.screen.height * 0.35
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onCloseClicked = useCallback(() => {
    localStorage.setItem(c.WARNING_CLEARED_KEY, true)
    dispatch(closeModal())
  })

  return (
    <Box
      px={3}
      py={4}
      sx={{
        height: height + 'px',
        margin: 'auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 'auto',
        width: width + 'px',
      }}
      onScroll={onScroll}
      className="screenSizeWarning"
    >
      <Typography align="left" paragraph={true} variant="h5">
        <b>{t('screen_resolution_warning')}</b>
      </Typography>
      <Typography align="left" paragraph={true}>
        {t('screen_size_warning_one')} <b>{t('screen_size_warning_two')}</b>.{' '}
        {t('screen_size_warning_three')}.
      </Typography>
      <Button
        align="left"
        style={{ backgroundColor: '#FFA500', display: 'block' }}
        onClick={onCloseClicked}
      >
        {t('acknowledge_and_continue')}
      </Button>
    </Box>
  )
}
