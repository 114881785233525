import useSplitMap from 'hooks/use-split-map'
import { transformExtent } from 'ol/proj'

const DEFAULT_PADDING = [65, 60, 110, 15]

export default function FocusFieldB(props) {
  const { boundary, defaultExtent } = props

  const { map_b } = useSplitMap()

  useEffect(() => {
    if (boundary) {
      map_b
        .getView()
        .fit(boundary.getGeometry().getExtent(), { padding: DEFAULT_PADDING })
    } else if (defaultExtent) {
      map_b
        .getView()
        .fit(transformExtent(defaultExtent, 'EPSG:4326', 'EPSG:3857'))
    }
  }, [map_b, boundary, defaultExtent])

  return null
}
