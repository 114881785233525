import styled from 'styled-components'
import loadingAnimation from 'images/loading.gif'

export function Loading(props) {
  return (
    <StyledDiv fullscreen={props.fullscreen}>
      <StyledImg src={loadingAnimation} />
      {props.children}
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => (props.fullscreen ? 'position:absolute; top:0; left:0;' : '')}
`

const StyledImg = styled.img`
  width: 20rem;
`
