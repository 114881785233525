import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export function CommonSidebarLink(props) {
  return (
    <StyledListItem disablePadding component={NavLink} {...props}>
      {props.label && (
        <ListItemButton>
          {props.icon && (
            <ListItemIcon style={{ minWidth: '24px', marginRight: '12px' }}>
              {props.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={props.label}
            style={{ fontWeight: 'bolder' }}
          />
        </ListItemButton>
      )}
      {props.children}
    </StyledListItem>
  )
}

const StyledListItem = styled(ListItem)`
  &.active {
    .MuiTypography-root {
      color: #000;
      font-weight: 500;
    }
    .MuiListItemIcon-root {
      svg path {
        fill: #3475e0;
      }
    }
    background-color: #c6def8;
  }
  & {
    .MuiTypography-root {
      color: #000;
      font-weight: 500;
    }
  }
`
