import styled from 'styled-components'

export default function H(props) {
  const { className, children } = props

  return (
    <StyledContainer className={className} {...props}>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  flex-direction: row;
`

H.propTypes = {
  children: PropTypes.node.isRequired,
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
}

H.defaultProps = { justifyContent: 'flex-start' }

H.Item = function (props) {
  const { className, children } = props

  return (
    <StyledItem className={className} gutter={props.gutter}>
      {children}
    </StyledItem>
  )
}

H.Item.displayName = 'Horizontal Item'

H.Item.propTypes = { gutter: PropTypes.string }

H.Item.defaultProps = { gutter: '10px' }

const StyledItem = styled.div`
  & + & {
    margin-left: ${(props) => props.gutter};
  }
`
