import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import { showModal } from 'actions/app'
import { get, noop, filter, find, sumBy } from 'lodash-es'
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { fromGeoJson } from 'common/ol/utils'
import { getArea } from 'ol/sphere.js'
import * as c from 'common/c'
import { convertAreaToPref } from 'common/unitConvert'
import OCFFAddClientComponent from './add-client'
import OCFFEditClientComponent from './edit-client'
import OCFFDeleteClient from './delete-client'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import type { AppState } from 'state'

const useStyles = makeStyles(() => ({
  dataGridContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    height: '80%',
  },
  dataGridHeader: {
    background: '#f8f8f9',
    textTransform: 'uppercase',
  },
}))

declare module 'components/ocff/clients' {
  type featuresOverviewType = {
    id: number
    geometry: object
    properties: object
  }

  type fieldAreaMapType = {
    id: number
    area: number
    farmId: number
  }

  type RowData = {
    id: number
    fieldArea: string | 0
    name: string
    farmsQuantity: number
    fieldsQuantity: number
  }

  type clientColumns = {
    field: string
    headerName: string
    minWidth: number
    maxWidth: number
    flex: number
  }
}
export default function OCFFClientsComponent(): ReactElement<any, any> {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const fields = useSelector((state) => get(state, 'field.collection' || []))
  const farms = useSelector((state) =>
    get(state as AppState, 'farm.collection' || []),
  )
  const clients = useSelector((state) =>
    get(state as AppState, 'client.collection' || []),
  )
  const userPrefs = useSelector((state) =>
    get(state as AppState, 'account.singleton.prefs'),
  )
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const [searchValue, setSearchValue] = useState('')

  const permissions = useSelector((state) =>
    get(state as AppState, 'account.permissions'),
  )
  const showClients = permissions.includes('farm:read')
  const addModifyClients = permissions.includes('farm:write')

  const [columns, setColumns] = useState<clientColumns[]>([])
  const [fieldAreaMap, setFieldAreaMap] = useState<fieldAreaMapType[]>([])

  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
  })
  const featuresOverviewArr = useSelector(
    (state) => state['field']['featuresOverview'],
  )
  const [rows, setRows] = useState<RowData[]>([])

  useEffect(() => {
    const _fieldAreaMap: fieldAreaMapType[] = []
    const allFeaturesArr: featuresOverviewType[] = []
    if (featuresOverviewArr && featuresOverviewArr !== c.UNDEFINED) {
      featuresOverviewArr.forEach((item) => {
        allFeaturesArr.push(Object.assign({}, { type: 'Feature' }, { ...item }))
      })
    }
    const featureCollection = fromGeoJson({
      type: 'FeatureCollection',
      features: allFeaturesArr,
    })

    featureCollection.forEach(function (feature) {
      const id = feature.getId()
      //to get farmId
      let farmId = find(fields, ['id', id])?.['farmId']
      //end
      let area = 0
      area = getArea(feature.getGeometry())
      _fieldAreaMap.push({
        id,
        area: Math.floor(area),
        farmId,
      })
    })
    setFieldAreaMap(_fieldAreaMap)
  }, [featuresOverviewArr])

  const onSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const filterClients = (searchValue, client) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()
      return client.name.toLowerCase().indexOf(lowerSearchValue) > -1
    }
    return true
  }

  const showAddClientPopup = useCallback(() => {
    dispatch(showModal(<OCFFAddClientComponent />))
  }, [])

  useEffect(() => {
    const _columns: clientColumns[] = [
      {
        field: 'name',
        headerName: t('client'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'farmsQuantity',
        headerName: t('farms_quantity'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldsQuantity',
        headerName: t('fields_quantity'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
      {
        field: 'fieldArea',
        headerName: t('field_area'),
        minWidth: 200,
        maxWidth: 400,
        flex: 1,
      },
    ]
    setColumns(_columns)
    setRowSelectionModel([])
  }, [clients])

  function CustomNoRowsOverlayFields(): ReactElement {
    if (showClients) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {t('no_clients')}
        </div>
      )
    } else
      return (
        <div style={{ textAlign: 'center', padding: '32px' }}>
          {t('no_permissions', { item: t('clients') })}
        </div>
      )
  }

  useEffect(() => {
    let _rows: RowData[] = []
    if (showClients) {
      clients.forEach((client) => {
        let totalFields = 0,
          fieldArea = 0,
          matchingObjs: fieldAreaMapType[] = []
        //For total Fields per client
        if (client?.farms?.length) {
          client?.farms?.map((farm) => {
            totalFields =
              filter(fields, ['farmId', farm.id]).length + totalFields
            matchingObjs = fieldAreaMap.length
              ? filter(fieldAreaMap, ['farmId', farm.id])
              : []
            fieldArea += sumBy(matchingObjs, 'area')
          })
        }
        //End
        _rows.push({
          id: client.id,
          fieldArea:
            `${Math.floor(
              convertAreaToPref(fieldArea, userPrefs?.['units']['area_in']),
            )} ${userPrefs?.['units']['area_in']}` ?? 0,
          name: client.name,
          farmsQuantity: client?.farms?.length ?? 0,
          fieldsQuantity: totalFields ?? 0,
        })
      })
      setRows(_rows)
    } else {
      setRows([])
    }
  }, [clients, fieldAreaMap, fields, farms])

  const editClient = useCallback(() => {
    if (rowSelectionModel.length) {
      const selectedClient = find(rows, ['id', rowSelectionModel[0]])
      dispatch(
        showModal(<OCFFEditClientComponent selectedClient={selectedClient} />),
      )
    }
  }, [rowSelectionModel])

  const deleteClient = useCallback(() => {
    if (rowSelectionModel.length) {
      const selectedClient = find(rows, ['id', rowSelectionModel[0]])
      dispatch(showModal(<OCFFDeleteClient selectedClient={selectedClient} />))
    }
  }, [rowSelectionModel])

  const onClick = noop

  return (
    <>
      <Box px={3} py={4} width={1} className={classes.pageContainer}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('clients')}</h2>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={deleteClient}
              title={t('delete_item', { item: t('client') })}
            >
              <DeleteOutlineOutlinedIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>
            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              title={t('edit_client')}
              disabled={
                rowSelectionModel.length === 0 ||
                (rowSelectionModel.length > 1 && farms.length === 1)
              }
              onClick={editClient}
            >
              <EditIcon
                style={{ color: rowSelectionModel.length ? '#003057' : 'gray' }}
              />
            </IconButton>

            <IconButton
              style={{ display: addModifyClients ? 'block' : 'none' }}
              sx={{ marginLeft: '10px' }}
              onClick={showAddClientPopup}
              title={t('add_client')}
            >
              <AddIcon style={{ color: '#003057' }} />
            </IconButton>
            <TextField
              id="search"
              onChange={(val) => {
                onSearch(val)
              }}
              type="search"
              placeholder="Search"
              hiddenLabel
              sx={{ marginLeft: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box my={1} className={classes.pageContainer}>
          <div className={classes.dataGridContainer}>
            <DataGrid
              checkboxSelection
              onRowSelectionModelChange={(selection: []) => {
                if (selection.length > 1) {
                  const selectionSet = new Set(rowSelectionModel)
                  const result: number[] = selection.filter(
                    (s) => !selectionSet.has(s),
                  )
                  setRowSelectionModel(result)
                } else {
                  setRowSelectionModel(selection)
                }
              }}
              autoPageSize
              rowSelectionModel={rowSelectionModel}
              columnVisibilityModel={columnVisibility}
              onColumnVisibilityModelChange={(model: { name: true }) =>
                setColumnVisibility(model)
              }
              initialState={{
                sorting: {
                  sortModel: [{ field: 'clientName', sort: 'asc' }],
                },
              }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlayFields,
              }}
              rows={rows.filter((client) => filterClients(searchValue, client))}
              columns={columns}
              pageSizeOptions={[10]}
              onRowClick={(params) => onClick(params.row)}
              classes={{ columnHeader: classes.dataGridHeader }}
            />
          </div>
        </Box>
      </Box>
    </>
  )
}
