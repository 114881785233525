import http from 'common/http'

import {
  addHeader,
  editHeaderDetails,
  headersFetched,
  removeHeader,
  headerTypesFetched,
} from 'actions/headers'
import { shouldShowFarmSetupWizard } from '../actions/app'

export const createHeaders = (data) => {
  return (dispatch) => {
    return http.post('/header', data).then((res) => {
      dispatch(addHeader(res.data))
    })
  }
}

export const getHeaders = () => {
  return (dispatch) => {
    return http.get('/header').then((res) => {
      if (Array.isArray(res.data) && res.data.length === 0) {
        dispatch(shouldShowFarmSetupWizard())
      }

      dispatch(headersFetched(res.data))
    })
  }
}

export const editHeader = (id, data) => {
  return (dispatch) => {
    return http.put(`/header/${id}`, data).then((res) => {
      dispatch(editHeaderDetails(res.data))
    })
  }
}

export const deleteHeader = (id) => {
  return (dispatch) => {
    return http.delete(`/header/${id}`).then(() => {
      dispatch(removeHeader(id))
    })
  }
}

export const getHeaderTypes = () => {
  return (dispatch) => {
    return http.get('/header/types').then((res) => {
      dispatch(headerTypesFetched(res.data))
    })
  }
}
