import { Box } from '@mui/material'
import {
  AccountCircle as UserIcon,
  People as OrganizationIcon,
} from '@mui/icons-material'
import { Navigate, Routes, Route } from 'react-router-dom'
import { OrganizationsComponent } from './organizations.component'
import { AgProUsers, UserAccounts } from '../../containers/users'
import { CommonSidebar, CommonSidebarLink } from '../common-sidebar/'
import IntegrationsPanel from 'components/integrations-panel'
import SubscriptionComponent from '../subscription'
import UbjUploadsComponent from '../ubj-uploads'
import BulkExport from 'components/bulk-export'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import {
  FarmtrxIconDiamond,
  FarmtrxIconFile,
  FarmtrxIconUsers,
  FarmtrxIconLinkIntegration,
  FarmtrxIconExportHarvest as CloudDownloadIcon,
} from '@troo/farmtrx-icons'

export function AdminComponent() {
  const permissions = useSelector((state) => state.account.permissions)
  const showOrganizations = permissions.includes('tenant:list')
  const showAgProUsers = permissions.includes('users:write')
  const showAccounts = permissions.includes('tenant:list')
  const defaultRoute = showOrganizations
    ? '/admin/organizations'
    : '/admin/ag-pro-users'

  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', overflowY: 'auto' }} width={1} height={1}>
      <CommonSidebar>
        {showOrganizations && (
          <CommonSidebarLink
            key="organizations"
            exact="true"
            to="/admin/organizations"
            icon={<OrganizationIcon />}
            label={t('available_organizations')}
          />
        )}

        {showOrganizations && <Divider />}

        {showAgProUsers && (
          <CommonSidebarLink
            key="ag-pro-users"
            to="/admin/ag-pro-users"
            icon={<FarmtrxIconUsers />}
            label={t('agpro_users')}
          />
        )}
        <Divider />
        <CommonSidebarLink
          key="subscriptions"
          to="/admin/subscription"
          icon={<FarmtrxIconDiamond />}
          label={t('subscription')}
        />
        <Divider />
        {showAccounts && (
          <CommonSidebarLink
            key="accessible-accounts"
            to="/admin/accessible-accounts"
            icon={<UserIcon />}
            label={t('accessible_accounts')}
          />
        )}
        {showAccounts && <Divider />}
        <CommonSidebarLink
          key="ubj-uploads"
          to="/admin/ubj-uploads"
          icon={<FarmtrxIconFile />}
          label={t('ubj_uploads')}
        />
        <Divider />
        <CommonSidebarLink
          key="integrations"
          to="/admin/integrations"
          icon={<FarmtrxIconLinkIntegration />}
          label={t('integrations')}
        />
        <Divider />
        <CommonSidebarLink
          key="bulk-export"
          to="/admin/bulk-export"
          icon={<CloudDownloadIcon />}
          label={t('bulk_export')}
        />
        <Divider />
      </CommonSidebar>

      <Box component="main" sx={{ flexGrow: 1, p: 6 }}>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to={defaultRoute} replace={true} />}
          />
          {showOrganizations && (
            <Route path="/organizations" element={<OrganizationsComponent />} />
          )}
          <Route path="/accessible-accounts" element={<UserAccounts />} />
          <Route path="/ag-pro-users" element={<AgProUsers />} />
          <Route path="/integrations" element={<IntegrationsPanel />} />
          <Route path="/subscription" element={<SubscriptionComponent />} />
          <Route path="/ubj-uploads" element={<UbjUploadsComponent />} />
          <Route path="/bulk-export" element={<BulkExport />} />
        </Routes>
      </Box>
    </Box>
  )
}
