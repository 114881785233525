import http from 'common/http'
import {
  shouldcreateUserAccount,
  shouldEditUserAccount,
  shouldShowFarmSetupWizard,
} from 'actions/app'
import {
  userFetched,
  updateClient,
  contactInfoFetched,
  organizationsFetched,
  userListFetched,
} from 'actions/user'
import { getPartner } from 'common/misc'

export const fetchUserAccount = () => {
  return (dispatch) => {
    return http
      .get('/user')
      .then((res) => {
        dispatch(userFetched(res.data))
        if (res.data?.eusaDate == null) {
          dispatch(shouldShowFarmSetupWizard())
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          dispatch(shouldcreateUserAccount())
        }
      })
  }
}

export const createUserAccount = (data) => {
  return (dispatch) => {
    return http
      .post('/user', data)
      .then(() => {})
      .catch((err) => {
        if (err.response && err.response.status) {
          dispatch(shouldcreateUserAccount())
        }
      })
  }
}

export const fetchOrganizations = () => {
  return (dispatch) => {
    return http.get('/partner').then((res) => {
      dispatch(organizationsFetched(res.data))
    })
  }
}

export const switchClient = (tenantId) => {
  return () => {
    return http.get('/tenant/switch/' + tenantId).then((res) => {
      localStorage.setItem('impersonate', true)
      const data = JSON.parse(
        localStorage.getItem(
          `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        ),
      )
      localStorage.setItem('otok', data.body.access_token)
      const updateData = update(data, {
        body: { access_token: { $set: res.data } },
      })
      localStorage.setItem(
        `@@auth0spajs@@::${AUTH_CID}::https://api.farmtrx.com::openid profile email offline_access`,
        JSON.stringify(updateData),
      )
      window.location.href = '/'
    })
  }
}

export const editUserAccount = (data) => {
  return (dispatch) => {
    return http
      .put('/user', data)
      .then((res) => {
        dispatch(shouldEditUserAccount(res.data))
      })
      .catch(() => {})
  }
}

export const changeAccountStatus = (tenantId, status) => {
  return (dispatch) => {
    return http
      .post('partner/change-tenant-status', {
        tenantId,
        accountEnabled: status,
      })
      .then((res) => {
        dispatch(updateClient(res.data))
      })
  }
}

export const getPartnerContactInfo = () => {
  const partner = getPartner()
  return (dispatch) => {
    return http.get(`partner/contact-details/${partner}`).then((res) => {
      dispatch(contactInfoFetched(res.data))
    })
  }
}

export const getUserList = () => {
  return (dispatch) => {
    return http.get(`/user/list`).then((res) => {
      dispatch(userListFetched(res.data))
    })
  }
}
