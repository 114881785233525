import { applyMiddleware, createStore, compose } from 'redux'
import reducers from './state'
import { middlewares } from './redux'

const store = createStore(
  reducers,
  {},
  compose(applyMiddleware(...middlewares)),
)

export default store
