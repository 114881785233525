import { get, isNil } from 'lodash-es'
import * as c from 'common/c'
import Busy from 'components/busy'
import { pointInfoClr } from 'actions/map'
import InfoList from './info-list'
import { useEffect } from 'react'
export default function PointInfoView() {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const data = useSelector((state) => get(state, 'map.pointInfoRes'))

  useEffect(() => () => dispatch(pointInfoClr()), [])

  return (
    <>
      {data === c.UNDEFINED ? (
        <Busy />
      ) : isNil(data) ? (
        <div>{t('no_data')}</div>
      ) : (
        <InfoList id={'pointInfo'} data={data} />
      )}
    </>
  )
}
