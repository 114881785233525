import styled from 'styled-components'
import { switchToSingle } from 'actions/app'
import { SingleScreen } from '@troo/farmtrx-icons'

export default function SwitchSingle() {
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(switchToSingle())
  })

  return (
    <StyledLink href="#" onClick={onClick}>
      <SingleScreen />
    </StyledLink>
  )
}

const StyledLink = styled.a`
  position: absolute;
  height: 27px;
  top: 10px;
  right: 20px;
  z-index: 100;
  background: white;
  padding: 4px;
  border-radius: 4px;
`
