import { useCallback, useEffect, useState } from 'react'
import React from 'react'
import useMapCoordinate from 'hooks/use-click-coordinate'
import { pointInfoReq } from 'actions/map'
import PointInfoView from 'components/point-info-view'
import Overlay from 'ol/Overlay'
import useMap from 'hooks/use-map'
import * as c from 'common/c'
import { get } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PopupInternal from 'components/popup/popup-internal'

export default function PointInfo() {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [overlay, setOverlay] = useState<Overlay | null | undefined>(null)
  const map = useMap()
  const data = useSelector((state) => get(state, 'map.pointInfoRes'))

  const coordinate = useMapCoordinate()

  useEffect(() => {
    dispatch(pointInfoReq(coordinate))
  }, [coordinate])

  const onClose = useCallback(() => {
    //position is map coordinates, if undefined hide the overlay.
    //actually removing the overlay unless this component unmounts is bad
    // and breaks showing new point info until it unmounts and remounts.
    if (overlay != null) {
      overlay.setPosition(undefined)
    }
  }, [overlay, data])

  //We never call remove overlay because that breaks the popup in the future
  useEffect(() => {
    if (map) {
      const currentOverlays = map.getOverlays().getArray()
      //cleanup overlays otherwise we end up with a point info dialog in the top left
      for (let o in currentOverlays) {
        if (
          currentOverlays[o] != overlay &&
          currentOverlays[o].id == 'point-info'
        ) {
          map.removeOverlay(currentOverlays[o])
        }
      }
      if (data != c.UNDEFINED) {
        const el = document.getElementById('point-info-popup')
        if (overlay == null && el) {
          const _overlay = new Overlay({
            element: el,
            position: coordinate,
            offset: [-254, -72],
            id: 'point-info',
          })
          setOverlay(_overlay)
        } else if (overlay != null) {
          overlay.setPosition(coordinate)
        }
      }
    }
  }, [map, coordinate, overlay, data])

  useEffect(() => {
    if (map && overlay) {
      map.addOverlay(overlay)
    }
  }, [map, overlay])

  return (
    <div>
      <div id="point-info-popup">
        {overlay != null ? (
          <PopupInternal
            key={'point-info-popup'}
            onClose={onClose}
            title={t('point_data')}
            pixel={{ x: 5, y: 20 }}
          >
            <PointInfoView />
          </PopupInternal>
        ) : null}
      </div>
    </div>
  )
}
