import styled from 'styled-components'
import useOnClickOutside from 'hooks/click-outside'
import useWindowSize from 'hooks/use-window-size'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  colors,
  pointer,
  defaultShadow,
  BlankLink,
  List,
} from 'components/foundation'

const DEFAULT_POSITION = {
  top: 0,
  left: 0,
}

export default function ContextMenu(props) {
  const { imgUrl, entries } = props

  const [showPopup, setShowPopup] = useState(false)
  const [position, setPosition] = useState(DEFAULT_POSITION)

  const size = useWindowSize()

  const refPopup = useRef()

  useEffect(() => closePopup(), [size])

  const onClick = useCallback(
    (e) => {
      e.preventDefault()

      function adjustPosition(pos, rect) {
        const { top, left } = rect
        switch (pos) {
          case 'br':
            return {
              top: top + 25,
              left,
            }
          case 'bl':
            return {
              top: top + 25,
              left: left - 150,
            }
        }
      }

      setPosition(adjustPosition(props.pos, e.target.getBoundingClientRect()))
      setShowPopup(true)
    },
    [props.pos],
  )

  const onEntryClicked = useCallback(
    (cb) => {
      closePopup()
      cb()
    },
    [entries],
  )

  const closePopup = useCallback(() => {
    setShowPopup(false)
  }, [])

  useOnClickOutside(refPopup, closePopup)

  const getAction = useCallback(
    (payload) => {
      const { name, action } = payload

      if (React.isValidElement(action)) {
        return action
      } else {
        return (
          <BlankLink onClick={() => onEntryClicked(action)}>{name}</BlankLink>
        )
      }
    },
    [entries],
  )

  // build popup
  const popup = (
    <StyledPopup key="popup" style={position} ref={refPopup}>
      {props.head ? <StyledHeader>{props.head}</StyledHeader> : null}
      <StyledList>
        {entries.map((e, idx) => (
          <StyledItem key={idx}>{getAction(e)}</StyledItem>
        ))}
      </StyledList>
    </StyledPopup>
  )

  let displayEl
  if (imgUrl) {
    displayEl = <StyledImage src={imgUrl} />
  } else {
    displayEl = <FontAwesomeIcon icon={props.icon} color={props.color} />
  }

  return (
    <>
      <StyledRoot key="start" onClick={onClick}>
        {displayEl}
      </StyledRoot>
      {showPopup ? popup : null}
    </>
  )
}

ContextMenu.propTypes = {
  head: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  imgUrl: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string,
  pos: PropTypes.oneOf(['br', 'bl']),
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
      disabled: PropTypes.boolean,
    }),
  ).isRequired,
}

ContextMenu.defaultProps = {
  icon: 'ellipsis-h',
  pos: 'br',
  color: 'black',
}

const StyledImage = styled.img`
  border-radius: 15px;
  height: 25px;
  margin-top: -4px;
`

const StyledHeader = styled.span`
  display: block;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border-bottom: 1px dashed ${colors.greyLighter};
`

const StyledRoot = styled.div`
  ${pointer}
`

const StyledPopup = styled.div`
  position: fixed;
  min-width: 150px;
  background: white;
  text-align: left;
  border: 1px solid ${colors.greyLighter};
  border-radius: 4px;
  padding: 8px;
  box-shadow: ${defaultShadow};
  z-index: 99;
`

const StyledList = styled(List)``

const StyledItem = styled(List.Item)`
  ${pointer}
`
