import styled from 'styled-components'

export default function ModalHeader(props) {
  return <StyledHeader>{props.children}</StyledHeader>
}

const StyledHeader = styled.h1`
  color: #212529;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 1em 0;
  text-align: center;
`
