export default function useOnClickOverlay(ref, handler, ignore = undefined) {
  useEffect(() => {
    const listener = (event) => {
      // this will close the dialog when the mouse is clicked outside of the dialog
      if (
        typeof event.target.className === 'string' &&
        event.target.className.includes('overlay')
      ) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return function cleanup() {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, ignore])
}
