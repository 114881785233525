import { Box, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import colors from 'common/colors'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../theme'

const radioTheme = {
  ...theme,
  ...{
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: colors.brand,
          },
        },
      },
    },
  },
}

export default function CustomRadioButton({
  options,
  setSelectedOption,
  value,
}) {
  //const { options } = props

  //const [value, setValue] = useState(options[0]?.defaultSelection)

  const handleOnChange = useCallback((option) => {
    //setValue(option)
    setSelectedOption(option.target.value)
  })

  return (
    <ThemeProvider theme={radioTheme}>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <RadioGroup value={value} onChange={handleOnChange} color="error">
          {options.map((item) => {
            {
              return (
                <FormControlLabel
                  color="primary"
                  key={item.label}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              )
            }
          })}
        </RadioGroup>
      </Box>
    </ThemeProvider>
  )
}
