import useMap from './use-map'
import { isNil } from 'lodash-es'

export default function useFeaturesAtPixel() {
  const map = useMap()

  const [features, setFeatures] = useState([])

  // init onClick
  useEffect(() => {
    if (!map) return

    function onClick(e) {
      if (!isNil(e.pixel)) {
        const _features = []
        map.forEachFeatureAtPixel(e.pixel, function (feature) {
          _features.push(feature)
        })

        setFeatures(_features)
      }
    }

    map.on('click', onClick)

    return () => {
      map.un('click', onClick)
    }
  }, [map])

  return features
}
