import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import App from 'containers/app'
import HttpHandler from 'components/http-handler'
import store from 'store'
import 'common/init'
import { getPartner } from 'common/misc'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { StyledEngineProvider } from '@mui/material/styles'

const entryEl = (
  <Auth0Provider
    domain={AUTH_DOMAIN}
    clientId={AUTH_CID}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    partner={getPartner()}
    audience="https://api.farmtrx.com"
  >
    <Provider store={store}>
      <HttpHandler store={store} baseURL={FARM_API_EP} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </Auth0Provider>
)

ReactDOM.render(entryEl, document.getElementById('root-app'))
