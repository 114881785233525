import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'
declare module 'state/integrations' {
  export type TenantIntegration = {
    id?: number
    customerId: string
    farmId?: string
    name: string
    integrationId: number
    status: string
  }

  export type IntegrationData = {
    id: number
    authType: string
    authorizationUrl: string
    baseUrl: string
    clientId: string
    name: string
    scopes: string[]
    tokenUrl: string
    createdAt: string | null
    updatedAt: string | null
  }

  export type IntegrationUrls = {
    controllers: string
    files: string
    managePermissions: string
    self: string
    sendFileToMachine: string
    transferableFiles: string
    uploadFile: string
    clients: string
    farms: string
    fields: string
    boundaries: string
  } & { [key: string]: string }

  export type IntegrationErrors = {
    message: string
    name: string
    status: number
  }

  export type IntegrationRef = { [key: string]: IntegrationUrls }
  export type integrations = {
    tenantIntegrations: TenantIntegration[]
    availableIntegrations: IntegrationData[]
    integrationUrls: {
      [key: string]: {
        IntegrationRef
        authenticated: boolean
        status: number
        availableConnections?: IntegrationUrls[]
      }
    }
    clientSync: String
    farmSync: String
    fieldSync: String
    harvestSync: String
    syncAll: String
    integrationSyncErrors: {
      [key: string]: IntegrationErrors[]
    }
  }
}
const initialState = {
  tenantIntegrations: [],
  availableIntegrations: [],
  integrationUrls: {},
  clientSync: c.NOT_STARTED,
  farmSync: c.NOT_STARTED,
  fieldSync: c.NOT_STARTED,
  syncAll: c.NOT_STARTED,
  harvestSync: c.NOT_STARTED,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.TENANT_INTEGRATION_ADDED: {
      //@ts-ignore
      return update(state, {
        tenantIntegrations: { $unshift: [action.payload] },
      })
    }
    case c.TENANT_INTEGRATION_UPDATED: {
      const index = findIndex(
        get(state, 'tenantIntegrations', []),
        (tenantIntegration: TenantIntegration) =>
          tenantIntegration.id === action.payload.id,
      )
      if (index > -1) {
        //@ts-ignore
        return update(state, {
          tenantIntegrations: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }
    case c.TENANT_INTEGRATIONS_FETCHED: {
      //@ts-ignore
      return update(state, {
        tenantIntegrations: { $set: action.payload },
      })
    }
    case c.INTEGRATIONS_FETCHED: {
      //@ts-ignore
      return update(state, {
        availableIntegrations: { $set: action.payload },
      })
    }

    case c.SET_INTEGRATION_URLS: {
      //@ts-ignore
      return update(state, { integrationUrls: { $merge: action.payload } })
    }

    case c.SET_CLIENT_SYNC_STATE: {
      //@ts-ignore
      return update(state, { clientSync: { $set: action.payload } })
    }

    case c.SET_FARM_SYNC_STATE: {
      //@ts-ignore
      return update(state, { farmSync: { $set: action.payload } })
    }

    case c.SET_FIELD_SYNC_STATE: {
      //@ts-ignore
      return update(state, { fieldSync: { $set: action.payload } })
    }

    case c.SET_ALL_SYNC_STATE: {
      //@ts-ignore
      return update(state, {
        syncAll: { $set: action.payload },
      })
    }

    case c.SET_HARVEST_SYNC_STATE: {
      //@ts-ignore
      return update(state, { harvestSync: { $set: action.payload } })
    }

    case c.SET_INTEGRATION_SYNC_ERRORS: {
      const syncData = action.payload
      if (syncData?.clientErrors.length || syncData?.farmErrors.length) {
        //@ts-ignore
        return update(state, {
          integrationSyncErrors: { $set: action.payload },
          syncAll: { $set: c.ERROR },
        })
      }
    }

    default:
      return state
  }
}
