export const AJAX_START = 'ajax-start'
export const AJAX_STOP = 'ajax-stop'

export const MIN_SCREEN_WIDTH = 1000
export const MIN_SCREEN_HEIGHT = 720
//The maximum allowable field area in square meters, openLayers
//getArea function returns the area in square meters as well.
//This is roughly equivalent to 1500ac or 607ha.
export const MAX_FIELD_SIZE_SQM = 6070285
export const WARNING_CLEARED_KEY = 'screen-warning-dismissed'

export const TENANT_FETCHED = 'tenant-fetched'
export const EDIT_TENANT_BOOL = 'tenant-to-edit'
export const SET_LOCAL_TENANT_FORM_DATA = 'set-local-tenant-data'
export const EUSA_ACCEPTED = 'eusa-accepted'
export const EDIT_FIELD_NAME = 'edit-field-name'

export const EDIT_USER_ACCOUNT = 'edit-user-account'
export const USER_FETCHED = 'user-account-fetched'
export const USER_LIST_FETCHED = 'user-list-fetched'
export const CLIENT_UPDATED = 'client-updated'
export const CONTACT_INFO_FETCHED = 'partner-contact-into-fetched'

export const FIELD_FETCHED = 'field-fetched'
export const FIELD_FETCHED_A = 'field-fetched-a'
export const FIELD_FETCHED_B = 'field-fetched-b'
export const FIELDS_FETCHED = 'fields-fetched'
export const HARVESTED_FIELDS_FETCHED = 'harvested-fields-fetched'
export const ADD_FIELD_FEATURE = 'add-field-feature'
export const FEATURES_FETCHED = 'features-fetched'
export const CLEAR_FIELD_FEATURES = 'clear-field-features'
export const SET_FIELD_FEATURES = 'set-field-features'
export const SET_EDIT_FIELD = 'set-edit-field'
export const EDIT_FIELD = 'edit-field'
export const DELETE_FIELD = 'delete-field'
export const FIELDS_IMPORTED = 'fields-imported'
export const DELETE_FIELDS = 'delete-fields'
export const UPDATE_HARVEST_BY_ID = 'update-harvest-by-id'
export const UPDATE_HARVEST_HEADER = 'update-harvest-header'
export const HARVEST_HEADERS_FETCHED = 'harvest-headers-fetched'
export const HARVESTS_BY_YEAR_FETCHED = 'harvests-by-year-fetched'

export const PREFERENCE_FETCHED = 'preference-fetched'
export const EDIT_PREFERENCE_BOOL = 'preference-to-edit'
export const PREFERENCE_OPTIONS_FETCHED = 'preference-options-fetched'
export const SET_NEW_PREFERENCE = 'set-new-preference'
export const OPTIONS_FETCHED = 'preference-options-fetched'
export const SUBSCRIPTIONS_FETCHED = 'subscriptions-fetched'
export const SUBSCRIPTION_CREATED = 'subscription-created'
export const SUBSCRIPTION_PERIOD_CREATED = 'subscription-period-created'
export const HARVESTED_AREA_FETCHED = 'harvested-area-fetched'
export const SUBSCRIPTION_PAYMENT_LINK_FETCHED =
  'subscription-payment-link-fetched'
export const PAYMENT_INFO_FETCHED = 'payment-info-fetched'
export const CURRENT_SUBSCRIPTION_FETCHED = 'current-subscription-fetched'
export const SUBSCRIPTIONS_POPUP_NOTIFICATION =
  'subscription-renewal-popup-notification'
export const SUBSCRIPTION_PAYMENT_FETCHED = 'subscription-payment-fetched'
export const ACCOUNT_DISABLED_POPUP_NOTIFICATION =
  'account-disabled-popup-notification'

export const FARM_CREATED = 'farm-created'
export const FARMS_FETCHED = 'farms-fetched'
export const FARM_UPDATED = 'farm-updated'
export const FARM_DELETED = 'farm-deleted'
export const FARMS_UPDATED = 'farms-updated'
export const FARM_PREFERENCES_FETCHED = 'farm-preferences-fetched'
export const FARMS_SELECTED = 'farms-selected'

export const FARM_REFERENCE_UPDATED = 'farm-reference-updated'
export const FARM_REFERENCES_UPDATED = 'farm-references-updated'
export const FARM_REFERENCE_DELETED = 'farm-reference-deleted'
export const FARM_REFERENCE_ADDED = 'farm-reference-added'

export const FARMSETTINGS_EMPTY_ITEM_ADDED = 'farmsettings-empty-item-added'
export const FARMSETTINGS_EMPTY_ITEM_DELETED = 'farmsettings-empty-item-deleted'

export const FARMSETTINGS_COMBINE_BRAND_ADDED =
  'farmsettings-combine-brand-added'
export const FARMSETTINGS_COMBINE_BRAND_UPDATED =
  'farmsettings-combine-brand-updated'
export const FARMSETTINGS_COMBINE_BRAND_DELETED =
  'farmsettings-combine-brand-deleted'
export const FARMSETTINGS_COMBINE_BRAND_FETCHED =
  'farmsettings-combine-brand-fetched'
export const SET_LOCAL_CROP_VARIETIES = 'set-local-crop-varieties'

export const SET_IMPERSONATE = 'set-impersonate'
export const SET_TOKEN = 'set-token'
export const SET_CUSTOM_CLAIMS = 'set-custom-claims'
export const FT_ADMIN_CLAIM = 'https://api.farmtrx.com/is_ft_admin'
export const FT_PARTNER_CLAIM = 'https://api.farmtrx.com/is_partner_admin'

export const EQUIPMENT_FETCHED = 'equipment-fetched'
export const ADD_EQUIPMENT = 'add-equipment'
export const EDIT_EQUIPMENT = 'edit-equipment'
export const DELETE_EQUIPMENT = 'delete-equipment'

export const HARDWARE_FETCHED = 'hardware-fetched'
export const ACCESSIBLE_ACCOUNTS_FETCHED = 'accessible-accounts-fetched'
export const INVITED_USERS_FETCHED = 'invited-users-fetched'

export const POINT_INFO_REQ = 'point-info-req'
export const POINT_INFO_RES = 'point-info-res'
export const POINT_INFO_CLR = 'point-info-clr'
export const AREA_INFO_REQ = 'area-info-req'
export const AREA_INFO_RES = 'area-info-res'
export const AREA_INFO_CLR = 'area-info-clr'

export const CROP_VARIETIES_NAMES_FETCHED = 'crop-varieties-names-fetched'
export const CROPS_WITH_VARIETIES_FETCHED = 'crops-with-varieties-fetched'
export const CROP_VARIETY_ADDED = 'crop-variety-added'
export const TENANT_CROP_VARIETIES_FETCHED = 'tenant-crop-varieties-fetched'
export const CROP_CONSTANTS_FETCHED = 'crop-constants-fetched'
export const DELETE_CROP_VARIETY = 'delete-crop-variety'
export const UPDATE_CROP_VARIETY = 'update-crop-variety'
export const DELETE_EMPTY_CROP_VARIETY = 'delete-empty-crop-variety'

export const HEADERS_FETCHED = 'headers-fetched'
export const ADD_HEADER = 'add-header'
export const EDIT_HEADER = 'edit-header'
export const DELETE_HEADER = 'delete-header'
export const HEADER_TYPES_FETCHED = 'header-types-fetched'

export const SELECT_FIELD_A = 'select-field-a'
export const SELECT_FIELD_B = 'select-field-b'

export const FEATURES_A_FETCHED = 'features-a-fetched'
export const FEATURES_B_FETCHED = 'features-b-fetched'

export const CLIENT_CREATED = 'client-created'
export const CLIENTS_FETCHED = 'clients-fetched'
export const CLIENT_EDITED = 'client-edited'
export const CLIENT_DELETED = 'client-deleted'
export const CLIENT_SELECTED = 'client-selected'

export const ORGANIZATIONS_FETCHED = 'organizations-fetched'

export const HAS_TOKEN = 'has-token'
export const PERMISSIONS = 'permissions'
export const HAS_TOKEN_WITH_PERMISSIONS_RETRIES =
  'has-token-with-permissions-retries'

export const AUTH_DATA = 'auth-data'

export const FORM_DIRTY = 'form-dirty'
export const FORM_DESTROY = 'form-destroy'

export const STATE_SET = 'state-set'

export const ADD_EVENT = 'add-event'
export const CLEAR_EVENT = 'clear-event'

export const APP_STATE = Object.freeze({
  READY: Symbol('ready'),
  ACCOUNT_CREATE: Symbol('account-create'),
  UNDEFINED: Symbol('undefined'),
  FIELD_CREATE: Symbol('field-create'),
  USER_CREATE: Symbol('user-create'),
  FARM_SETUP_WIZARD: Symbol('farm-setup-wizard'),
  SHOW_DASHBOARD: Symbol('show-dashboard'),
  ACCOUNT_DISABLED: Symbol('account-disabled'),
})

export const MODAL_SHOW = 'modal-show'
export const MODAL_CLOSE = 'modal-close'
export const POPUP_SHOW = 'popup-show'
export const POPUP_CLOSE = 'popup-close'

export const MAP_INIT = 'map-init'
export const MAP_CENTER = 'map-center'
export const MAP_ZOOM = 'map-zoom'
export const MAP_EDIT = 'map-edit'
export const BOUNDARY_CHANGES_MADE = 'boundary-changed'
export const DRAW_BOUNDARY = 'add-boundary'
export const EDIT_BOUNDARY = 'edit-boundary'
export const DRAW_HOLE = 'draw-hole'
export const SET_DRAW_TYPE = 'set-draw-type'
export const SELECTED_FEATURES = 'selected-features'
export const SET_NEW_FEATURE = 'set-new-feature'
export const SET_DEFAULT_EXTENT = 'set-default-extent'
export const SET_FEATURES_OVERVIEW = 'set-features-overview'
export const UPDATE_FEATURES_OVERVIEW = 'update-features-overview'
export const UPDATE_COUNTRY_EXTENT = 'update-country-extent'
export const SET_LAYER_LEGEND = 'set-layer-legend'
export const SET_LAYER_LEGEND_A = 'set-layer-legend-a'
export const SET_LAYER_LEGEND_B = 'set-layer-legend-b'
export const REMOVE_LAYER_LEGEND = 'remove-layer-legend'
export const REMOVE_LAYER_LEGEND_A = 'remove-layer-legend-a'
export const REMOVE_LAYER_LEGEND_B = 'remove-layer-legend-b'
export const REMOVE_LAYER_LEGENDS = 'remove-layer-legends'
export const REMOVE_LAYER_LEGENDS_A = 'remove-layer-legends-a'
export const REMOVE_LAYER_LEGENDS_B = 'remove-layer-legends-b'
export const SET_LAYER = 'show-layer'
export const SET_LAYER_A = 'show-layer-a'
export const SET_LAYER_B = 'show-layer-b'
export const REMOVE_LAYER_A = 'remove-layer-a'
export const REMOVE_LAYER_B = 'remove-layer-b'

export const REMOVE_LAYER = 'remove-layer'

export const ADD_HARVEST_FEATURE = 'add-harvest-feature'

export const OL_PROP_TYPE = 'trooType'
export const FTYPE_BOUNDARY = 'boundary'
export const FTYPE_NOTE = 'note'
export const SET_HARVEST_FEATURES = 'set-harvest-features'
export const HARVESTS_FETCHED = 'harvest-fetched'
export const FIELDS_HARVESTED_A_FETCHED = 'fields-harvested-a'
export const FIELDS_HARVESTED_B_FETCHED = 'fields-harvested-b'
export const HARVESTS_A_FETCHED = 'harvest-a-fetched'
export const HARVESTS_B_FETCHED = 'harvest-b-fetched'
export const SET_LAYERS = 'set-layers'
export const SET_LAYERS_A = 'set-layers-a'
export const SET_LAYERS_B = 'set-layers-b'
export const RESET_LAYERS = 'reset-layers'
export const RESET_LAYERS_A = 'reset-layers-a'
export const RESET_LAYERS_B = 'reset-layers-b'
export const LAYER_INFO_FETCHED = 'layer-info-fetched'
export const LAYER_INFO_A_FETCHED = 'layer-info-a-fetched'
export const LAYER_INFO_B_FETCHED = 'layer-info-b-fetched'
export const SINGLE_HARVEST_FETCHED = 'single-harvest-fetched'
export const SINGLE_HARVEST_A_FETCHED = 'single-harvest-a-fetched'
export const SINGLE_HARVEST_B_FETCHED = 'single-harvest-b-fetched'
export const HARVEST_YEARS_FETCHED = 'harvest-years-fetched'
export const SET_CROP = 'set-crop'
export const SET_YEAR = 'set-year'
export const SET_YEAR_A = 'set-year-a'
export const SET_YEAR_B = 'set-year-b'
export const DOWNLOADING_LAYER = 'downloading-layer'

export const UNDEFINED = Symbol('undefined')

export const DEFAULT_ZOOM = 4
// export const DEFAULT_ZOOM = 14
export const DEFAULT_CENTER = [-103.89197610986974, 57.31381437677578]
// export const DEFAULT_CENTER = [-107.942766192066, 53.2490982462867]
// export const DEFAULT_CENTER = [-107.930852360064, 53.25618314636]

export const SPLIT_VIEW = 'split-view'
export const SPLIT_SINGLE = 'split-single'

export const TENANT_INTEGRATION_ADDED = 'tenant-integration-added'
export const TENANT_INTEGRATION_UPDATED = 'tenant-integration-updated'
export const TENANT_INTEGRATIONS_FETCHED = 'tenant-integrations-fetched'
export const INTEGRATIONS_FETCHED = 'integrations-fetched'
export const SET_INTEGRATION_SYNC_ERRORS = 'set-integration-sync-errors'
export const ENABLED = 'enabled'
export const DISABLED = 'disabled'
export const IMPORT = 'import'
export const EXPORT = 'export'
export const SYNC = 'sync'
export const NOT_STARTED = 'not-started'
export const IN_PROGRESS = 'in-progress'
export const ERROR = 'error'
export const SET_CLIENT_SYNC_STATE = 'set-client-sync-state'
export const SET_FARM_SYNC_STATE = 'set-farm-sync-state'
export const SET_FIELD_SYNC_STATE = 'set-field-sync-state'
export const FIELD_SYNC_JOB_TYPE = 'field_sync'
export const SET_HARVEST_SYNC_STATE = 'set-harvest-sync-state'
export const SET_ALL_SYNC_STATE = 'set-all-sync-state'
export const DONE = 'done'
export const SET_INTEGRATION_URLS = 'set-integration-urls'
export const JD_OPERATIONS_CENTER = 'operations_center'
export const CREATE_JOB = 'create-job'
export const REMOVE_JOB = 'remove-job'
export const UPDATE_JOB = 'update-job'
export const STARTED = 'started'

export const UBJ_UPLOADS_FETCHED = 'ubj-uploads-fetched'

import {
  AccessTime as ProcessingIcon,
  CheckCircleOutlined as CompleteIcon,
  ErrorOutlineOutlined as ErrorIcon,
} from '@mui/icons-material'

import { t } from 'i18next'

export const getHarvestStatusIcon = (status) => {
  switch (status) {
    case 'LAYER_PROCESSING_COMPLETE':
      return (
        <CompleteIcon
          size="small"
          style={{ color: '#01A07E', height: '0.7em' }}
          title={t('layer_processing_complete')}
          titleAccess={t('layer_processing_complete')}
        />
      )
    case 'LAYER_PROCESSING_STARTED':
      return (
        <ProcessingIcon
          size="small"
          style={{ color: '#F8981D', height: '0.7em' }}
          title={t('layer_processing_started')}
          titleAccess={t('layer_processing_started')}
        />
      )
    case 'DATA_RECEIVED':
      return (
        <ProcessingIcon
          size="small"
          style={{ color: '#F8981D', height: '0.7em' }}
          title={t('data_received')}
          titleAccess={t('data_received')}
        />
      )
    case 'DATA_RECEIVED_ERROR':
      return (
        <ErrorIcon
          size="small"
          style={{ color: '#B2023B', height: '0.7em' }}
          title={t('data_received_error')}
          titleAccess={t('data_received_error')}
        />
      )
    case 'LAYER_PROCESSING_NO_LAYERS':
      return (
        <ErrorIcon
          size="small"
          style={{ color: '#B2023B', height: '0.7em' }}
          title={t('layer_processing_no_layers')}
          titleAccess={t('layer_processing_no_layers')}
        />
      )
    case 'LAYER_PROCESSING_ERROR':
      return (
        <ErrorIcon
          size="small"
          style={{ color: '#B2023B', height: '0.7em' }}
          title={t('layer_processing_error')}
          titleAccess={t('layer_processing_error')}
        />
      )
    case 'LP_INVALID_CALIBRATION':
      return (
        <CompleteIcon
          size="small"
          style={{ color: '#01A07E', height: '0.7em' }}
          title={t('lp_invalid_calibration')}
          titleAccess={t('lp_invalid_calibration')}
        />
      )
    case 'REFRESH_REQUESTED':
      return (
        <ProcessingIcon
          size="small"
          style={{ color: '#F8981D', height: '0.7em' }}
          title={t('refresh_requested')}
          titleAccess={t('refresh_requested')}
        />
      )

    default:
      return (
        <ErrorIcon
          size="small"
          style={{ color: '#B2023B', height: '0.7em' }}
          title={t('error')}
          titleAccess={t('error')}
        />
      )
  }
}
