import * as c from 'common/c'

const initialState = { brands: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case c.FARMSETTINGS_COMBINE_BRAND_ADDED: {
      return update(state, { brands: { $push: [action.payload] } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_UPDATED: {
      const { data } = action.payload
      let index = state['brands'].findIndex((p) => p.id == data['id'])
      return update(state, { ['brands']: { [index]: { $set: data } } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_DELETED: {
      let index = state['brands'].findIndex((p) => p.id === action.payload.id)
      return update(state, { ['brands']: { $splice: [[index, 1]] } })
    }

    case c.FARMSETTINGS_COMBINE_BRAND_FETCHED: {
      return update(state, { brands: { $set: action.payload } })
    }

    default:
      return state
  }
}
