import styled from 'styled-components'
import { colors } from 'components/foundation'

export default function InfoLine(props) {
  const { label, icon, value } = props

  return (
    <StyledRoot>
      <StyledLabel>{label}:</StyledLabel>
      <StyledIcon>{icon}</StyledIcon>
      <StyledValue>{value}</StyledValue>
    </StyledRoot>
  )
}

const StyledRoot = styled.div`
  display: flex;
  justify-content: space-between;
  width: clamp(60px, ${((window.screen.width + window.screen.height) / 2) * 0.15}px, 160px);
`

const StyledLabel = styled.div`
  color: ${colors.brand};
  font-size: clamp(10px, ${((window.screen.width + window.screen.height) / 2) * 0.01}px, 10px);
`
const StyledIcon = styled.div`
  height:15px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 15px;

 svg {
    width: 0.6em;
    height:0.6em;
    color: ${colors.brand};
  }
`
const StyledValue = styled.div`
  color: ${colors.brand};
  font-size: clamp(10px, ${((window.screen.width + window.screen.height) / 2) * 0.01}px, 10px);
  font-weight: 600;
`
