import * as c from 'common/c'

export const subscriptionsFetched = (data) => {
  return {
    type: c.SUBSCRIPTIONS_FETCHED,
    payload: data,
  }
}

export const subscriptionCreated = (data) => {
  return {
    type: c.SUBSCRIPTION_CREATED,
    payload: data,
  }
}

export const subscriptionPeriodCreated = (data) => {
  return {
    type: c.SUBSCRIPTION_PERIOD_CREATED,
    payload: data,
  }
}

export const harvestedAreaFetched = (data) => {
  return {
    type: c.HARVESTED_AREA_FETCHED,
    payload: data,
  }
}

export const subscriptionRenewalPaymentLinkFetched = (
  paymentLink,
  startDate,
  endDate,
) => {
  return {
    type: c.SUBSCRIPTION_PAYMENT_LINK_FETCHED,
    payload: { paymentLink, startDate, endDate },
  }
}

export const paymentInfoFetched = (id, payload) => {
  return {
    type: c.PAYMENT_INFO_FETCHED,
    payload: {
      id,
      payload,
    },
  }
}

export const currentSubscriptionFetched = (payload) => {
  return {
    type: c.CURRENT_SUBSCRIPTION_FETCHED,
    payload,
  }
}

export const subscriptionPaymentFetched = (payload) => {
  return {
    type: c.SUBSCRIPTION_PAYMENT_FETCHED,
    payload,
  }
}
