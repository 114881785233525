import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { isEmpty } from 'lodash-es'
import useMap from 'hooks/use-map'
import { defaultStyle, modifyStyles, selectStyle } from 'common/ol/styles'
import { useEffect } from 'react'

export default function MapBoundaryLayer(props) {
  const { features, modify } = props
  const map = useMap()

  const source = new VectorSource({
    features,
    useSpatialIndex: false,
  })
  const [layer, setLayer] = useState()

  useEffect(() => {
    if (map && !isEmpty(features)) {
      const _layer = new VectorLayer({
        className: 'boundary-layer',
        updateWhileAnimating: false,
        renderBuffer: 0,
        zIndex: 5,
        style: selectStyle,
      })
      setLayer(_layer)
    }
  }, [map, features])

  useEffect(() => {
    if (layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
      setLayer(undefined)
    }
  }, [map, layer])

  useEffect(() => {
    if (layer && source) {
      source.addFeatures(features)
      layer.setSource(source)
    }
  }, [layer, source])

  useEffect(() => {
    if (layer && map) {
      const style = modify ? modifyStyles : defaultStyle
      layer.setStyle(style)
      setLayer(layer)
    }
  }, [modify, layer, map])

  return null
}
