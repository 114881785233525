import { FarmtrxIconUploadNew } from '@troo/farmtrx-icons'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'

const useStyles = makeStyles(() => ({
  fileImportComponent: {
    p: 2,
    my: 1,
    border: '1px dashed grey',
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: '99',
  },
  uploadNew: {
    '& path': {
      fill: '#FFF',
    },
  },
  infoIcon: {
    '& path': {
      fill: '#439313',
    },
  },
}))

const HiddenInput = styled('input')({
  height: 1,
  width: 1,
})
export type FileUploadInputProps = {
  mt: number | string
  onImport: Function
}

export default function FileUploadInput(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onImport } = props
  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={
          <FarmtrxIconUploadNew
            className={classes.uploadNew}
            sx={{ height: '20px' }}
          />
        }
        sx={[{ ml: 2 }, props.mt && { mt: 2 }]}
      >
        {t('import')}
        <HiddenInput
          type="file"
          onChange={(event) => {
            let fileData = event.target.files
            onImport(fileData)
          }}
        />
      </Button>
    </>
  )
}
