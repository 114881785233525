import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleUp,
  faSpinner,
  faUser,
  faTimes,
  faEllipsisH,
  faPlus,
  faEdit,
  faTrashAlt,
  faCheck,
  faSquare,
  faCircle,
  faFileImport,
  faStickyNote,
  faBalanceScale,
  faDrawPolygon,
  faDiceOne,
  faDotCircle,
  faVectorSquare,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAngleDown,
  faAngleUp,
  faEllipsisH,
  faSpinner,
  faUser,
  faTimes,
  faPlus,
  faEdit,
  faTrashAlt,
  faCheck,
  faSquare,
  faCircle,
  faFileImport,
  faStickyNote,
  faFileImport,
  faBalanceScale,
  faDrawPolygon,
  faDiceOne,
  faDotCircle,
  faVectorSquare,
)

export default {}
