import ModalContainer from './modal-container'
import ModalHeader from './modal-header'
import ModalActions from './modal-actions'
import Button from '@mui/material/Button'
import { t } from 'i18next'
import { useState } from 'react'

export default function ModalTool(props) {
  const { header, content, onClose, onApply, disabled } = props
  const [applying, setApplying] = useState(false)

  return (
    <ModalContainer>
      <ModalHeader>{header}</ModalHeader>
      {content}
      <ModalActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || applying}
          onClick={() => {
            setApplying(true)
            onApply()
          }}
        >
          {applying ? t('applying') : t('apply')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}
