//mui
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
//react/redux/translations
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { AppState } from 'state'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
//actions
import { assignSelectedClient } from 'actions/client'
import { assignSelectedFarms } from 'actions/farm'
//Icons
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
//types
import { ClientModel } from 'state/client'
import { FarmModel, FarmOptionModel } from 'state/farm'

export type FarmSelectorProps = {
  client?: ClientModel
  selectedFarms?: FarmOptionModel[]
  onChange?: Function
  hideLabel?: boolean
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
export default function FarmSelector(props: FarmSelectorProps): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filter = createFilterOptions({
    stringify: (option: FarmOptionModel) => option.name,
  })
  const client: ClientModel | undefined = useSelector(
    (state: AppState) => state.client.singleton,
  )
  const farms: FarmModel[] = useSelector(
    (state: AppState) => state.farm.collection,
  )
  const [filteredFarms, setFilteredFarms] = useState<FarmOptionModel[]>([])
  const [selectedFarms, setSelectedFarms] = useState<FarmOptionModel[]>([])

  const allSelected = filteredFarms.length === selectedFarms.length
  useEffect(() => {
    if (props.client === null && client) {
      setFilteredFarms(client.farms)
      setSelectedFarms(client.farms)
    } else {
      if (props.client != null) {
        setFilteredFarms(props.client.farms)
        setSelectedFarms(props.client.farms)
      } else {
        setFilteredFarms([])
        setSelectedFarms([])
      }
    }
  }, [client, props.client])

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedFarms(filteredFarms)
    } else {
      handleClearOptions()
    }
  }

  const handleClearOptions = () => {
    setSelectedFarms([])
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }
  useEffect(() => {
    if (props.selectedFarms !== undefined) {
      setSelectedFarms(props.selectedFarms)
    } else {
      setSelectedFarms([])
    }
  }, [props.selectedFarms])

  const onFarmChange = useCallback(
    (event, newValue, reason) => {
      if (props.onChange) {
        props.onChange(event, newValue, reason)
      } else {
        let _farms: FarmOptionModel[] = []
        if (reason === 'selectOption' || reason === 'removeOption') {
          if (newValue.find((option) => option.id === 0)) {
            handleToggleSelectAll()
            _farms = !allSelected ? filteredFarms : []
          } else {
            _farms = newValue?.length ? newValue : []
            setSelectedFarms(newValue)
          }
        } else if (reason === 'clear') {
          handleClearOptions()
          _farms = []
        }
        setSelectedFarms(_farms)
      }
    },
    [props.onChange],
  )
  return (
    <Stack spacing={1}>
      {props?.hideLabel ? null : <Typography>{t('farms') + ':'}</Typography>}

      <Autocomplete
        fullWidth
        style={{ maxHeight: '30px' }}
        sx={{
          '& input': {
            height: '1em',
          },
          '& label': {
            lineHeight: '1.5em',
            fontSize: '1em',
          },
        }}
        multiple
        limitTags={2}
        id="farms"
        size="small"
        options={filteredFarms}
        getOptionLabel={(option) => option?.name || ''}
        value={selectedFarms}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => {
          if (Array.isArray(value)) {
            return value.findIndex((element) => element.id === option.id) >= 0
          } else {
            return option.id === value.id
          }
        }}
        onChange={(event, newValue, reason) => {
          onFarmChange(event, newValue, reason)
        }}
        filterOptions={(options, params) => {
          const filtered: FarmOptionModel[] = filter(options, params)
          return [
            {
              name: t('select_all'),
              id: 0,
            },
            ...filtered,
          ]
        }}
        renderOption={(props, option, { selected }) => {
          console.log(option)
          const selectAllProps =
            option.id === 0 // To control the state of 'select-all' checkbox
              ? { checked: allSelected }
              : {}
          return (
            <li style={{ padding: '0 0 0 5px' }} {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                {...selectAllProps}
              />
              {option.name}
            </li>
          )
        }}
        renderInput={(params) => {
          return props.hideLabel ? (
            <TextField
              {...params}
              label={t('farms')}
              placeholder={
                selectedFarms && selectedFarms.length > 0 ? '' : t('farms')
              }
              style={{
                width: '100%',
                maxHeight: '35px',
                textOverflow: 'ellipsis',
              }}
            />
          ) : (
            <TextField
              {...params}
              placeholder={
                selectedFarms && selectedFarms.length > 0 ? '' : t('farms')
              }
              style={{
                width: '100%',
                maxHeight: '35px',
                textOverflow: 'ellipsis',
              }}
            />
          )
        }}
        renderTags={(value) => {
          const numTags = value.length
          return (
            <div
              data-variant="body2"
              title={`${value[0].name} +${numTags - 1} more`}
              style={{
                whiteSpace: 'pre',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {value
                .slice(0, 1)
                .map((option) => option.name)
                .join(', ')}

              {numTags > 1 && ` +${numTags - 1} more`}
            </div>
          )
        }}
      />
    </Stack>
  )
}
