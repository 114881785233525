import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    height:100%;
  }

  body {
    background: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    color: #262626;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
    min-width: 300px;
    min-height: 200px;
  }

  h1,h2,h3,h4,h5,h6{
    font-family: 'Roboto', sans-serif;
    font-weight:700;
  }
  .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000D18;
      z-index: 999;
      opacity: 0.75;
  }

  input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    position: absolute;
    opacity: 0;
    z-index: 1;
    background: rgba(222, 222, 222, .75);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity 0.5s 0.5s ease-out;
    transition: opacity 0.5s 0.5s ease-out;
  }

  ::-webkit-scrollbar-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: rgba(150, 150, 150, .5);
    border-radius: 4px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export default GlobalStyle
