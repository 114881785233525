import * as c from 'common/c'

export const cropVarietiesFetchedByName = (data) => {
  return {
    type: c.CROP_VARIETIES_NAMES_FETCHED,
    payload: data,
  }
}

export const addEmptyCropVariety = (data) => {
  return {
    type: c.CROP_VARIETY_ADDED,
    payload: data,
  }
}
export const cropVarietiesForTenantFetched = (data) => {
  return {
    type: c.TENANT_CROP_VARIETIES_FETCHED,
    payload: data,
  }
}

export const removeCropVariety = (data) => {
  return {
    type: c.DELETE_CROP_VARIETY,
    payload: data,
  }
}

export const updateCropVariety = (data) => {
  return {
    type: c.UPDATE_CROP_VARIETY,
    payload: data,
  }
}

export const removeEmptyCropVariety = () => {
  return { type: c.DELETE_EMPTY_CROP_VARIETY }
}

export const setLocalCropVarietiesObj = (data) => {
  return {
    type: c.SET_LOCAL_CROP_VARIETIES,
    payload: data,
  }
}
