import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'

const initialState = {
  collection: [],
  defaultVariety: undefined,
  namesCollection: [],
  localStore: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.CROP_VARIETIES_NAMES_FETCHED: {
      return update(state, { namesCollection: { $set: action.payload } })
    }

    case c.CROP_VARIETY_ADDED: {
      return update(state, { collection: { $unshift: [action.payload] } })
    }

    case c.TENANT_CROP_VARIETIES_FETCHED: {
      return update(state, { collection: { $set: action.payload } })
    }

    case c.DELETE_CROP_VARIETY: {
      const idx = findIndex(
        get(state, 'collection', []),
        (variety) => variety.id === action.payload,
      )
      if (idx > -1) {
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    case c.UPDATE_CROP_VARIETY: {
      const index = findIndex(
        get(state, 'collection', []),
        (variety) => variety.id === action.payload.id,
      )
      if (index > -1) {
        return update(state, {
          collection: { $splice: [[index, 1, action.payload]] },
        })
      } else return
    }

    case c.SET_LOCAL_CROP_VARIETIES: {
      return update(state, { localStore: { $set: action.payload } })
    }

    case c.DELETE_EMPTY_CROP_VARIETY: {
      const idx = findIndex(
        get(state, 'collection', []),
        (variety) => variety.type === 'temp',
      )
      if (idx > -1) {
        return update(state, { collection: { $splice: [[idx, 1]] } })
      } else return
    }

    default:
      return state
  }
}
