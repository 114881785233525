import useMap from './use-map'
import { isNil } from 'lodash-es'

export default function useClickPixel() {
  const map = useMap()

  const [pixel, setPixel] = useState([0, 0])

  // init onClick
  useEffect(() => {
    if (!map) return

    function onClick(e) {
      if (!isNil(e.pixel_)) {
        setPixel(e.pixel_)
      }
    }

    map.on('singleclick', onClick)

    return () => {
      map.un('singleclick', onClick)
    }
  }, [map])

  return pixel
}
